import { API_URL } from '../config'

const defaultHeaders = {
  accept: 'application/json',
  'content-type': 'application/json'
}

export function setTokenHeader(value) {
  defaultHeaders.Authorization = value
}

export function clearTokenHeader() {
  delete defaultHeaders.Authorization
}

export default function request(path, args = {}) {
  const url = `${API_URL}/${path}`

  const options = {
    headers: args.headers || defaultHeaders,
    method: args.method || 'GET'
  }

  if (args.data) {
    options.body = JSON.stringify(args.data)
    options.headers['content-type'] = 'application/json'
  }
  return fetch(url, options).then(res => {
    if (res.status === 204) {
      return res
    }
    if (res.status >= 200 && res.status < 300) {
      return res.json().then(body => [body, res])
    }
    const error = new Error(res.statusText)
    error.res = res
    throw error
  })
}

export function requestFile(path, args = {}) {
  const url = `${API_URL}/${path}`

  const options = {
    headers: args.headers || defaultHeaders,
    method: args.method || 'GET'
  }

  if (args.data) {
    options.body = JSON.stringify(args.data)
    options.headers['content-type'] = 'application/json'
  }
  return fetch(url, options).then(res => {
    if (res.status >= 200 && res.status < 300) {
      return res
    }
  })
}
