// TODO: spelling / grammar check
export default {
  // Maintenance message
  maintenance: [
    'Servidores en mantenimiento. En breve podrás volver a acceder a My inBiot. Disculpa las molestias.',
    'Servers in maintenance. Shortly you will be able to access My inBiot again. Sorry for the inconvenience.',
    'Server in Wartung. In Kürze werden Sie wieder auf Mein inBiot zugreifen können. Entschuldigung für die Unannehmlichkeiten.'
  ],
  companyPost: ['Cargo en la empresa', 'Position in the company', 'Position in der empressa'],
  whatIsNew: {
    newFunctions: [
      '¡Nuevas funcionalidades!',
      'New premium functionalities!',
      'Neue Funktionalitäten!'
    ],
    newBusinessFunction: [
      '¡Nueva funcionalidad Business!',
      'New Business functionality!',
      'Neue Business-Funktionalität!'
    ],
    nonPremiumNewFunctions: [
      '¡Nuevas funcionalidades premium!',
      'New functionalities!',
      'Neue Premium-Funktionen!'
    ],
    discoverWhatIsNew: [
      'Ahora podrás personalizar aún más tus alertas, descubre todas las novedades.',
      "Now you can personalise your alerts even more, find out what's new.",
      'Jetzt können Sie Ihre Benachrichtigungen noch besser personalisieren und erfahren, was es Neues gibt.'
    ],
    discoverWhatIsNewNonPremium: [
      'Mantente al día de cualquier cambio en la calidad del aire con las nuevas alertas personalizadas.',
      'Stay up to date on any changes in air quality with new personalised alerts.',
      'Bleiben Sie mit den neuen personalisierten Warnmeldungen über Veränderungen der Luftqualität auf dem Laufenden.'
    ],
    explore: ['Explorar ahora', 'Explore now', 'Jetzt erforschen'],
    knowMore: ['Saber más', 'Know more', 'Mehr erfahren'],
    understood: [
      'Salir y no mostrar más',
      'Exit and show no more',
      'Beenden und nicht mehr anzeigen'
    ],
    exit: [
      'Salir y mostrar la próxima vez',
      'Exit and show next time',
      'Beenden und beim nächsten Mal anzeigen'
    ],

    close: ['Salir', 'Exit', 'Ausfahrt'],
    remindLater: ['¿Recordar más tarde?', 'Remind me later?', 'Erinnern Sie sich später?'],
    moreInformation: ['Más información', 'More information', 'Weitere Informationen'],
    remindMeLater: ['Recuérdamelo más tarde', 'Remind me later', 'Erinnern Sie mich später'],
    lastMessage: [
      '¡Ya puedes disfrutar de todas las novedades y sacar el máximo partido a tus datos de calidad del aire interior!',
      'You can now enjoy all the new features and get the most out of your indoor air quality data!',
      'Sie können jetzt alle neuen Funktionen nutzen und das Beste aus Ihren Raumluftqualitätsdaten herausholen! Daten zur Luftqualität in Innenräumen!'
    ],
    lastMessageNonPremium: [
      'Ya disponible en My inBiot Business.',
      'Now available on My inBiot Business.',
      'Jetzt verfügbar auf My inBiot Business.'
    ],
    availableInBusiness: [
      'Ya disponibles en My inBiot Business.',
      'Now available in My inBiot Business.',
      'Jetzt verfügbar auf My inBiot Business.'
    ],
    myInbiotNews: [
      '¡Hay novedades en My inBiot!',
      "There's news at My inBiot!",
      'Es gibt Neuigkeiten bei My inBiot!'
    ],
    myInbiotNewsUnderstood: ['¡Entendido!', 'Understood!', "Ich hab's!"],
    statusDevicePanel: [
      'Descubre el nuevo panel de estado de dispositivos.',
      'Discover the new device status dashboard.',
      'Entdecken Sie das neue Dashboard für den Gerätestatus.'
    ],
    statusDevice: ['Estado de dispositivos', 'Device status', 'Gerätestatus'],
    statusDevicePanelDescription: [
      'Supervisa fácilmente el estado de tus dispositivos MICA comprobando de un vistazo su conectividad, señal, periodicidad de subida de datos y más.',
      'Easily monitor the status of your MICA devices by checking at a glance their connectivity, signal, upload timing and more.',
      'Überwachen Sie den Status Ihrer MICA-Geräte, indem Sie auf einen Blick die Konnektivität, das Signal, den Upload-Zeitplan und mehr überprüfen.'
    ],
    statusDevicePanelLastMessageBusiness: [
      '¡Ya puedes disfrutar de esta funcionalidad para maximizar el control de tus dispositivos!',
      'You can now enjoy this functionality to maximise control of your devices!',
      'Sie können diese Funktion jetzt nutzen, um die Kontrolle über Ihre Geräte zu maximieren!'
    ],
    statusDevicePanelLastMessageNonBusiness: [
      '¡Pásate a My inBiot Business para disfrutar de esta nueva funcionalidad y maximizar el control de tus dispositivos!',
      'Switch to My inBiot Business to enjoy this new functionality and maximise control of your devices!',
      'Wechseln Sie zu My inBiot Business, um diese neue Funktionalität zu nutzen und die Kontrolle über Ihre Geräte zu maximieren!'
    ]
  },

  // Login & sign up
  discoverInbiot: ['Descubre inBiot', 'Discover inBiot', 'Entdecken Sie inBiot'],
  copyright: [
    'Copyright © inBiot. Todos los derechos reservados.',
    'Copyright © inBiot. All rights reserved.',
    'Copyright © inBiot. Alle Rechte vorbehalten.'
  ],
  login: ['Iniciar sesión', 'Login', 'Melden Sie sich an'],
  signUp: ['Crear cuenta', 'Sign Up', 'Registrierung'],
  premiumFunctionalityText: [
    'Pásate a My inBiot Business para disfrutar de esta funcionalidad. Consúltanos en info@inbiot.es',
    'Switch to My inBiot Business to enjoy this functionality. Contact us at info@inbiot.es',
    'Gehen Sie zu My inBiot Business, um diese Funktion zu nutzen. Kontaktieren Sie uns unter info@inbiot.es'
  ],
  contactUs: [
    'Consúltanos en info@inbiot.es',
    'Contact us at info@inbiot.es',
    'Kontaktieren Sie uns unter info@inbiot.es'
  ],
  premiumDownloadData: [
    'Pásate a My inBiot Business para disfrutar de esta funcionalidad. Puedes descargar datos de manera individual desde la vista de cada uno de sus dispositivos.',
    'Switch to My inBiot Business to enjoy this functionality. You can download data individually from the view of each of your devices.',
    'Wechseln Sie zu Mein inBiot Business, um diese Funktion zu nutzen. Sie können die Daten einzeln aus der Ansicht jedes Ihrer Geräte herunterladen.'
  ],
  businessPlan: ['Licencia Business', 'Business License', 'Business License'],
  comingSoon: ['Próximamente...', 'Coming soon...', 'Demnächst...'],
  firstName: ['Nombre', 'First name', 'Vorname'],
  lastName: ['Apellidos', 'Last name', 'Nachname'],
  country: ['País', 'Country', 'Land'],
  measurementSystem: ['Sistema de mediciones', 'Measurement system', 'Messsystem'],
  metricSystem: [
    'Sistema métrico (ºC, hPa)',
    'Metric system (ºC, hPa)',
    'Metrisches System (ºC, hPa)'
  ],
  imperialSystem: [
    'Sistema imperial (ºF, PSI)',
    'Imperial system (ºF, PSI)',
    'Imperiales System (ºF, PSI)'
  ],
  open: ['ABRIR', 'OPEN', 'ÖFFNEN'],
  company: ['Empresa', 'Company', 'Firmenname '],
  position: ['Cargo en la empresa', 'Company position', 'Position in der Firma'],
  otherPosition: ['Otro (Rellenar)', 'Other (Fill in)', 'Anderes (bitte ausfüllen)'],
  positionPlaceholder: ['Cargo en la empresa', 'Position in the company', 'Position in der Firma'],
  positions: {
    facilityManager: ['Facility Manager', 'Facility Manager', 'Facility Manager'],
    infraestructureManager: [
      'Responsable de infraestructuras',
      'Infrastructure Manager',
      'Infrastruktur-Manager'
    ],
    maintenanceManager: [
      'Responsable de mantenimiento',
      'Maintenance Manager',
      'Leiter Instandhaltung'
    ],
    rrhhManager: ['Responsable RRHH', 'RRHH Manager', 'RRHH-Manager'],
    rrllManager: [
      'Responsable prevención y RRLL',
      'Prevention and RRLL Manager',
      'Manager für Prävention und RRLL'
    ],
    hseManager: ['Responsable HSE', 'HSE Manager', 'HSE-Manager'],
    marketingManager: ['Responsable Marketing', 'Marketing Manager', 'Marketing-Manager'],
    rscManager: ['Responsable RSC', 'RSC Manager', 'RSC-Manager'],
    other: ['Otro', 'Other', 'Andere']
  },
  manageGroup: ['GESTIONAR GRUPO', 'MANAGE GROUP', 'GRUPPE VERWALTEN'],
  hours: ['horas', 'hours', 'Stunden'],
  week: ['semana', 'week', 'Woche'],
  weeks: ['semanas', 'weeks', 'Wochen'],
  month: ['mes', 'month', 'Monat'],
  months: ['meses', 'months', 'Monate'],
  email: ['Correo electrónico', 'Email address', 'E-Mail-Adresse'],
  emailContact: ['Correo de contacto', 'Contact Email', 'Kontaktpost'],
  phoneContact: ['Teléfono de contacto', 'Contact phone', 'Kontakt Telefon'],
  password: ['Contraseña', 'Password', 'Passwort'],
  oldPassword: ['Contraseña antigua', 'Old password', 'Altes Passwort'],
  newPassword: ['Contraseña nueva', 'New password', 'Neues Passwort'],
  repeatPassword: ['Repetir contraseña', 'Repeat password', 'Passwort bestätigen'],
  add: ['Añadir', 'Add', 'Hinzufügen'],
  myMicas: ['Mis Dispositivos', 'My Devices', 'Meine Geräte'],
  newGroup: ['Nuevo grupo', 'New group', 'Neue Gruppe'],
  multipleDownload: ['Descarga múltiple', 'Multiple download', 'Mehrfacher Download'],
  transferDevice: ['Transferir dispositivos', 'Transfer devices', 'Geräte übertragen'],
  devicesStatus: ['Estado de dispositivos', 'Device status', 'Gerätestatus'],
  indicateEmail: [
    'Indique el correo electrónico de la cuenta a la que desea transferir los dispositivios.',
    'Indicate the e-mail address of the account to which you wish to transfer the devices.',
    'Bitte geben Sie die E-Mail-Adresse des Kontos ein, auf das Sie die Geräte übertragen möchten.'
  ],
  emailUser: [
    'El correo debe estar asociado a una cuenta de My inBiot.',
    'The email must be associated with My inBiot account.',
    'Die E-Mail muss mit einem My inBiot-Konto verknüpft sein.'
  ],
  RemoveDevices: ['Quitar dispositivos', 'Remove devices', 'Geräte entfernen'],
  options: ['Opciones', 'Options', 'Auswahl'],
  summaryView: ['Resumen', 'Summary', 'Zusammenfassung'],
  detailView: ['En detalle', 'View details', 'Details anzeigen'],
  addSystem: ['AÑADIR DISPOSITIVO', 'ADD DEVICE', 'GERÄT HINZUFÜGEN'],
  addGroup: ['Añadir grupo', 'Add group', 'Gruppe hinzufügen'],
  editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
  groupName: ['Nombre del grupo', 'Group name', 'Gruppenname'],
  searchMica: ['Buscar MICA', 'Search MICA', 'Suche MICA'],
  searchDevice: ['Buscar dispositivo', 'Search device', 'Gerät suchen'],
  save: ['Guardar', 'Save', 'Speichern'],
  saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
  edit: ['Editar', 'Edit', 'Bearbeiten'],
  editSystem: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  delete: ['Borrar', 'Delete', 'Löschen'],
  deleteSystem: ['Borrar dispositivo', 'Delete device', 'System löschen'],
  clearData: ['Limpiar datos', 'Clear data', 'Daten löschen'],
  downloadStatistics: [
    'Descargar estadísticos',
    'Download statistics',
    'Statistiken herunterladen'
  ],
  Personalization: ['Personalización', 'Personalization', 'die Personalisierung'],
  units: ['Unidades', 'Units', 'Einheiten'],
  dailyStatistics: ['Estadísticos diarios', 'Daily statistics', 'Tägliche Statistiken'],
  weeklyStatistics: ['Estadísticos semanales', 'Weekly statistics', 'Wöchentliche Statistiken'],
  monthlyStatistics: ['Estadísticos mensuales', 'Monthly statistics', 'Monatliche Statistiken'],
  dashboard: ['Dashboard', 'Dashboard', 'Dashboard'],
  home: ['Inicio', 'Home', 'Home'],
  parameters: ['Parámetros', 'Parameters', 'Parameter'],
  timeRange: ['Rango de tiempo', 'Time range', 'Zeitspanne'],
  chartAvg: ['med.', 'avg.', 'avg.'],
  chartMax: ['max.', 'max.', 'max.'],
  chartMin: ['max.', 'max.', 'max.'],
  indicators: ['Indicadores', 'Indicators', 'Indikatoren'],
  iaqIndicator: ['Indicador IAQ', 'IAQ Indicator', 'IAQ Indikator'],
  ventilationIndicator: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Effizienz der Belüftung'
  ],
  virusIndicator: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  thermalIndicator: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  iaqInterior: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  indicatorThermico: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  ViricoIndicator: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  VentilaciónIndicator: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Qualität der Belüftung'
  ],

  chart: ['Gráfica', 'Chart', 'Grafik'],
  DESK: ['MICA Desk', 'MICA Desk', 'MICA Desk'],
  MICA_LITE: ['MICA Lite', 'MICA Lite', 'MICA Lite'],
  MICA_WELL: ['MICA WELL', 'MICA WELL', 'MICA WELL'],
  MICA_PLUS: ['MICA Plus', 'MICA Plus', 'MICA Plus'],
  MICA: ['MICA', 'MICA', 'MICA'],
  MICA_MINI: ['MICA Mini', 'MICA Mini', 'MICA Mini'],
  availability: ['Fechas disponibles', 'Dates available', 'Verfügbare Termine'],
  availabilityText: [
    'La cantidad de dispositivos y parámetros escogidos afectarán el intervalo de fechas seleccionable.',
    'The number of devices and parameters chosen will affect the selectable date range.',
    'Die Anzahl der Geräte und die gewählten Parameter beeinflussen den wählbaren Datumsbereich.'
  ],
  groupVis: ['Visualización de grupos', 'Group visualization', 'Gruppenanzeige'],
  recogidos: ['Grupos recogidos', 'Groups collected', 'Gruppen gesammelt'],
  desplegados: ['Grupos desplegados', 'Groups deployed', 'Eingesetzte Gruppen'],
  report: ['Informes Inteligentes', 'Smart Reports', 'Intelligente Berichterstattung'],
  compare: ['Comparar', 'Compare', 'Vergleichen'],
  fullscreenView: ['Pantallas', 'Views', 'Bildschirme'],
  shareables: ['Compartibles', 'Shareables', 'Shareables'],
  alerts: ['Alertas', 'Alerts', 'Warnungen'],
  certifications: ['Certificaciones', 'Certifications', 'Zertifizierungen'],

  transfertDeviceTitle: [
    'Solicitud de Tranferencia de Dispositivo',
    'Request for Device Transfer',
    'Anfrage zur Geräteübertragung'
  ],
  transdferDeviceFirstText: ['La cuenta ', 'The account ', 'Das Konto '],
  transdferDeviceSecondText: [
    ' ha solicitado transferir ',
    ' has requested to transfer ',
    ' hat beantragt, '
  ],

  transdferDeviceThirdText: [
    ' dispositivo a esta cuenta. Indica si deseas aceptar o denegar el traspaso.',
    ' device to this account. Indicate whether you want to accept or deny the transfer.',
    ' Gerät auf dieses Konto zu übertragen. Geben Sie an, ob Sie die Übertragung annehmen oder ablehnen möchten.'
  ],
  transdferDeviceFourthText: [
    ' dispositivos a esta cuenta. Indica si deseas aceptar o denegar el traspaso.',
    ' devices to this account. Indicate whether you want to accept or deny the transfer.',
    ' Geräte auf dieses Konto zu übertragen. Geben Sie an, ob Sie die Übertragung annehmen oder ablehnen möchten.'
  ],

  confirmDeleteFirstText: [
    'Confirma que deseas eliminar',
    'Confirm that you want to remove',
    'Bestätigen Sie, dass Sie Gerät'
  ],
  confirmDeleteSecondText: [
    'dispositivo de tu cuenta.',
    'device from your account.',
    'aus Ihrem Konto entfernen möchten.'
  ],
  confirmDeleteThirdText: [
    'dispositivos de tu cuenta.',
    'devices from your account.',
    'aus Ihrem Konto entfernen möchten.'
  ],

  confirmDeleteFourthText: [
    'Todas las alertas y compartibles creadas para este dispositivo se borrarán. Si deseas migrar el dispostivo de cuenta con sus alertas y compartibles incluídas, puedes hacerlo desde la opción “Transferir dispositivos”.',
    'All alerts and shareables created for this device will be deleted. If you want to migrate the device from the account with its alerts and shareables included, you can do it from the "Transfer devices" option.',
    'Alle für dieses Gerät erstellten Warnungen und Freigaben werden gelöscht. Wenn Sie das Kontogerät mit seinen Warnungen und Freigaben migrieren möchten, können Sie dies über die Option "Geräte übertragen" tun.'
  ],

  confirmTransferFirstText: [
    'Confirma que deseas transferir ',
    'Confirm that you want to transfer ',
    'Bestätigen Sie, dass Sie '
  ],

  confirmTransferSecondText: [
    ' dispositivos a la cuenta  ',
    ' devices to the account ',
    ' Geräte auf das Konto '
  ],

  confirmTransferFourthText: [
    'Todas las alertas y compartibles creadas para este dispositivo migrarán a la nueva cuenta y ya no podrás visualizar los datos de las mediciones. ',
    'All alerts and sharables created for this device will migrate to the new account and you will no longer be able to view the measurement data. ',
    'Alle für dieses Gerät erstellten Warnungen und Freigaben werden auf das neue Konto übertragen und Sie können die Messdaten nicht mehr anzeigen. '
  ],

  confirmTransferThirdText: [
    ' dispositivo  a la cuenta  ',
    ' device to the account ',
    ' Gerät auf das Konto '
  ],

  transferButton: ['TRANSFERIR', 'TRANSFER', 'ÜBERWEISUNG'],

  cancelButton: ['ELIMINAR', 'REMOVE', 'ENTFERNEN'],
  infoTextTransfer: [
    'La cuenta de destino deberá aceptar la transferencia',
    'Destination account must accept the transfer',
    'Das Zielkonto muss die Überweisung akzeptieren'
  ],

  acceptTransfer: ['ACEPTAR', 'ACCEPT', 'AKZEPT'],
  refuseTransfer: ['DENEGAR', 'REFUSE', 'VERWEIGERN'],

  help: ['Ayuda', 'Help', 'Hilfe'],
  user: ['Mi cuenta', 'My account', 'Mein Konto'],
  thisUser: ['(Este usuario)', '(This user)', '(Dieser Benutzer)'],
  language: ['Idioma', 'Language', 'Sprache'],
  ES: ['Español', 'Spanish', 'Spanisch'],
  EN: ['Inglés', 'English', 'Englisch'],
  faqs: ['Preguntas frecuentes', 'FAQs', 'Häufig gestellte Fragen'],

  profile: ['Perfil', 'Profile', 'Profil'],
  editProfile: ['Editar perfil', 'Edit profile', 'Profil bearbeiten'],
  dataVis: ['Visualización de datos', 'Data visualization ', 'Datenvisualisierung '],
  visualization: ['Visualización', 'Visualization', 'Visualisierung'],
  restorePassword: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen'],
  uploadLogo: ['Subir o cargar logotipo', 'Upload logo', 'Logo hochladen'],
  deleteLogo: ['Eliminar logotipo', 'Delete logo', 'Logo löschen'],
  deleteAccount: ['Eliminar cuenta', 'Delete account', 'Konto löschen'],
  deleteAccountConfirmationText: [
    'Vas a eliminar esta cuenta de My inBiot, todos los dispositivos dejarán de estar asociados a esta cuenta y los datos recolectados se perderán. ¿Estás seguro?',
    'If you delete this account from My inBiot, all devices will no longer be associated with this account and the collected data will be lost. Are you sure?',
    'Wenn Sie dieses Konto aus Mein inBiot löschen, werden alle Geräte nicht mehr mit diesem Konto verbunden und die gesammelten Daten gehen verloren. Sind Sie sicher?'
  ],
  deleteUserAccessConfirmationText: [
    'Vas a eliminar el acceso de este usuario de tus dispositivos de My inBiot. ¿Estás seguro?',
    "You are going to remove this user's access from your My inBiot devices. Are you sure?",
    'Sie werden den Zugang dieses Benutzers zu Ihren My inBiot-Geräten entfernen. Sind Sie sicher?'
  ],
  deleteCollaborativeAccessConfirmationText: [
    'Vas a borrar tu acceso a los dispositivos de esta cuenta. ¿Estás seguro?',
    "You are going to delete your access to the selected account's devices. Are you sure?",
    'Sie werden Ihren Zugriff auf die Geräte des ausgewählten Kontos löschen. Sind Sie sicher?'
  ],
  logout: ['Cerrar sesión', 'Logout', 'Abmelden'],
  close: ['Cerrar', 'Close', 'schließen'],

  name: ['Nombre', 'Name', 'Name'],
  systemName: ['Nombra el dispositivo', 'Name your device', 'Benennen Sie Ihr Gerät'],
  systemDescription: ['Descripción del dispositivo', 'Device description', 'Gerätebeschreibung'],
  sensorId: ['Id de sensor', 'Sensor id', 'Sensor id'],
  description: ['Descripción', 'Description', 'Beschreibung'],
  zipCode: ['Código postal', 'Zip code', 'Postleitzahl'],

  micaMiniThermalInfoText: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  micaMiniInfoText: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  micaDeskInfoText: ['Indicador IAQ', 'IAQ indicator', 'IAQ Indikator'],
  micaDeskInfoTextTitle: [
    'Indicador de Calidad del Aire Interior',
    'Indoor Air Quality Indicator',
    'Luftqualität in Innenräumen Luftqualität in Innenräumen'
  ],
  micaLiteInfoText: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  micaLiteInfoTextTitle: [
    'Indicador de Resistencia a la Propagación de Virus',
    'Resistance to Virus Spread Indicator',
    'Indikator für Resistenz gegen Virusausbreitung Resistenzindikator'
  ],
  micaInfoText: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  micaMiniInfoTextTitle: [
    'Indicador del Confort Termohigrométrico',
    'Thermohygrometric Comfort Indicator',
    'Thermo-hygrometrischer Komfort-Indikator'
  ],
  micaWellInfoText: [
    'Calidad de Aire Interior',
    'Indoor Air Quality',
    'Luftqualität in Innenräumen'
  ],
  micaLiteInfo: [
    'El Indicador Virus, basado en el RESET VIRAL INDEX, muestra en una escala 0-100 la resistencia que ofrece el aire de un espacio a la propagación de virus. </br></br>' +
      'Nos ayuda a optimizar los sistemas de un edificio para minimizar el potencial de infección vírica por vía aérea.',

    'The Virus Indicator, based on the RESET VIRAL INDEX, shows on a scale of 0-100 the resistance of the air in a space to the spread of viruses. </br></br>' +
      "It helps us to optimize a building's systems to minimize the potential for airborne viral infection.",

    'Der Virus-Indikator, der auf dem RESET VIRAL INDEX basiert, zeigt auf einer Skala von 0-100 an, wie resistent die Luft in einem Raum gegenüber der Verbreitung von Viren ist. </br></br>' +
      'Er hilft uns, die Systeme eines Gebäudes so zu optimieren, dass das Potenzial für Virusinfektionen über die Luft minimiert wird.'
  ],
  micaInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaInfoReduced: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaWellInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaDeskInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  ventilationInfoText: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Effizienz der Belüftung'
  ],
  ventilationInfoTextTitle: [
    'Indicador de Eficacia de Ventilación',
    'Ventilation Efficiency Indicator',
    'Indikator für den Lüftungswirkungsgrad'
  ],
  ventilationInfo: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂ y de TVOC. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ and TVOC concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂- und TVOC-Konzentration.'
  ],
  ventilationInfoVocs: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂-Konzentration.'
  ],
  micaMiniInfo: [
    'Permite identificar de manera sencilla el rango de temperatura y humedad relativa que representa el bienestar térmico.   </br></br>' +
      'Nos ayuda a objetivizar las condiciones de temperatura y humedad de un espacio interior.',

    'It allows to identify in a simple way the range of temperature and relative humidity that represents the thermal comfort. <br/> <br/>' +
      'It helps us to objectify the temperature and humidity conditions of an interior space.',

    'Sie ermöglicht es uns, den Bereich der Temperatur und der relativen Luftfeuchtigkeit, der für die thermische Behaglichkeit ausschlaggebend ist, leicht zu bestimmen. <br/> <br/>' +
      'Sie hilft uns, die Temperatur- und Feuchtigkeitsbedingungen in einem Innenraum zu objektivieren.'
  ],
  iaqIndicatorText: [
    'Calidad Interior del Aire',
    'Indoor Air Quality',
    'Qualität der Innenraumluft'
  ],
  moreInfo: ['Ampliar Información', 'More information', 'Weitere Informationen'],
  learnMore: ['SABER MÁS', 'LEARN MORE', 'MEHR LERNEN'],
  veryGoodIndoorAirQuality: [
    'Calidad de Aire Interior muy buena',
    'Very good Indoor Air Quality',
    'Sehr gute Luftqualität in Innenräumen'
  ],
  veryGoodIndoorAirQualityTip: [
    'Aire limpio e higiénico. No se requiere acción.',
    'Clean and hygienic air. No action required.',
    'Saubere und hygienische Luft. Keine Maßnahmen erforderlich.'
  ],
  goodIndoorAirQuality: [
    'Calidad de Aire Interior buena',
    'Good Indoor Air Quality',
    'Gute Luftqualität in Innenräumen'
  ],
  goodIndoorAirQualityTip: [
    'Buena calidad del aire sin superar umbrales de riesgo.',
    'Good air quality without exceeding risk thresholds.',
    'Gute Luftqualität ohne Überschreitung der Risikogrenzen.'
  ],
  mediumIndoorAirQuality: [
    'Calidad de Aire Interior media',
    'Medium Indoor Air Quality',
    'Mittlere Luftqualität in Innenräumen'
  ],
  mediumIndoorAirQualityTip: [
    'Calidad del aire interior no recomendada para grupos de riesgo. Sensación de disconfort en exposiciones de larga duración. Mejora la ventilación y reduce fuentes de contaminación.',
    'Indoor air quality not recommended for risk groups. Feeling of discomfort in long-term exposures Improve ventilation and reduce sources of contamination.',
    'Raumluftqualität für Risikogruppen nicht empfohlen. Gefühl des Unbehagens bei Langzeitexposition Verbesserung der Belüftung und Verringerung der Verschmutzungsquellen.'
  ],
  poorIndoorAirQuality: [
    'Calidad de Aire Interior baja',
    'Poor Indoor Air Quality',
    'Schlechte Luftqualität in Innenräumen'
  ],
  poorIndoorAirQualityTip: [
    'Calidad del aire no recomendada para exposiciones continuas. Mejora e incrementa la ventilación y reduce las fuentes de contaminación.',
    'Air quality not recommended for continuous exposures. Improve and increase ventilation and reduce sources of indoor pollutants.',
    'Die Luftqualität wird für eine kontinuierliche Exposition nicht empfohlen. Verbessern und erhöhen Sie Belüftung und Verringerung der Quellen von Schadstoffen in Innenräumen.'
  ],
  veryPoorIndoorAirQuality: [
    'Calidad de Aire Interior muy baja',
    'Very poor Indoor Air Quality',
    'Sehr schlechte Luftqualität in Innenräumen'
  ],
  veryPoorIndoorAirQualityTip: [
    'Condiciones de calidad del aire interior no adecuadas. Se necesita ventilar y mejorar las condiciones de calidad del aire interior de forma urgente.',
    'Ventilation and improvement of indoor air quality conditions is urgently needed.',
    'Die Belüftung und Verbesserung der Luftqualität in Innenräumen ist dringend erforderlich.'
  ],
  highSpreadProbability: [
    'Probabilidad de propagación alta.',
    'High probability of spread',
    'Hohe Wahrscheinlichkeit der Ausbreitung'
  ],
  highSpreadProbabilityTip: [
    'Es urgente revisar el nivel de ventilación y el aforo del espacio, ajustar las condiciones de confort termohigrométrico y reducir la presencia de partículas en suspensión.',
    'It is urgent to review the ventilation level and the space occupancy, adjust the thermo-hygrometric comfort conditions and reduce the presence of Particulate Matter.',
    'Es ist dringend erforderlich, die Belüftungsstufe und die Raumbelegung zu überprüfen, die thermohygrometrischen Komfortbedingungen anzupassen und das Vorhandensein von Feinstaub zu reduzieren.'
  ],
  mediumSpreadProbability: [
    'Probabilidad de propagación media',
    'Medium probability of spread',
    'Mittlere Wahrscheinlichkeit der Ausbreitung'
  ],
  mediumSpreadProbabilityTip: [
    'Se recomienda revisar las condiciones específicas de ventilación, confort termohigrométrico y propagación de partículas para reducir el riesgo de propagación de virus.',
    'It is recommended to review ventilation rate, thermo-hygrometric comfort and PM spread to reduce the risk of virus spread.',
    'Es wird empfohlen, die Beatmungsrate, den thermohygrometrischen Komfort und die PM-Ausbreitung zu überprüfen, um das Risiko einer Virusausbreitung zu verringern.'
  ],
  lowSpreadProbability: [
    'Probabilidad de propagación baja',
    'Low probability of spread',
    'Geringe Wahrscheinlichkeit der Ausbreitung'
  ],
  lowSpreadProbabilityTip: [
    'No se requiere acción específica',
    'No specific action is required.',
    'Es sind keine besonderen Maßnahmen erforderlich.'
  ],
  lowVentilationNeeds: [
    'No se requiere ventilar',
    'No ventilation required',
    'Keine Belüftung erforderlich'
  ],
  mediumVentilationNeeds: [
    'Se recomienda ventilar',
    'Ventilation recommended',
    'Belüftung empfohlen'
  ],
  highVentilationNeeds: [
    'Es necesario ventilar',
    'Ventilation is required',
    'Belüftung ist erforderlich'
  ],
  sensorErrorMessage: [
    'No se están recibiendo datos de este sensor. Encuentra soluciones o contáctanos.',
    'No data is being received from this sensor. Find solutions or contact us.',
    'Es werden keine Daten von diesem Sensor empfangen. Finden Sie Lösungen oder kontaktieren Sie uns.'
  ],
  sensorPreheatingMessage: [
    'El sensor se encuentra en fase de pre-calentamiento. Espere unas horas para recibir medidas.',
    'The sensor is in the pre-heating phase. Wait a few hours to receive measurements.',
    'Der Sensor befindet sich in der Vorwärmphase. Warten Sie ein paar Stunden, um Messungen zu erhalten.'
  ],
  supportButton: ['Ayuda', 'Help', 'Hilfe'],
  optimalTemperatureTip: [
    '¡Muy bien! El rango de temperatura para garantizar el confort térmico es adecuado!',
    'Very good! The temperature range to ensure thermal comfort is adequate!',
    'Sehr gut! Der Temperaturbereich zur Gewährleistung des thermischen Komforts ist ausreichend!'
  ],
  regularTemperatureTip: [
    'Trata de regular la temperatura del termostato para garantizar un confort térmico más adecuado.',
    'Try to regulate the thermostat temperature to ensure a more adequate thermal comfort.',
    'Versuchen Sie, die Temperatur des Thermostats zu regulieren, um einen angemessenen Wärmekomfort zu gewährleisten.'
  ],
  lowTemperatureTip: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 20-21ºC.',
    'Adjust the thermostat temperature to values around 20-21ºC.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 20-21°C ein.'
  ],
  lowKelvinTemperature: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 293-294ºk.',
    'Adjust the thermostat temperature to values around 293-294ºk.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 293-294ºk.'
  ],
  highKelvinTemperature: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 293 - 294ºk.',
    'Adjust the thermostat temperature to values around 293-294ºk.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 293-294ºk.'
  ],
  lowTemperatureTipImperial: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 68-69ºF.',
    'Adjust the thermostat temperature to values around 68-69ºF.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 68-69ºF.'
  ],
  highTemperatureTip: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 20-21ºC',
    'Adjust the thermostat temperature to values around 20-21ºC.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 20-21°C ein.'
  ],
  highTemperatureTipImperial: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 68-69ºF',
    'Adjust the thermostat temperature to values around 68-69ºF.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 68-69ºF.'
  ],
  optimalHumidityTip: [
    '¡Muy bien! Rango de humedad relativa adecuado para un ambiente interior saludable.',
    'Very good! Relative humidity range suitable for a healthy indoor environment.',
    'Sehr gut! Der Bereich der relativen Luftfeuchtigkeit ist für ein gesundes Raumklima geeignet.'
  ],
  regularHumidityTip: [
    'Trata de ajustar la humedad hasta alcanzar niveles de humedad relativa  en torno al 50%.',
    'Try to adjust the humidity to reach relative humidity levels of around 50%.',
    'Versuchen Sie, die Luftfeuchtigkeit so einzustellen, dass die relative Luftfeuchtigkeit etwa 50 % beträgt.'
  ],
  badHumidityTip: [
    'Trata de ajustar la humedad hasta alcanzar niveles de humedad relativa entre el 35% y el 60%.',
    'Try to adjust the humidity to reach relative humidity levels between 35% and 60%.',
    'Versuchen Sie, die Luftfeuchtigkeit so einzustellen, dass die relative Luftfeuchtigkeit zwischen 35 % und 60 % liegt.'
  ],
  optimalCo2Tip: [
    '¡Excelente! Nivel de CO₂ óptimo para un ambiente interior saludable. Continúa garantizando adecuada renovación de aire para seguir por debajo de 800 ppm.',
    'Excellent! Optimal CO₂ level for a healthy indoor environment. Continues to ensure adequate air renewal to remain below 800 ppm.',
    'Ausgezeichnet! Optimaler CO₂-Wert für ein gesundes Raumklima. Sorgt weiterhin für eine angemessene Lufterneuerung, um unter 800 ppm zu bleiben.'
  ],
  regularCo2Tip: [
    'Aumenta la tasa de ventilación del espacio.',
    'Increase the ventilation rate of the space.',
    'Erhöhen Sie die Belüftungsrate des Raums.'
  ],
  badCo2Tip: [
    'Incrementa la tasa de renovación de aire y ajusta el sistema de ventilación a las necesidades reales.',
    'Increase the air renewal rate and adjust the Ventilation System system to real needs.',
    'Erhöhen Sie die Lufterneuerungsrate und passen Sie das Belüftungssystem an den tatsächlichen Bedarf an.'
  ],
  optimalVocsTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de TVOC de forma continua.',
    'Very good! Continues to ensure low levels of TVOC on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige TVOC-Werte.'
  ],
  regularVocsTip: [
    'Ajusta los niveles de ventilación para reducir la concentración de TVOC. Asegura una ventilación efectiva al utilizar productos de limpieza, pinturas o disolventes.',
    'Adjust ventilation levels to reduce TVOC concentration. Ensure effective ventilation when using cleaners, paints or solvents.',
    'Passen Sie die Belüftungsstufen an, um die TVOC-Konzentration zu verringern. Sorgen Sie für gute Belüftung, wenn Sie Reinigungsmittel, Farben oder Lösungsmittel verwenden.'
  ],
  badVocsTip: [
    'Implanta un sistema de ventilación eficaz, un sistema de purificación de aire con tecnología específica para adsorción de TVOC y utiliza productos sustitutivos de bajas emisiones. Consulta con profesional especializado si se mantienen niveles altos de forma continua.',
    'Implement an effective ventilation system, an air purification system with specific TVOC adsorption technology and use low-emission substitutes. Consult a specialised professional if high levels are maintained on a continuous basis.',
    'Führen Sie ein wirksames Belüftungssystem und ein Luftreinigungssystem mit spezieller TVOC-Adsorptionstechnologie ein und verwenden Sie emissionsarme Ersatzstoffe. Wenden Sie sich an einen Fachmann, wenn ständig hohe Werte auftreten.'
  ],
  optimalPm25Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM2.5 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM2.5 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM2,5-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm25Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm25Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm1Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM1.0 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM1.0 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM1.0-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm1Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm1Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm4Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM4.0 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM4.0 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM4.0-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm4Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm4Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm10Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM10 siempre por debajo de 50 µg/m³.',
    'Good job! Keep it up and keep PM10 particulate levels always below 50 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM10-Feinstaubwerte stets unter 50 µg/m³.'
  ],
  regularPm10Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm10Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalFormaldehydeTip: [
    'Enhorabuena, el nivel de formaldehído es bajo, adecuado para un ambiente interior saludable.',
    'Congratulations, the formaldehyde level is low, suitable for a healthy indoor environment.',
    'Herzlichen Glückwunsch, der Formaldehydgehalt ist niedrig und eignet sich für ein gesundes Raumklima.'
  ],
  regularFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento y utiliza productos sellantes para formaldehído. Asegura buenos niveles de ventilación al utilizar productos de limpieza, pinturas o disolventes.',
    'Check for defects in finishing materials or equipment and use formaldehyde sealants. Ensure good ventilation levels when using cleaning products, paints or solvents.',
    'Prüfen Sie auf Mängel an den Endbearbeitungsmaterialien oder -geräten und verwenden Sie Formaldehyd-Dichtungsmittel. Sorgen Sie für gute Belüftung, wenn Sie Reinigungsmittel, Farben oder Lösungsmittel verwenden.'
  ],
  badFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento, utiliza productos sellantes para formaldehído o selecciona productos sustitutivos para las fuentes de emisión detectadas. Consulta con profesional especializado si estos niveles se mantienen de forma continua.',
    'Check for defects in finishing materials or equipment, use formaldehyde sealants or select substitute products for detected emission sources. Consult a professional if these levels are maintained on a continuous basis.',
    'Prüfen Sie auf Defekte an den Ausbaumaterialien oder Geräten, verwenden Sie Formaldehyd-Dichtstoffe oder wählen Sie Ersatzprodukte für festgestellte Emissionsquellen. Wenden Sie sich an einen Fachmann, wenn diese Werte dauerhaft aufrechterhalten werden.'
  ],
  optimalO3Tip: [
    '¡Muy bien! Continúa garantizando niveles bajos de O₃ de forma continua.',
    'Very good! Continues to ensure continuously low O₃ levels.',
    'Sehr gut! Sorgt weiterhin für kontinuierlich niedrige O₃-Werte.'
  ],
  regularO3Tip: [
    'Aumenta la tasa de ventilación y revisa posibles fuentes de emisión de O₃ en equipos de ofimática, purificadores de aire, o equipos con lámparas UV.',
    'Increase the ventilation rate and check for possible sources of O₃ emissions from office equipment, air purifiers, or equipment with UV lamps.',
    'Erhöhen Sie die Belüftungsrate und überprüfen Sie mögliche Quellen von O₃-Emissionen aus Bürogeräten, Luftreinigern oder Geräten mit UV-Lampen.'
  ],
  badO3Tip: [
    'Incrementa la tasa de renovación de aire y elimina cualquier fuente de emisión de O₃ en equipos de ofimática (impresoras), purificadores de aire o equipos con lámparas UV. Consulta con un profesional especializado si estos niveles se mantienen de forma continua.',
    'Increase the air renewal rate and eliminate any source of O₃ emissions in office equipment (printers), air purifiers or equipment with UV lamps. Consult a specialised professional if these levels are maintained on a continuous basis.',
    'Erhöhen Sie die Lufterneuerungsrate und beseitigen Sie alle Quellen von O₃-Emissionen in Bürogeräten (Drucker), Luftreinigern oder Geräten mit UV-Lampen. Wenden Sie sich an einen Fachmann, wenn diese Werte ständig aufrechterhalten werden.'
  ],
  optimalCoTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de CO de forma continua.',
    'Very good! Continues to ensure low CO levels on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige CO-Werte.'
  ],
  regularCoTip: [
    'Aumenta la tasa de ventilación, localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco',
    'Increase ventilation, locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco smoke.',
    'Erhöhen Sie die Belüftungsrate, suchen Sie nach Verbrennungsgeräten (Herde, Öfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabakrauch.'
  ],
  badCoTip: [
    'Apaga cualquier equipo de combustión y sal al aire libre y/o a un lugar no contaminado, hasta que los niveles de CO<9ppm (10 mg/m3).',
    'Shut down all combustion equipment and move to fresh air and/or an uncontaminated area until CO levels are <9ppm (10 mg/m3).',
    'Schalten Sie alle Verbrennungsanlagen aus und begeben Sie sich an die frische Luft und/oder in einen nicht kontaminierten Bereich, bis die CO-Werte unter 9ppm (10 mg/m3) liegen.'
  ],
  optimalNo2Tip: [
    '¡Excelente, sigue así! Mantén los niveles de NO₂ por debajo de 21 ppb (40 ug/m3).',
    'Excellent, keep it up! Keep NO₂ levels below 21 ppb (40 ug/m3).',
    'Ausgezeichnet, weiter so! Halten Sie die NO₂-Werte unter 21 ppb (40 ug/m3).'
  ],
  regularNo2Tip: [
    'Aumenta la tasa de ventilación, localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco',
    'Increase ventilation, locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco smoke.',
    'Erhöhen Sie die Belüftungsrate, suchen Sie nach Verbrennungsgeräten (Herde, Öfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabakrauch.'
  ],
  badNo2Tip: [
    'Apaga cualquier equipo de combustión y sal al aire libre y/o a un lugar no contaminado, hasta que los niveles de CO<9ppm (10 mg/m3).',
    'Shut down all combustion equipment and move to fresh air and/or an uncontaminated area until CO levels are <9ppm (10 mg/m3).',
    'Schalten Sie alle Verbrennungsanlagen aus und begeben Sie sich an die frische Luft und/oder in einen nicht kontaminierten Bereich, bis die CO-Werte unter 9ppm (10 mg/m3) liegen.'
  ],
  optimalNoxTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de NOx de forma continua.',
    'Very good! Continues to ensure low NOx levels on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige NOx-Werte.'
  ],
  regularNoxTip: [
    'Ajusta los niveles de ventilación para reducir la concentración de NOx. Localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco y del tráfico.',
    'Adjust ventilation levels to reduce NOx concentrations. Locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco and traffic smoke.',
    'Passen Sie die Belüftungsstufen an, um die NOx-Konzentration zu verringern. Suchen Sie alle Verbrennungsgeräte (Herde, Öfen, Backöfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabak- und Verkehrsrauch.'
  ],
  badNoxTip: [
    'Apaga cualquier equipo de combustión, aumenta la tasa de renovación de aire y elimina cualquier posible fuente hasta que los niveles de NOx bajen de 150.',
    'Shut down all combustion equipment, increase air renewal rates and eliminate all possible sources until NOx levels drop below 150.',
    'Schalten Sie alle Verbrennungsanlagen aus, erhöhen Sie die Lufterneuerungsrate und beseitigen Sie alle möglichen Quellen, bis die NOx-Werte unter 150 fallen.'
  ],
  optimalNoiseTip: [
    '¡Excelente, sigue así! Continúa garantizando niveles de ruido aéreo por debajo de 30dB.',
    'Excellent, keep it up! Continue to maintain airborne noise levels below 30dB.',
    'Ausgezeichnet, weiter so! Sorgen Sie weiterhin für Luftschallpegel unter 30 dB.'
  ],
  regularNoiseTip: [
    'Revise las fuentes de contaminación por ruido aéreo (tráfico, industria, falta de aislamiento acústico) y busque estrategias para reducir la exposición al ruido.',
    'Review sources of airborne noise pollution (traffic, industry, lack of soundproofing) and look for strategies to reduce noise exposure.',
    'Überprüfen Sie die Quellen der Luftschallbelastung (Verkehr, Industrie, fehlende Schalldämmung) und suchen Sie nach Strategien zur Verringerung der Lärmbelastung.'
  ],
  badNoiseTip: [
    'Garantiza un aislamiento acústico adecuado en este espacio interior - ajusta la carpintería para evitar el efecto del ruido exterior. Consulta con un profesional especializado si estos niveles se mantienen de forma continua.',
    'Ensure adequate soundproofing in this interior space - adjust joinery to avoid the effect of outside noise. Consult a specialist if these levels are to be maintained on a continuous basis. ',
    'Sorgen Sie für eine ausreichende Schalldämmung in diesem Innenraum - passen Sie die Tischlerarbeiten an, um die Auswirkungen von Außengeräuschen zu vermeiden. Ziehen Sie einen Fachmann zu Rate, wenn die Lärmbelastung über einen längeren Zeitraum anhält.'
  ],
  optimalLightTip: [
    '¡Muy bien! Un ambiente interior lumínicamente saludable requiere de una iluminancia de entre 100 y 500 luxes para puestos de trabajo con exigencias visuales bajas,moderadas y altas.',
    'Very good! A healthy indoor lighting environment requires illuminance between 100 and 500 lux for workplaces with low, medium and high visual demands.',
    'Sehr gut! Eine gesunde Innenraumbeleuchtung erfordert Beleuchtungsstärken zwischen 100 und 500 Lux für Arbeitsplätze mit geringen, mittleren und hohen Sehanforderungen.'
  ],
  regularLightTip: [
    'Ajusta la intensidad luminosa para evitar  fatiga visual y mental, molestias oculares o deslumbramientos.',
    'Adjust light intensity to prevent visual and mental fatigue, eye discomfort or glare.',
    'Anpassung der Lichtintensität, um visuelle und mentale Ermüdung, Augenbeschwerden oder Blendung zu vermeiden.'
  ],
  badLightTip: [
    'Ajuste los niveles de iluminación para evitar exceso o defecto de iluminancia.',
    'Adjust the lighting levels to avoid over- or under-illumination.',
    'Die Beleuchtungsstärke so einstellen, dass eine Über- oder Unterbelichtung vermieden wird.'
  ],
  noDataMeasured: ['No se han medido datos', 'No data measured', 'Keine Daten gemessen'],
  forgotPassword: ['¿Has olvidado la contraseña?', 'Forgot password?', 'Passwort vergessen?'],
  resendCode: [
    'Reenviar correo de confirmación',
    'Resend confirmation email',
    'Bestätigungsmail erneut senden'
  ],
  loading: ['Cargando', 'Loading', 'Wird geladen'],
  min: ['Mín', 'Min', 'Min'],
  max: ['Máx', 'Max', 'Max'],
  mean: ['Media', 'Mean', 'Bedeuten'],

  startDate: ['Fecha de inicio', 'Start date', 'Startdatum'],
  endDate: ['Fecha final', 'End date', 'Endtermin'],
  download: ['Descargar datos', 'Download data', 'Daten herunterladen'],
  downloadData: ['Descargar datos', 'Download data', 'Daten herunterladen'],
  downloadMultipleData: [
    'Descarga de datos múltiple',
    'Multiple data download',
    'Download mehrerer Daten'
  ],
  createAlarm: ['Crear alerta', 'Create alert', 'Einen Alarm erstellen'],
  next: ['Siguiente', 'Next', 'Nächste'],
  back: ['Atrás', 'Back', 'Zurück'],

  noWeatherDataAvailable: [
    'No hay datos de tiempo disponibles.',
    'No weather data available.',
    'Keine Wetterdaten verfügbar.'
  ],
  noWeatherDataForZipcode: [
    'Lo sentimos, no disponemos de datos de tiempo exterior para el código postal introducido.',
    'We are sorry. We do not have outdoor weather data available for that zip code.',
    'We are sorry. We do not have outdoor weather data available for that zip code.'
  ],
  changeZipcode: [
    'Puedes probar a cambiarlo por un código postal cercano.',
    'You can try to change it by a near zip code.',
    'You can try to change it by a near zip code.'
  ],

  customValues: ['Valores personalizados', 'Custom values', 'benutzerdefinierte Werte'],

  yellowWarning: ['Nivel Amarillo', 'Yellow Warning', 'Gelbe Warnung'],

  redWarning: ['Nivel Rojo', 'Red Warning', 'Rote Warnung'],

  Whoops: ['Ups', 'Whoops!', 'Hoppla!'],

  noWeatherDataAvailableExtra: [
    '¿Acabas de añadir este sensor? Los datos del tiempo tardarán una media hora en aparecer.',
    'Did u just recently added this system? It will take about half an hour before we have your weather data measured.'
  ],
  makeSureCorrectZipCode: [
    'Asegurate de que has introducido el código postal correctamente.',
    'Make sure you entered the zip code correctly.',
    'Haben Sie diesen Sensor gerade hinzugefügt? Es dauert etwa eine halbe Stunde, bis die Wetterdaten angezeigt werden.'
  ],
  apply: ['APLICAR', 'APPLY', 'APPLY'],
  navDevice: ['1. Dispositivo', '1. Device', '1. Gerät'],
  navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
  navAccount: ['2. Cuenta', '2. Account', '2. Konto'],
  navEnviroment: ['2. Entorno', '2. Environment', '2. Umgebung'],
  navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
  editNavParams: ['1. Parámetros', '1. Parameters', '1. Parameter'],
  navDateAndFormat: ['2. Fechas y formato', '2. Date and format', '2. Daten und Format'],
  multipleNavDateAndFormat: ['3. Fechas y formato', '3. Date and format', '3. Daten und Format'],
  infoId: [
    'El Id se obtiene al configurar el dispositivo por primera vez desde la app',
    'Id is obtained the first time the device is configured from the app',
    'Die ID wird bei der ersten Konfiguration des Geräts über die App abgerufen'
  ],
  agree: ['Sí, eliminarlo', 'Yes, delete', 'Akzeptieren'],
  cancel: ['Cancelar', 'Cancel', 'Stornieren'],
  definedValues: ['Valores predefinidos: ', 'Define value: ', 'Wert definieren'],
  addDevice: ['Añadir dispositivo', 'Add device', 'Gerät hinzufügen'],
  buildingTypeInfo: [
    'Los siguientes campos opcionales ayudarán a optimizar más tu experiencia en My inBiot clasificando el espacio en el que has colocado tu MICA. Si no los conoces puedes añadirlos más adelante.',
    "The following optional fields will help to further optimise your My inBiot experience by classifying the space in which you have placed your MICA. If you don't know them you can add them later.",
    'Die folgenden optionalen Felder helfen Ihnen, Ihr My inBiot-Erlebnis weiter zu optimieren, indem sie den Raum klassifizieren, in dem Sie Ihr MICA platziert haben. Wenn Sie sie nicht kennen, können Sie sie später hinzufügen.'
  ],
  typeOfUseBuildingInfoN: [
    '<h4> Tipo de uso </h4>' +
      '<br/> Es importante conocer cuál es el uso principal del espacio a \n' +
      'monitorizar para ayudar a identificar patrones de uso y \n' +
      'referentes normativos. <b>Residencial</b> para uso vivienda y <b>terciario</b> para \n' +
      'oficinas, retail, centros educativos, hostelería, etc.',
    '<h4> Use of building </h4>' +
      '<br/> It is important to know the main use of the space to be monitored in the building to help identify usage patterns and regulatory references. <b>Residential</b> for housing use and <b>tertiary</b> for offices, retail, educational centres, hotels, etc.',
    '<h4> Tipo de uso </h4>' +
      '<br/>Es ist wichtig, die Hauptnutzung des zu überwachenden Raums im Gebäude zu kennen, um Nutzungsmuster und gesetzliche Vorgaben zu ermitteln. <b>Wohngebiete</b> für Wohnzwecke und <b>Tertiärgebiete</b> für Büros, Einzelhandel, Bildungszentren, Hotels usw.'
  ],
  ventilationBuildingInfoN: [
    '<h4> Tipo de ventilación </h4>' +
      '<br/> Identifica si hay o no un sistema mecánico de ventilación con impulsión y/o extracción mecánica. O si la ventilación está integrada a través del sistema de climatización. Si no hay nada y se ventila a través de ventanas y puertas, será ventilación manual.',
    '<h4> Ventilation type </h4>' +
      '<br/> Determine if there is a mechanical ventilation system with mechanical supply and/or exhaust. Or if ventilation is provided by the air conditioning system. If there is none and ventilation is through windows and doors, it is manual ventilation.'
  ],
  occupancyBuildingInfoN: [
    '<h4> Ocupación </h4>' +
      '<br/> ¿El espacio se ocupa de forma continuada - permanente, con un horario de ocupación a lo largo de todo el año – oficinas, centros educativos, hostelería, o sólo se utiliza estacionalmente, como segundas residencias o espacios para eventos u alquileres temporales?',
    '<h4> Occupancy </h4>' +
      '<br/> Is the space occupied on a continuous basis - permanent, with a year-round occupancy schedule - offices, educational facilities, hospitality - or is it used seasonally, as a second home or space for events or temporary rentals?'
  ],
  airTightnessLevelBuildingInfoN: [
    '<h4> Nivel de hermeticidad </h4>' +
      '<br/> Conocer el nivel de hermeticidad de un edificio ayuda a controlar las fugas de aire no controladas (infiltraciones) en el edificio por juntas entre materiales y/o sistemas constructivos.  Se verifican a través de pruebas de hermeticidad – generalmente blower door test n50.',
    '<h4> Tightness level </h4>' +
      '<br/> Knowing the level of airtightness of a building helps to control uncontrolled air leakage (infiltration) into the building through joints between materials and/or building systems.  It is verified by airtightness tests - usually the Blower Door test n50.'
  ],
  editDevice: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  editDevicePeriodicity: [
    'Periodicidad subida datos',
    'Periodicity of uploading data',
    'Periodizität des Hochladens von Daten'
  ],
  timeInterval: ['Periodicidad en minutos', 'Periodicity in minutes', 'Periodizität in Minuten'],
  periodicity: ['Periodicidad', 'Periodicity', 'Periodizität'],
  timeIntervalInfo: [
    'Introduce la periodicidad (en minutos) en la que tu MICA enviará datos a My inBiot.',
    'Enter the frequency (in minutes) at which your MICA will send data to My inBiot.',
    'Geben Sie die Häufigkeit (in Minuten) an, mit der Ihr MICA Daten an My inBiot senden soll.'
  ],
  editDeviceLights: ['Luz indicadora', 'Indicator light', 'Anzeigelampe'],
  co2Calibration: [
    'Auto-calibración CO<sub>2</sub>',
    'CO<sub>2</sub> auto-calibration',
    'CO<sub>2</sub> Auto-Kalibrierung'
  ],
  enableDeviceLights: [
    'Activar luz indicadora',
    'Activate indicator light',
    'Anzeigelampe einschalten'
  ],
  disableDeviceLights: [
    'Desactivar luz indicadora',
    'Deactivate indicator light',
    'Kontrollleuchte deaktivieren'
  ],
  enableLightsInfo: [
    'Si activas esta opción el MICA mantendrá encendida la luz indicadora de necesidad de ventilación.' +
      '<br/> <br/> Nota: Los cambios se aplicarán en el MICA en el siguiente ciclo subida de datos.',
    'If you activate this option, the MICA will keep the ventilation indicator light on.' +
      '<br/> <br/> Note: The changes shall be applied in the MICA on the next data upload cycle.',
    'Wenn Sie diese Option aktivieren, lässt die MICA die Belüftungsanzeige leuchten.' +
      '<br/> <br/> Anmerkung: Die Änderungen werden beim nächsten Datenupload-Zyklus in die MICA übernommen.'
  ],

  disableLightsInfo: [
    'Si desactivas esta opción el MICA mantendrá apagada la luz indicadora de necesidad de ventilación. Además, al pulsar el botón táctil, la luz indicadora se encenderá por 3 segundos y luego se apagará.' +
      '<br/> <br/> Nota: Los cambios se aplicarán en el MICA en el siguiente ciclo subida de datos.',
    'If you disable this option, the MICA will keep the ventilation indicator light off. In addition, when the touch button is pressed, the indicator light will turn on for 3 seconds and then turn off.' +
      '<br/> <br/> Note: The changes will be applied in the MICA in the next data upload cycle.',
    'Wenn Sie diese Option deaktivieren, bleibt die Lüftungsanzeige des MICA ausgeschaltet. Außerdem leuchtet beim Drücken der Berührungstaste die Kontrollleuchte 3 Sekunden lang auf und geht dann aus.' +
      '<br/> <br/> Anmerkung: Die Änderungen werden beim nächsten Datenupload-Zyklus in die MICA übernommen.'
  ],
  deviceUpdateInfo: [
    'Los cambios se aplicarán en el MICA en la siguiente toma de datos.',
    'The changes will be implemented in the MICA at the next data collection.',
    'Die Änderungen werden bei der nächsten Datenerhebung in die MICA aufgenommen.'
  ],
  downloadStatisticsInfo: [
    'Esta funcionalidad sirve para descargar los estadísticos (máximos, mínimos, medias) de un rango de tiempo (diarios, semanales, mensuales) de los parámetros de un dispositivo MICA.',
    'This functionality is used to download the statistics (maximum, minimum, average) of a time range (daily, weekly, monthly) of the parameters of a MICA device.',
    'Mit dieser Funktion können Sie die Statistiken (Maximum, Minimum, Durchschnitt) eines Zeitraums (täglich, wöchentlich, monatlich) der Parameter eines MICA-Geräts herunterladen.'
  ],
  applyToAllDevices: [
    'Aplicar a todos los dispositivos',
    'Apply to all devices',
    'Auf alle Geräte anwenden'
  ],
  applyToAllDevicesInfo: [
    'Seleccionando esta opción aplicarás los cambios a todos los MICAs asociados a esta cuenta.',
    'Selecting this option will apply the changes to all MICAs associated with this account.',
    'Wenn Sie diese Option wählen, werden die Änderungen auf alle mit diesem Konto verbundenen MICAs angewendet.'
  ],
  warning: ['Aviso', 'Warning', 'Warnung'],
  inactivity: ['Inactividad', 'Inactivity', 'Inaktivität'],
  error: ['Error', 'Error', 'Fehler'],
  selectEmails: ['Seleccionar correos', 'Select emails', 'Emails auswählen'],

  deleteSystemConfirmationText: {
    p1: [
      '¿Estas seguro de que quieres quitar el dispositivo',
      'Are you sure you want to remove the',
      'Sind Sie sicher, dass Sie das Gerät'
    ],
    p2: ['de esta cuenta ?', 'device from this account?', 'aus diesem Konto entfernen möchten?']
  },
  deleteDataConfirmationText: [
    '¿Estas seguro de que quieres borrar los datos?',
    'You are going to delete the data. Are you sure about it?',
    'Sie werden die Daten löschen. Bist du dir sicher?'
  ],
  deleteSystemFromGroupConfirmationText: {
    p1: [
      '¿Estás seguro de que quieres quitar el dispositivo',
      'Are you sure you want to remove the',
      'Sind Sie sicher, dass Sie das Gerät'
    ],
    p2: ['del grupo', 'device from the', 'aus der Gruppe'],
    p3: ['?', 'group?', 'entfernen müssen?']
  },
  deleteGroupConfirmationText: {
    p1: [
      '¿Estás seguro de que quieres borrar el grupo',
      'Are you sure you want to delete the',
      'Sind Sie sicher, dass Sie die Gruppe'
    ],
    p2: ['?', ' group?', ' löschen wollen?']
  },
  deleteLogoConfirmationText: [
    '¿Estás seguro de que quieres eliminar el logo?',
    'You are going to delete this logo. Are you sure about it?',
    'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
  ],
  deleteScreenConfirmationText: [
    '¿Estás seguro de que quieres borrar la pantalla?',
    'You are going to delete this screen. Are you sure about it?'
  ],
  deleteAlarmConfirmationText: [
    '¿Estás seguro de que quieres borrar la alarma?',
    'You are going to delete this alarm. Are you sure about it?',
    'Sie werden diesen Alarm löschen. Sind Sie sich dessen sicher?'
  ],
  deleteAlertRecipientConfirmationText: [
    '¿Estas seguro de que quieres eliminar este correo de la lista de recipientes de alertas?',
    'You are going to remove this email from the alert recipients list. Are you sure about it?',
    'Sie werden diese E-Mail aus der Liste der Alarmempfänger entfernen. Sind Sie sicher, dass dies der Fall ist?'
  ],

  // Login page
  loginPage: {
    needAnAccount: ['¿Necesitas una cuenta?', 'Need an account?', 'Benötigen Sie einen Account?'],
    termsAndConditions1: [
      'Al iniciar sesión, aceptas la',
      'When you login, you accept the',
      'Wenn Sie sich anmelden, akzeptieren Sie die'
    ],
    termsAndConditions2: [' y los', ' and the', ' und das'],
    termsAndConditions3: [' de inBiot.', ' of inBiot.', ' von inBiot.']
  },

  helpOptions: {
    howMyInbiotWorks: [
      '¿Cómo funciona My Inbiot?',
      'How My Inbiot works?',
      'Wie funktioniert mein Inbiot?'
    ],
    contactUs: ['Contacta con nosotros', 'Contact us', 'Kontaktiere uns']
  },

  // Sign up page
  signUpPage: {
    alreadyHaveAnAccount: [
      '¿Ya tienes una cuenta?',
      'Already have an account?',
      'Haben Sie bereits einen Account?'
    ]
  },

  newGroupForm: {
    selectDevices: ['Seleccionar dispositivos:', 'Select devices:', 'Geräte auswählen:']
  },

  editGroupForm: {
    selectDevices: ['Seleccionar dispositivos para añadir', 'Select devices to add'],
    groupDevices: ['Dispositivos en el grupo', 'Group devices']
  },

  confirmUserInfoPage: {
    header: [
      'Verificar dirección de correo electrónico',
      'Verify e-mail address',
      'Email Adresse bestätigen'
    ],
    subHeader: [
      'Te hemos enviado un mail a ',
      'An email has been sent to ',
      'Eine email wurde gesendet an '
    ],
    mailInstructions: [
      'Pulsa el botón "Confirmar correo electrónico" y podrás acceder a My inBiot.',
      'Press the "Verify email address" button and you could access to My inBiot.',
      'Drücken Sie die Schaltfläche "E-Mail bestätigen" und Sie können auf Mein inBiot zugreifen.'
    ],
    noMailReceived: [
      'Si no has recibido ningún correo: ',
      'If you have not received any email: ',
      'Falls Sie keine E-Mail erhalten haben: '
    ],
    resendCode: [
      'Volver a enviar correo de verificación',
      'Resend verification email',
      'Bestätigungsmail erneut senden'
    ],
    nothingReceived: [
      'Si sigues sin recibir nada, prueba a: ',
      'If you still do not receive anything, try: ',
      'Wenn Sie immer noch nichts erhalten, versuchen Sie Folgendes: '
    ],
    backToSignUp: [
      'Volver a crear cuenta',
      'Go back to sign up',
      'Gehen Sie zurück, um sich anzumelden'
    ]
  },

  // Confirm Sign Up page
  confirmUserPage: {
    header: [
      'Dirección de correo verificada con éxito.',
      'E-mail account has been successfully verified.',
      'E-Mail-Konto wurde erfolgreich verifiziert'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes acceder a My inBiot.',
      'All ready! You can now access My inBiot.',
      'Alles bereit! Sie können jetzt auf Mein inBiot zugreifen.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  // Confirm Alarm Recipient page
  confirmRecipientPage: {
    header: [
      'Dirección de correo verificada con éxito.',
      'E-mail account has been successfully verified.',
      'E-Mail-Konto wurde erfolgreich verifiziert'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes empezar a recibir alarmas en dicha dirección de correo.',
      'All ready! You can now start receiving alarms at this email address.',
      'Alles bereit! Sie können nun Alarme über diese E-Mail-Adresse empfangen.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  confirmCollaboratorPage: {
    header: [
      'Invitación aceptada con éxito.',
      'Invitation successfully accepted.',
      'Einladung erfolgreich angenommen.'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes comenzar a monitorizar la calidad de aire interior en los dispositivos a los que te han brindado acceso.',
      'All set! You can now start monitoring indoor air quality on the devices you have been given access to.',
      'Alles bereit! Sie können nun mit der Überwachung der Raumluftqualität auf den Geräten beginnen, zu denen Sie Zugang erhalten haben.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  acceptPolicyandTerms: {
    header: [
      'He leído y acepto la',
      'I have read and accept the',
      'Ich habe die AGB gelesen und stimme diesen zu.'
    ],
    policy: ['Política de Privacidad', 'Privacy Policy', 'Datenschutzrichtlinien'],
    terms: ['Términos de Uso', 'Terms of Use', 'Nutzungsbedingungen'],
    adding: ['y', 'and', 'und']
  },
  // Forgotten password page
  forgottenPasswordPage: {
    header: ['Contraseña olvidada', 'Forgot password', 'Passwort vergessen'],
    subHeader: [
      'Escribe tu dirección de correo electrónico y te enviaremos instrucciones para restablecer la contraseña.',
      'Please enter your email address and we will send you the instructions to restore the password.',
      'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen die Anweisungen zum Wiederherstellen des Passworts'
    ],
    submitButton: ['Mándame un link', 'Send me a link', 'Sende mir einen Link'],
    restore: ['Restablecer', 'Restore', 'Wiederherstellen'],
    returnTo: ['Volver a ', 'Return to ', 'Zurücksenden an ']
  },

  // Recover password page
  recoverPasswordPage: {
    header: ['Recuperar contraseña', 'Recover password', 'Passwort wiederherstellen'],
    subHeader: [
      'Se ha enviado un enlace a tu correo para proceder al cambio de contraseña.',
      'A changing password link has been sent to your mail.',
      'Ein Link zum Ändern des Passworts wurde an Ihre E-Mail gesendet.'
    ],
    submitButton: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen']
  },

  homePage: {
    editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
    deleteGroup: ['Eliminar grupo', 'Delete group', 'Gruppe löschen']
  },

  disabledSystem: [
    'El sensor ha sido desactivado.',
    'This system is disabled.',
    'Dieses System ist deaktiviert.'
  ],

  downloadDataForm: {
    selectDevices: ['Seleccionar dispositivos', 'Select devices', 'Geräte auswählen'],
    disponible: ['(Disponible: ', '(Available: ', '(Verfügbar: '],
    days: [' días)', ' days)', ' tage)'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectValues: [
      'Seleccionar valores a alertar',
      'Select values to alert',
      'Wählen Sie Werte für die Warnung aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date range',
      'Select date range',
      'Datumsbereich auswählen'
    ],
    getMoreRange: [
      '¿Cómo accedo a un mayor rango de datos?',
      'How do I access a wider range of data?',
      'Wie erhalte ich Zugang zu einer breiteren Palette von Daten?'
    ],
    downloadFormat: ['Seleccionar formato', 'Select format', 'Format auswählen'],
    downloadExtension: ['Extensión de archivo:', 'File extension: ', 'Dateierweiterung:'],
    statisticsInterval: [
      'Intervalo de estadísticos',
      'Statistics interval',
      'Statistisches Intervall'
    ],
    downloadOrder: ['Orden datos:', 'Data order:', 'Bestellung von Daten:'],
    olderDataFirst: ['Más antiguos primero', 'Older first', 'Ältere zuerst'],
    latestDataFirst: ['Más recientes primero', 'Latest first', 'Neueste zuerst'],
    dataGrouping: ['Agrupación datos', 'Data grouping', 'Gruppierung der Daten'],
    originalData: ['Datos originales', 'Original data', 'Ursprüngliche Daten'],
    grouping10: ['Medias 10min', 'Average 10min', 'Durchschnittlich 10min'],
    grouping15: ['Medias 15min', 'Average 15min', 'Durchschnittlich 15min'],
    grouping30: ['Medias 30min', 'Average 30min', 'Durchschnittlich 30min'],
    grouping1: ['Medias 1h', 'Average 1h', 'Strümpfe 1h']
  },
  profileForm: {
    micaLiteMainIndicator: [
      'Indicador principal MICA Lite',
      'Main indicator MICA Lite',
      'Hauptindikator MICA Lite'
    ],

    micaMainIndicator: ['Indicador principal MICA', 'Main indicator MICA', 'Hauptindikator MICA'],
    micaPlusMainIndicator: [
      'Indicador principal MICA Plus',
      'Main indicator MICA Plus',
      'Hauptindikator MICA Plus'
    ],
    micaDeskMainIndicator: [
      'Indicador principal MICA Desk',
      'Main indicator MICA Desk',
      'Hauptindikator MICA Desk'
    ],
    micaWellMainIndicator: [
      'Indicador principal MICA WELL',
      'Main indicator MICA WELL',
      'Hauptindikator MICA WELL'
    ]
  },
  uploadFileModal: {
    title: ['Configuración logotipo', 'Logo configuration', 'Logokonfiguration'],
    currentLogo: ['Logotipo actual', 'Current logo', 'aktuelles Logo'],
    noLogo: [
      'Actualmente no hay ningún archivo subido',
      'There are currently no files uploaded',
      'Es sind derzeit keine Dateien hochgeladen'
    ],
    deleteLogoConfirmationText: [
      '¿Estás seguro de que quieres eliminar el logo?',
      'You are going to delete this logo. Are you sure about it?',
      'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
    ],
    uploadFile: ['Subir archivo', 'Upload file', 'Datei hochladen'],
    deleteFile: ['Eliminar', 'Delete', 'Löschen'],
    selectFile: [
      'Selecciona el fichero que desea cargar',
      'Select the file to be uploaded',
      'Wählen Sie die hochzuladende Datei'
    ],
    upload: ['Subir', 'Upload', 'Hochladen'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
    cancel: ['Cancelar', 'Cancel', 'Abbrechen']
  },
  fullScreenPage: {
    link: ['Enlace', 'Link', 'Link'],
    knowIaq: [
      'Accede desde </br>tu smartphone',
      'View on your </br> smartphone',
      'Ansicht auf Ihrem </br> Smartphone'
    ],
    Excellent: ['Excelente', 'Excellent', 'Ausgezeichnete'],
    Good: ['Bueno', 'Good', 'Gute'],
    Moderate: ['Moderado', 'Moderate', 'Mäßige'],
    Regular: ['Regular', 'Regular', 'Regelmäßige'],
    Inadequate: ['Inadecuado', 'Inadequate', 'Unzureichende'],
    Poor: ['Malo', 'Poor', 'Schlechte'],
    savedShareables: ['Compartibles guardados', 'Saved shareables', 'Saved shareables'],
    searchShareable: ['Buscar compartible', 'Search shareable', 'Suche gemeinsam nutzbar'],
    uploadLogo: ['Logotipo', 'Logo', 'Logo'],
    newShareable: ['Nuevo compartible', 'New shareable', 'Neue Teilung'],
    createShareable: ['Crear compartible', 'Create shareable', 'Gemeinsam nutzbar machen'],
    editShareable: ['Editar compartible', 'Edit shareable', 'Edit shareable'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Save changes'],
    deleteSharable: ['Borrar compartible', 'Delete shareable', 'Delete shareable'],
    qrDownload: ['Descargar QR', 'Download QR', 'Download QR'],
    downloadQRCertificate: [
      'Descargar certificado QR',
      'Download QR certificate',
      'Download QR certificate'
    ],
    downloadQR: ['Descargar código QR', 'Download QR code', 'Download QR code'],
    share: ['Compartir', 'Share', 'Share'],
    shareDeviceLink: ['Copiar enlace a MICA', 'Copy link to device', 'Copy link to device'],
    shareFullscreenLink: [
      'Copiar enlace a pantalla completa',
      'Copy link to fullscreen',
      'Copy link to fullscreen'
    ],
    veryGoodIAQ: [
      'Muy buena Calidad de Aire Interior',
      'Very good Indoor Air Quality',
      'Very good Indoor Air Quality'
    ],
    veryBadIAQ: [
      'Muy mala Calidad de Aire Interior',
      'Very bad Indoor Air Quality',
      'Very bad Indoor Air Quality'
    ],
    normalIAQ: [
      'Calidad de Aire Interior normal',
      'Regular Indoor Air Quality',
      'Regular Indoor Air Quality'
    ],
    veryLowProbability: [
      'Probabilidad de propagación de virus muy baja',
      'Very low virus propagation probability',
      'Very low virus propagation probability'
    ],
    lowProbability: [
      'Probabilidad de propagación de virus baja',
      'Low virus propagation probability',
      'Low virus propagation probability'
    ],
    mediumProbability: [
      'Probabilidad de propagación de virus media',
      'Medium virus propagation probability',
      'Medium virus propagation probability'
    ],
    highProbability: [
      'Probabilidad de propagación de virus alta',
      'High virus propagation probability',
      'High virus propagation probability'
    ],
    lowVentilation: [
      'No se requiere ventilar',
      'No ventilation required',
      'Keine Belüftung erforderlich'
    ],
    mediumVentilation: ['Se recomienda ventilar', 'Ventilation recommended', 'Belüftung empfohlen'],
    highVentilation: [
      'Es necesario ventilar',
      'Ventilation is required',
      'Belüftung ist erforderlich'
    ],
    micaLiteHeading: [
      'Probabilidad de propagación de virus',
      'Virus propagation probability',
      'Virus propagation probability'
    ],
    micaMiniHeading: ['Necesidad de ventilación', 'Ventilation need', 'Ventilation need'],
    micaHeading: ['Calidad de aire interior', 'Indoor Air Quality', 'Indoor Air Quality'],
    smartphoneText: [
      'Visualiza estas mediciones y mucho más en tu smartphone',
      'View this data and more information in your smartphone',
      'View this data and more information in your smartphone'
    ],
    discoverMore: ['Descubre más en: ', 'Discover more in: ', 'Discover more in: '],
    outdoorWeather: ['Tiempo exterior', 'Outdoor Weather', 'Outdoor Weather']
  },
  listDays: [
    'Indique en que horario quiere recibir los correos',
    'Indicate when you want to receive the emails',
    'Geben Sie an, wann Sie die Mails erhalten möchten'
  ],
  lastUpdate: ['Última actualización', 'Last update', 'Last update'],

  co2CalibrationModal: {
    title: [
      'Autocalibración del sensor de CO₂',
      'CO₂ sensor self-calibration',
      'CO₂-Sensor Selbst-Kalibrierung'
    ],
    subtitle: [
      'La duración del ciclo de autocalibración varía dependiendo del tipo de ventilación utilizado en el espacio donde se encuentra el dispositivo MICA.',
      'The duration of the self-calibration cycle varies depending on the type of ventilation used in the space where the MICA device is located.',
      'Die Dauer des Selbstkalibrierungszyklus hängt von der Art der Belüftung des Raums ab, in dem sich die MICA-Einrichtung befindet.'
    ],
    info48: [
      'Ciclo de calibración de 48 h: recomendado para espacios ventilados manualmente, donde las concentraciones de CO2 del aire fresco se alcanzan al menos una vez cada 48 horas.',
      '48h calibration cycle: recommended for manually ventilated spaces, where the fresh air CO2 concentrations are achieved at least once every 48 hours.',
      '48-Stunden-Kalibrierungszyklus: empfohlen für manuell belüftete Räume, in denen die CO2-Konzentration der Frischluft mindestens einmal alle 48 Stunden gemessen wird.'
    ],
    info24: [
      'Ciclo de calibración de 24h: recomendado para espacios ventilados mecánicamente o con sistemas HVAC, donde las concentraciones de CO2 en aire fresco se alcanzan diariamente.',
      '24h calibration cycle: recommended for mechanically ventilated spaces or spaces with HVAC systems, where the fresh air CO2 concentrations are achieved daily.',
      '24-Stunden-Kalibrierungszyklus: empfohlen für mechanisch belüftete Räume oder Räume mit HVAC-Systemen, in denen die CO2-Konzentration der Frischluft täglich erreicht wird.'
    ],
    info7: [
      'Ciclo de calibración de 7 días: recomendado para espacios mal ventilados en los que puede ser difícil alcanzar concentraciones óptimas de CO2 en el aire fresco.',
      '7 days calibration cycle: recommended for poorly ventilated spaces where optimal fresh air CO2 concentrations may be difficult to achieve.',
      '7-Tage-Kalibrierungszyklus: empfohlen für schlecht belüftete Räume, in denen eine optimale CO2-Konzentration der Frischluft schwer zu erreichen ist.'
    ],
    infoOff: [
      'Desactivar calibración: no se recomienda desactivar la calibración, a menos que sea necesario para realizar pruebas en entornos extraordinarios, como laboratorios.',
      'Disable calibration: it is not recommended to disable calibration, unless it is required for testing in extraordinary environments such as laboratories.',
      'Kalibrierung deaktivieren: Es wird nicht empfohlen, die Kalibrierung zu deaktivieren, es sei denn, sie ist für Tests in außergewöhnlichen Umgebungen wie z. B. in Labors erforderlich. '
    ],
    calibration48h: [
      'Ciclo de calibración de 48 h',
      '48h calibration cycle',
      '48-Stunden-Kalibrierungszyklus'
    ],
    calibration24h: [
      'Ciclo de calibración de 24 h',
      '24h calibration cycle',
      '24-Stunden-Kalibrierungszyklus'
    ],
    calibration7d: [
      'Ciclo de calibración de 7 días',
      '7 days calibration cycle',
      '7-Tage-Kalibrierungszyklus'
    ],
    calibrationOff: ['Desactivar calibración', 'Disable calibration', 'Kalibrierung deaktivieren'],
    calibration15: [
      'Ciclo de calibración de 15 días',
      '15 days calibration cycle',
      '15-Tage-Kalibrierungszyklus'
    ],

    info15: [
      'Ciclo de calibración de 15 días: recomendado para espacios sin ventilación.',
      '15 days calibration cycle: recommended for unventilated spaces.',
      '7-tägiger Kalibrierungszyklus: für unbelüftete Räume empfohlen.'
    ],
    note: [
      'Nota: para asegurar un funcionamiento óptimo del sensor, trate de alcanzar el nivel de aire fresco exterior (400 ppm de CO₂) durante el ciclo de autocalibración, al menos en una ocasión.',
      'Note: To ensure optimal sensor performance, try to reach the outside fresh air level (400 ppm CO₂) during the self-calibration cycle at least once.',
      'Hinweis: Um eine optimale Sensorleistung zu gewährleisten, versuchen Sie, während des Selbstkalibrierungszyklus mindestens einmal den Außenluftpegel (400 ppm CO₂) zu erreichen.'
    ]
  },

  addAlertModal: {
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectParameters: ['Seleccionar parámetros', 'Select parameters', 'Parameter auswählen'],
    rangeCo2Error: [
      'Debes introducir un valor superior a 400',
      'You must enter a value greater than 400',
      'Sie müssen einen Wert größer als 400 eingeben'
    ],
    rangeError: [
      'Debes introducir un valor superior a 0',
      'You must enter a value greater than 0',
      'Sie müssen einen Wert größer als 0 eingeben'
    ]
  },

  addInactivityAlertModal: {
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  addParameterAlertModal: {
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navParams: ['2. Parámetros', '2. Parameter', '2. Parameter'],
    navValues: ['3. Valores', '3. Values', '3. Werte'],
    navEmails: ['4. Correos', '4. E-Mails', '4. E-Mails']
  },

  addErrorAlertModal: {
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  editErrorAlertModal: {
    navValues: ['1. Valores', '1. Values', '1. Werte'],
    navEmails: ['2. Correos', '2. E-Mails', '2. E-Mails']
  },

  editInactivityAlertModal: {
    navValues: ['1. Valores', '1. Values', '1. Werte'],
    navEmails: ['2. Correos', '2. E-Mails', '2. E-Mails']
  },

  editParameterAlertModal: {
    navParams: ['1. Parámetros', '1. Parameter', '1. Parameter'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  addFullScreenModal: {
    newShareable: ['Nuevo compartible', 'New shareable', 'Neue Teilung'],
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navIndicators: ['2. Indicadores', '2. Indicators', '2. Indikatoren'],
    navParams: ['3. Parámetros', '3. Parameters', '3. Parameter'],
    navPrivacy: ['4. Privacidad', '4. Privacy', '4. Privatsphäre'],
    screenViewName: ['Nombrar la pantalla', 'Screen View name', 'Benennen Sie den Bildschirm'],
    selectDevice: ['Seleccionar dispositivo: ', 'Select device: ', 'Gerät auswählen: '],
    selectIndicator: [
      'Selecciona que indicador quieres mostrar: ',
      'Select which indicator you want to display: ',
      'Wählen Sie aus, welchen Indikator Sie anzeigen möchten:'
    ],
    noIndicator: ['Sin indicador', 'No indicator', 'Kein Indikator'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    infoLabelRadio: [
      'Acceso público al MICA',
      'Public access to MICA',
      'Öffentlicher Zugang zu MICA'
    ],
    infoPublic: [
      'Si desactivas esta opción el link compartible a tu dispositivo MICA quedará inutilizado y nadie podrá acceder a él.' +
        'Tampoco se mostrará el código QR en la Pantalla Completa. Puedes editarlo más tarde en editar compartible. ',
      'If you disable this option, the shareable link to your MICA device will be unused and no one will be able to access it. ' +
        'The QR code will also not be displayed on the Full Screen. You can edit it later in edit shareable.',
      'Wenn Sie diese Option deaktivieren, wird der gemeinsame Link zu Ihrem MICA-Gerät nicht genutzt und niemand kann darauf zugreifen.' +
        'Der QR-Code wird auch nicht im Vollbildmodus angezeigt. Sie können es später in Edit Shareable bearbeiten.'
    ],
    infoShowLogo: ['Mostrar logotipo personal', 'Show personal logo', 'Persönliches Logo anzeigen'],
    howToShowLogo: [
      '¿Cómo muestro mi logotipo en el compartible?',
      'How do I show my logo in the shareable?',
      'Wie zeige ich mein Logo in der Freigabe?'
    ],
    createShareable: ['Crear compartible', 'Create shareable', 'Gemeinsam nutzbar machen']
  },

  editFullScreenModal: {
    editShareable: ['Editar compartible', 'Edit shareable', 'Edit shareable'],
    navGeneral: ['1. General', '1. Overview', '1. Überblick'],
    navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
    navPrivacy: ['3. Privacidad', '3. Privacy', '3. Privatsphäre'],
    screenViewName: ['Nombrar la pantalla', 'Screen View name', 'Benennen Sie den Bildschirm'],
    selectIndicator: [
      'Selecciona que indicador quieres mostrar: ',
      'Select which indicator you want to display: ',
      'Wählen Sie aus, welchen Indikator Sie anzeigen möchten:'
    ],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    infoLabelRadio: [
      'Acceso público al MICA',
      'Public access to MICA',
      'Öffentlicher Zugang zu MICA'
    ],
    infoPublic: [
      'Si desactivas esta opción el link compartible a tu dispositivo MICA quedará inutilizado y nadie podrá acceder a él.' +
        'Tampoco se mostrará el código QR en la Pantalla Completa. Puedes editarlo más tarde en editar compartible. ',
      'If you disable this option, the shareable link to your MICA device will be unused and no one will be able to access it. ' +
        'The QR code will also not be displayed on the Full Screen. You can edit it later in edit shareable.',
      'Wenn Sie diese Option deaktivieren, wird der gemeinsame Link zu Ihrem MICA-Gerät nicht genutzt und niemand kann darauf zugreifen.' +
        'Der QR-Code wird auch nicht im Vollbildmodus angezeigt. Sie können es später in Edit Shareable bearbeiten.'
    ],
    infoShowLogo: ['Mostrar logotipo personal', 'Show personal logo', 'Persönliches Logo anzeigen'],
    howToShowLogo: [
      '¿Cómo muestro mi logotipo en el compartible?',
      'How do I show my logo in the shareable?',
      'Wie zeige ich mein Logo in der Freigabe?'
    ],
    saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern']
  },

  outdoorWeather: {
    clearSky: ['Cielo despejado', 'Clear sky', 'Klarer himmel'],
    fewClouds: ['Pocas nubes', 'Few clouds', 'Wenige wolken'],
    scatteredClouds: ['Nubes dispersas', 'Scattered Clouds', 'Vereinzelte wolken'],
    brokenClouds: ['Nubes rotas', 'Broken clouds', 'Zerrissene wolken'],
    showerRain: ['Lluvias y chubascos', 'Shower rain', 'Dusche regen'],
    rain: ['Lluvia', 'Rain', 'Regen'],
    thunderstorm: ['Tormenta eléctrica', 'Thunderstorm', 'Gewittersturm'],
    snow: ['Nieve', 'Snow', 'Schnee'],
    mist: ['Niebla', 'Mist', 'Nebel']
  },

  reportPage: {
    report: ['Informes Inteligentes', 'Smart Report', 'Intelligente Berichterstattung'],
    reportCoverPageTitle: ['¿En qué consisten?', 'What is it?', 'Was ist das?'],
    reportCoverPage: [
      'Los informes inteligentes son reportes que pueden descargarse de forma periódica para conocer de forma precisa y visual el estado y evolución de la calidad del aire interior. Los parámetros mostrados en cada informe dependerán del tipo de MICA y los parámetros monitorizados en cada caso. Son informes que facilitan la interpretación de los datos durante períodos de monitorización específicos y permiten identificar de forma clara las áreas de mejora para tomar medidas eficaces.',
      'Smart reports are reports that can be downloaded on a regular basis in order to know in a precise and visual way the status and evolution of the indoor air quality. The parameters shown in each report will depend on the type of MICA and the parameters monitored in each case. These reports facilitate the interpretation of the data during specific monitoring periods and allow to clearly identify areas for improvement in order to take effective measures.',
      'Intelligente Berichte sind Berichte, die regelmäßig heruntergeladen werden können, um den Status und die Entwicklung der Luftqualität in Innenräumen präzise und visuell darzustellen. Die in jedem Bericht angezeigten Parameter hängen von der Art der MICA und den jeweils überwachten Parametern ab. Diese Berichte erleichtern die Interpretation der Daten während bestimmter Überwachungszeiträume und ermöglichen es, Bereiche mit Verbesserungsbedarf klar zu identifizieren, um wirksame Maßnahmen zu ergreifen.'
    ],
    reportSecondPageTitle: ['Resumen', 'Summary', 'Samenvatting'],
    reportSecondPage: [
      'Convertir los datos en información implica definir muy bien qué interesa evaluar de cada parámetro. Por ello, la información se muestra de forma gráfica para cada parámetro y cada uno de los indicadores de calidad del aire, confort, necesidad de ventilación o propagación de virus disponibles en My inBiot.',
      'Converting data into information implies defining very well what is to be evaluated for each parameter. Therefore, the information is displayed graphically for each parameter and each of the indicators of air quality, comfort, ventilation requirements or virus propagation available in My inBiot.',
      'Het omzetten van gegevens in informatie houdt in dat zeer goed moet worden gedefinieerd wat voor elke parameter moet worden geëvalueerd. Daarom wordt de informatie voor elke parameter en elk van de in My inBiot beschikbare indicatoren voor luchtkwaliteit, comfort, ventilatiebehoeften of virusverspreiding grafisch weergegeven.'
    ],
    reportThirdPageTitle: ['Página ejemplo', 'Example page', 'Beispielseite'],
    reportThirdPage: [
      '¿Quieres valorar la información que ofrecen los Informes Inteligentes? Aquí tienes una muestra del tipo de información que se muestra sobre cada parámetro. Una página completa con información sobre los rangos alcanzados durante todo el periodo de ocupación. Y una gráfica de evaluación durante todo el periodo de monitorización.',
      'Do you want to evaluate the information offered by the Smart reports? Here is a sample of the type of information displayed for each parameter. A complete page with information on the ranges achieved during the entire occupancy period. And an evaluation graph during the entire monitoring period.',
      'Wilt u de informatie van de Smart reports evalueren? Hier volgt een voorbeeld van het type informatie dat voor elke parameter wordt weergegeven. Een volledige pagina met informatie over de bereikte bereiken gedurende de gehele bezettingsperiode. En een evaluatiegrafiek voor de gehele bewakingsperiode.'
    ],
    newReport: ['Nuevo Informe Inteligente', 'New Smart Report ', 'Neuer intelligenter Bericht']
  },
  addReportModal: {
    rangeTimeDisponible: [
      'Rango de tiempo disponible',
      'Available time range',
      'Verfügbare Zeitspanne'
    ],
    rangeTimeInfo: [
      'El número de dispositivos seleccionado afectará al rango de tiempo disponible analizado en el reporte. Para ampliar el rango de tiempo seleccione menos dispositivos.',
      'The number of devices selected will affect the available time range analyzed in the report. To extend the time range select fewer devices.',
      'Die Anzahl der ausgewählten Geräte wirkt sich auf die im Bericht analysierte verfügbare Zeitspanne aus. Um den Zeitbereich zu erweitern, wählen Sie weniger Geräte aus.'
    ],

    selectedDevice: ['Dispositivos seleccionados', 'Selected devices', 'Ausgewählte Geräte'],
    deviceNumber: [
      'El número de dispositivos seleccionado afectará al rango de tiempo disponible analizado en el reporte.',
      'The number of devices selected will affect the range of available time analyzed in the report.',
      'Die Anzahl der ausgewählten Geräte wirkt sich auf den Bereich der verfügbaren Zeit aus, der im Bericht analysiert wird.'
    ],
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
    navInterval: ['3. Intervalo', '3. Interval', '2. Intervall'],
    navOccupation: ['4. Ocupación', '4. Occupancy', '4. Bezetting'],
    next: ['Siguiente', 'Next', 'Nächste'],
    back: ['Atrás', 'Back', 'Zurück'],
    cancel: ['Cancelar', 'Cancel', 'Stornieren'],
    location: ['Ubicación', 'Location', 'Locatie'],
    newReport: ['Nuevo informe inteligente', 'New smart report ', 'Nieuw slim rapport'],
    reportName: [
      'Nombre del informe inteligente',
      'Smart Report Name',
      'Intelligenter Bericht Name'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectParameters: [
      'Selecciona los parámetros que quieres visualizar en el informe:',
      'Select the parameters you want to display in the report:',
      'Selecteer de parameters die u in het rapport wilt weergeven:'
    ],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date range',
      'Datumsbereich auswählen'
    ],
    startDate: ['Fecha de inicio', 'Start date', 'Startdatum'],
    endDate: ['Fecha final', 'End date', 'Endtermin'],
    occupationInterval: ['Intervalo de ocupación ', 'Occupation interval ', 'Bezetting interval'],
    occupationDay: ['Días de ocupación', 'Days of occupancy', 'Bezettingsdagen'],
    occupationSchedule: ['Horario de ocupación', 'Occupation schedule', 'Uren van bezetting'],
    firstOccupationHours: ['Franja 1', 'Time frame 1', 'Freistaat 1'],
    secondOccupationHours: ['Franja 2', 'Time frame 2', 'Freistaat 2'],
    monday: ['Lu', 'Mon', 'Mo'],
    tuesday: ['Ma', 'Tue', 'Di'],
    wednesday: ['Mi', 'Wed', 'Mi'],
    thursday: ['Ju', 'Thu', 'Do'],
    friday: ['Vi', 'Fri', 'Fr'],
    saturday: ['Sa', 'Sat', 'Sa'],
    sunday: ['Do', 'Sun', 'So'],
    downloadReport: ['Descargar informe', 'Download report', 'rapport downloaden']
  },
  off: ['OFF', 'OFF', 'OFF'],
  alertsPage: {
    savedAlerts: ['Alertas guardadas', 'Saved alerts', 'Gespeicherte Warnungen'],
    newAlert: ['Nueva alerta', 'New alert', 'Neue Warnungen'],
    searchAlerts: ['Buscar alerta', 'Search alert', 'Suchmeldung'],
    editAlert: ['Editar alerta', 'Edit alert', 'Alarm bearbeiten'],
    deleteAlert: ['Borrar alerta', 'Delete alert', 'Alarm löschen'],
    alarmName: ['Nombrar la alarma', 'Alert name', 'Alarmname'],
    inactivityAlarms: ['Alertas de inactividad', 'Inactivity alerts', 'Inaktivitätswarnungen'],
    parameterAlarms: ['Alertas de parametros', 'Parameters alerts', 'Parameterwarnungen'],
    deviceOrGroupName: ['Nombre del dispositivo', 'Device Name', 'Gerätename'],
    addEmail: ['Añadir nuevo correo', 'Add new email', 'Neue email hinzufügen'],
    searchEmail: ['Buscar correo', 'Search email', 'Suche email']
  },
  information: ['Información', 'Information', 'Informationen'],
  manageUser: ['Gestión usuarios', 'Users management', 'Benutzerverwaltung'],
  MyAccesses: ['Mis accesos', 'My Accesses', 'Mein Zugang'],
  controlAccess: [
    'Controle el acceso de otros usuarios al panel de control de sus dispositivos MICA.',
    "Control other users' access to the control panel of your MICA devices.",
    'Kontrollieren Sie den Zugriff anderer Benutzer auf das Bedienfeld Ihrer MICA-Geräte.'
  ],
  controlCollaboratorAccess: [
    'Controle las cuentas a las que tiene acceso como colaborador.',
    'Control the accounts to which you have access as a collaborator.',
    'Kontrolle der Konten, auf die Sie als Mitarbeiter Zugriff haben.'
  ],
  myRole: ['Mi rol: ', 'My role: ', 'Meine Rolle: '],
  existingAccesses: ['Accesos existentes', 'Existing accesses', 'Bestehende Zugänge'],
  existingUsers: ['Usuarios existentes', 'Existing users', 'Bestehende Nutzer'],
  addUsers: ['Añadir', 'Add', 'Hinzufügen'],
  addUserCondition: [
    'Al añadir un usuario nuevo, se creará una cuenta en My inBiot automáticamente (si no está creada). La información de inicio de sesión le llegará al correo al usuario.',
    'When adding a new user, a My inBiot account will be created automatically. The login information will be emailed to the user.',
    ''
  ],
  devices: ['DISPOSITIVOS', 'DEVICES', 'GERÄTE'],
  data: ['DATOS', 'DATA', 'DATEN'],
  addNewUser: ['Añadir nuevo usuario', 'Add new user', 'Neuen Benutzer hinzufügen'],
  editUser: ['Editar usuario', 'Edit user', 'Benutzer bearbeiten'],
  deleteUser: ['Eliminar usuario', 'Delete user', 'Benutzer löschen'],
  certificationsPage: {
    version41: ['Versión 4.1', 'Version 4.1', 'Version 4.1'],
    version5: ['Versión 5', 'Version 5', 'Version 5'],
    titleMore: ['Más Información', 'More Information', 'Weitere Informationen'],
    certificationMain: [
      'Conoce nuestro servicio de cumplimiento de diferentes certificaciones ambientales a partir de nuestras soluciones de monitorización de la calidad del aire interior.',
      'Learn about our service for compliance with different environmental certifications from our indoor air quality monitoring solutions.',
      'Informieren Sie sich über unseren Service zur Einhaltung verschiedener Umweltzertifizierungen auf der Grundlage unserer Lösungen zur Überwachung der Innenraumluftqualität.'
    ],
    Seleccionar: ['Seleccionar dispositivo', 'Select device', 'Gerät auswählen'],
    edit: ['Editar', 'Edit', 'bearbeiten'],
    temperatureInfo: [
      'Para cumplir con la certificación RESET la temperatura  debe ser monitorizada diariamente.',
      'To comply with RESET certification the temperature must be monitored daily.',
      'Um die RESET-Zertifizierung zu erhalten, muss die Temperatur täglich überwacht werden.'
    ],
    humidityInfo: [
      'Para cumplir con la certificación RESET la humedad relativa  debe ser monitorizada diariamente.',
      'To comply with RESET certification the relative humidity must be monitored daily.',
      'Um die RESET-Zertifizierung zu erfüllen, muss die relative Luftfeuchtigkeit täglich überwacht werden.'
    ],
    pm25Info: [
      'Para cumplir con la certificación RESET la media diaria de PM 2.5  durante el horario de ocupación debe ser menor a 35 µg/m³ (< 12 µg/m³ para High Performance).',
      'To comply with RESET certification the daily average PM 2.5 during the hours of occupancy must be less than 35 µg/m³ (< 12 µg/m³ for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von PM 2,5 während der Belegungszeiten unter 35 µg/m³ (< 12 µg/m³ für High Performance) liegen.'
    ],
    pm25InfoShell: [
      'Para cumplir con la certificación RESET la media diaria de PM 2.5  durante el horario de ocupación debe ser menor o igual a 12 µg/m³ (o Reduccón ≥ 75% ).',
      'To comply with RESET certification the daily average PM 2.5 during the hours of occupancy must be less than or equal to 12 µg/m³ (or Reduction ≥ 75% ).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von PM 2,5 während der Belegungszeiten kleiner oder gleich 12 µg/m³ (oder Ermäßigung ≥ 75% ) sein.'
    ],
    co2Info: [
      'Para cumplir con la certificación RESET la media diaria de CO₂  durante el horario de ocupación debe ser menor a 1000 ppm (< 600 ppm para High Performance).',
      'To comply with RESET certification the daily average CO₂ during the hours of occupancy must be less than 1000 ppm (< 600 ppm for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt an CO₂ während der Belegungszeiten unter 1000 ppm (< 600 ppm für High Performance) liegen.'
    ],
    co2InfoShell: [
      'Para cumplir con la certificación RESET la media diaria de CO₂ durante el horario de ocupación debe ser menor a 800 ppm.',
      'To comply with RESET certification, the daily average of CO₂ during the hours of occupancy must be less than 800 ppm.',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt des CO₂ während der Belegungszeiten unter 800 ppm liegen.'
    ],
    vocsInfo: [
      'Para cumplir con la certificación RESET la media diaria de TVOC  durante el horario de ocupación debe ser menor a 500 µg/m³ (< 400 µg/m³ para High Performance).',
      'To comply with RESET certification the daily average TVOC during the hours of occupancy must be less than 500 µg/m³ (< 400 µg/m³ for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt der TVOC während der Belegungszeiten unter 500 µg/m³ (< 400 µg/m³ für High Performance) liegen.'
    ],
    vocsInfoShell: [
      'Para cumplir con la certificación RESET la media diaria de TVOC  durante el horario de ocupación debe ser menor a 400 µg/m³.',
      'To comply with RESET certification, the daily average TVOC during the hours of occupancy must be less than 400 µg/m³.',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von TVOC während der Belegungszeiten unter 400 µg/m³ liegen.'
    ],
    noData: [
      'No se encontraron datos, compruebe que el dispositivo está conectado.',
      'No data found, check if the device is connected.',
      'Keine Daten gefunden, überprüfen Sie, ob das Gerät angeschlossen ist.'
    ],
    micaLiteVocs: [
      'Los dispositivos MICA Lite no incluyen sensor de TVOC.',
      'MICA Lite devices do not include a TVOC sensor.',
      'Die MICA Lite-Geräte enthalten keinen TVOC-Sensor.'
    ],
    resetComercial: ['Commercial Interiors', 'Commercial Interiors', 'Commercial Interiors'],
    selectDevice: [
      'Seleccione un dispositivo e indique el horario de ocupación para ver información en tiempo real',
      'Select a device and enter the hours of occupancy to view real-time information',
      'Wählen Sie ein Gerät aus und geben Sie die Belegungszeiten ein, um Echtzeitinformationen anzuzeigen'
    ],
    secondBand: ['Franja horaria 2 ', 'Time band 2 ', 'Zeitfenster 2 '],
    change: ['APLICAR', 'APPLY', 'APPLY'],
    occupancyBand: ['Franja de ocupación', 'Occupancy band', 'Belegungsband'],
    firstBand: ['Franja horaria 1', 'Time band 1', 'Zeitfenster 1'],

    occupancyBandInfo: [
      'Período de tiempo de la semana en que el espacio se encuentra en uso (ocupado). Si la ocupación responde a una jornada partida o intermitente, utilice la franja horaria 2 para recoger con mayor precisión el horario de ocupación',
      'Time period of the week when the space is in use (occupied). If occupancy is on a split or intermittent basis, use time slot 2 to more accurately capture the hours of occupancy.',
      'Zeitraum in der Woche, in dem der Raum genutzt wird (belegt ist). Bei geteilter oder intermittierender Belegung ist Zeitfenster 2 zu verwenden, um die Belegungszeiten genauer zu erfassen.'
    ],

    monday: ['Lu', 'Mon', 'Mo'],
    tuesday: ['Ma', 'Tue', 'Di'],
    wednesday: ['Mi', 'Wed', 'Mi'],
    thursday: ['Ju', 'Thu', 'Do'],
    friday: ['Vi', 'Fri', 'Fr'],
    saturday: ['Sa', 'Sat', 'Sa'],
    sunday: ['Do', 'Sun', 'So'],
    occupancyDays: ['Días de ocupación', 'Occupancy days', 'Belegungstage'],
    start: ['Inicio', 'Start', 'Startseite'],
    end: ['Fin', 'End', 'Ende'],
    dateOcuppancy: ['Fechas de ocupación', 'Occupancy dates', 'Daten der Beschäftigung'],
    device: ['Dispositivo', 'Device', 'Gerät'],
    occupancyTitle: ['Media diaria', 'Daily average', 'Täglicher Durchschnitt'],
    occupancyInfo: [
      'Estos valores hacen referencia a la media diaria durante ocupación de los últimos 30 días naturales.',
      'These values refer to the daily average during occupancy for the last 30 calendar days.',
      'Diese Werte beziehen sich auf den Tagesdurchschnitt während der Belegung in den letzten 30 Kalendertagen.'
    ],
    occupancyInfo1: [
      'Para cumplir con la certificación RESET hay que garantizar que la media diaria de las medidas se encuentran dentro del rango permitido durante el horario de ocupación del espacio.',
      'To comply with RESET certification, it must be ensured that the daily average of the measurements are within the permitted range during the hours of occupancy of the space.',
      'Um die RESET-Zertifizierung zu erhalten, muss sichergestellt werden, dass der Tagesdurchschnitt der Messungen während der Belegungszeiten des Raumes innerhalb des zulässigen Bereichs liegt.'
    ],
    resetTitleImageHeader1: [
      'Parámetros y requisitos para proyectos RESET “Commercial Interiors”',
      'Parameters and requirements for RESET "Commercial Interiors" projects',
      'Parameter und Anforderungen für RESET "Commercial Interiors"-Projekte'
    ],
    RealTimeTitle: [
      'Media diaria (últimos 30 días)',
      'Daily average (last 30 days)',
      'Tagesdurchschnitt (letzte 30 Tage)'
    ],
    realTime: ['Tiempo Real', 'Real Time', 'Echtzeit'],
    ResetInformationIAQ: [
      'El algoritmo de análisis de datos de RESET compila las medias diarias calculadas a partir de las horas de ocupación y las compara con​ los límites de calidad del aire interior de los objetivos de calidad del aire interior de RESET™ Air for Commercial Interiors o RESET™ Air Core &Shell. Los datos son enviados vía API con la RESET Assessment Cloud.​',
      "RESET's data analysis algorithm compiles daily averages calculated from occupancy hours and compares them to the indoor air quality limits of the RESET™ Air for Commercial Interiors or RESET™ Air Core &Shell indoor air quality objectives. The data is sent via API with the RESET Assessment Cloud.",
      'Der Datenanalysealgorithmus von RESET stellt Tagesmittelwerte zusammen, die aus Belegungsstunden berechnet werden, und vergleicht sie mit den Innenraumluftqualitätsgrenzwerten der RESET™ Air for Commercial Interiors oder RESET™ Air Core &Shell Innenraumluftqualitätsziele. Die Daten werden über API an die RESET Assessment Cloud gesendet.'
    ],
    ResetInformationIAQ1: [
      'Aquí puedes revisar los requisitos de RESET en cuanto a monitorización de la calidad del aire interior.',
      "You can review RESET's indoor air quality monitoring requirements here.",
      'Hier können Sie die Anforderungen von RESET für die Überwachung der Innenraumluftqualität überprüfen.'
    ],
    visitSite: ['VISITAR SITIO', 'VISIT SITE', 'SITE BESUCHEN'],
    micaResetInformation: [
      'MICA cuenta con la acreditación RESET de Grado B y cumple con todos los requisitos establecidos.',
      'MICA has RESET Grade B accreditation and complies with all established requirements.',
      'Die MICA ist nach RESET akkreditiert und erfüllt alle festgelegten Anforderungen.'
    ],
    micaResetInformation1: [
      'Revisa aquí los requisitos específicos solicitados por RESET para monitores de calidad del aire interior',
      'Check here the specific requirements requested by RESET for indoor air quality monitors.',
      'Prüfen Sie hier die von RESET geforderten spezifischen Anforderungen für die Überwachung der Luftqualität in Innenräumen.'
    ],
    dataResetTitle: ['Dispositivos MICA', 'MICA devices', 'MICA-Geräte'],
    dataResetTitle2: ['Monitorización IAQ', 'IAQ monitoring', 'IAQ-Überwachung'],
    certifications: ['Certificaciones', 'Certifications', 'Zertifizierungen'],
    information: ['Información', 'Information', 'Informationen'],
    reports: ['Reportes', 'Reports', 'Berichte'],
    justification: ['Justificación', 'Justification', 'Rechtfertigung'],
    wellStandard: ['WELL Standard', 'WELL Standard', 'WELL Standard'],

    wellPerformanceRating: [
      'WELL Performance Rating',
      'WELL Performance Rating',
      'WELL Performance Rating'
    ],
    reset: ['RESET', 'RESET', 'RESET'],
    leed: ['LEED', 'LEED', 'LEED'],
    leedBdTitle: [
      'Parámetros y requisitos para proyectos LEED BD+C ID+C ',
      'Parameters and Requirements for LEED BD+C ID+C Projects',
      'Parameter und Anforderungen für LEED BD+C ID+C-Projekte'
    ],
    leedV4omTitle: [
      'Parámetros y requisitos para proyectos O+M',
      'Parameters and requirements for O+M projects',
      'Parameter und Anforderungen für O+M-Projekte'
    ],
    leedtextV4OM: [
      'Dentro de la categoría de Calidad de Ambiente Interior (EQ) de LEED, la monitorización de la calidad del aire puede ayudar a obtener puntos en distintos apartados.',
      "Within LEED's Indoor Environmental Quality (EQ) category, air quality monitoring can help score points under several different headings.",
      'Im Rahmen der LEED-Kategorie Innenraumqualität (EQ) kann die Überwachung der Luftqualität dazu beitragen, in verschiedenen Bereichen Punkte zu erzielen.'
    ],
    leedV5omTitle: [
      'Parámetros y requisitos para proyectos LEED O+M (Operations and Maintenance)',
      'Parameters and requirements for LEED O+M (Operations and Maintenance) projects',
      'Parameter und Anforderungen für LEED O+M (Betrieb und Instandhaltung) Projekte'
    ],
    leedtextV5OM: [
      'Las soluciones de inBiot ayudan a obtener puntos para la certificación LEED en proyectos O+M, a través del crédito de rendimiento de calidad de aire interior (Indoor Air Quality Performance) de la categoría Calidad de Ambiente Interior (EQ), y más en concreto, a través de la opción de medición de aire interior (Option 1. Measure Indoor Air (1 – 10 points)). De esta manera, los proyectos con soluciones inBiot pueden sumar hasta 10 puntos adicionales. La monitorización puede dar hasta 6 puntos. En caso de que se cumplan los umbrales establecidos, los puntos obtenidos pueden llegar a ser hasta 10. El periodo de muestreo debe ser como mucho de 15 minutos para el CO₂ y de 1 hora para el resto de parámetros. ',
      'inBiot solutions help to earn points towards LEED certification for O+M projects through the Indoor Air Quality Performance credit in the Indoor Environment Quality (EQ) category, and more specifically through the Measure Indoor Air option (Option 1. Measure Indoor Air (1 - 10 points)). In this way, projects with inBiot solutions can score up to 10 additional points. Monitoring can score up to 6 points. In case the established thresholds are met, the points obtained can be up to 10 points. The sampling period should be at most 15 minutes for CO₂ and 1 hour for all other parameters.',
      'Die Lösungen von inBiot helfen dabei, LEED-Zertifizierungspunkte für O+M-Projekte durch den Indoor Air Quality Performance Credit in der Kategorie Indoor Environment Quality (EQ) zu erhalten, und zwar durch die Option Measure Indoor Air (Option 1. Measure Indoor Air (1 - 10 points)). Auf diese Weise können Projekte mit inBiot-Lösungen bis zu 10 zusätzliche Punkte erzielen. Das Monitoring kann bis zu 6 Punkte erreichen. Werden die festgelegten Schwellenwerte eingehalten, können bis zu 10 Punkte erreicht werden. Die Dauer der Probenahme sollte für CO₂ höchstens 15 Minuten und für alle anderen Parameter höchstens 1 Stunde betragen.'
    ],
    leedV5FootNote: [
      '* Los puntos para cada parámetro pueden obtenerse por monitorización y/o por cumplir los umbrales.',
      '* Points can be earned for each parameter by monitoring and/or meeting thresholds.',
      '* Punkte für jeden Parameter können durch Überwachung und/oder Einhaltung von Schwellenwerten erreicht werden.'
    ],
    wellStandardInformationText1: [
      'Es una certificación centrada en la salud y confort de las personas usuarias, basada en el rendimiento del edificio y no tanto en la prescripción de soluciones específicas.',
      'It is a certification focused on the health and comfort of the users, based on the performance of the building and not so much on the prescription of specific solutions.',
      'Es handelt sich um eine Zertifizierung, bei der die Gesundheit und der Komfort der Nutzer im Mittelpunkt stehen und die sich auf die Leistung des Gebäudes und nicht so sehr auf die Vorgabe spezifischer Lösungen stützt.'
    ],
    wellStandardInformationText2: [
      'Trabaja desde 10 conceptos clave que aportan conocimiento y métricas suficientes bajo un trabajo de certificación holístico e integral - aire, agua, alimentación, iluminación, movimiento, confort térmico, sonido, materiales, mente y comunidad.',
      'It works from 10 key concepts that provide sufficient knowledge and metrics under a holistic and integrated certification work - air, water, food, lighting, movement, thermal comfort, sound, materials, mind and community.',
      'Es geht von 10 Schlüsselkonzepten aus, die im Rahmen einer ganzheitlichen und integrierten Zertifizierungsarbeit ausreichende Kenntnisse und Messgrößen liefern - Luft, Wasser, Lebensmittel, Beleuchtung, Bewegung, thermischer Komfort, Schall, Materialien, Geist und Gemeinschaft.'
    ],
    wellStandardInformationText3: [
      'De cada concepto, algunos requerimientos son obligatorios (precondition) y otros permiten sumar puntos voluntarios adicionales ("optimization"), con un mínimo de 40 puntos para alcanzar la certificación.',
      'For each concept, some requirements are mandatory (precondition) and others allow for additional voluntary points ("optimisation"), with a minimum of 40 points to achieve certification.',
      'Für jedes Konzept sind einige Anforderungen obligatorisch (precondition) und andere erlauben zusätzliche freiwillige Punkte ("optimization"), wobei mindestens 40 Punkte erreicht werden müssen, um eine Zertifizierung zu erhalten.'
    ],
    wellStandardInformationText4: [
      'Debido al enorme impacto de la calidad del aire en nuestra salud, el aire figura en primer lugar entre los conceptos clave que WELL pretende mejorar. WELL promueve la adecuada calidad del aire interior fomentado la verificación de niveles adecuados, la monitorización y diversos métodos de mejora: la eliminación y reducción de las fuentes de contaminantes, la intervención en los comportamientos que empeoran la calidad del aire y un énfasis especial en la implantación de sistemas de control de la calidad del aire interior.',
      'Because of the enormous impact of air quality on our health, air is at the top of the list of key concepts that WELL aims to improve. WELL promotes adequate indoor air quality by encouraging the verification of adequate levels, monitoring and various methods of improvement: the elimination and reduction of pollutant sources, intervention in behaviours that worsen air quality and a special emphasis on the implementation of indoor air quality control systems.',
      'Aufgrund der enormen Auswirkungen der Luftqualität auf unsere Gesundheit steht die Luft ganz oben auf der Liste der Schlüsselkonzepte, die mit WELL verbessert werden sollen. WELL fördert eine angemessene Luftqualität in Innenräumen, indem es die Überprüfung angemessener Werte, die Überwachung und verschiedene Methoden zur Verbesserung fördert: die Beseitigung und Verringerung von Schadstoffquellen, das Eingreifen in Verhaltensweisen, die die Luftqualität verschlechtern, und einen besonderen Schwerpunkt auf die Implementierung von Systemen zur Kontrolle der Luftqualität in Innenräumen.'
    ],
    wellStandardImageHeader1: [
      'Las soluciones de inBiot están diseñadas para ayudar a garantizar los requisitos obligatorios A01, A03 y T01, así como a ganar puntos adicionales a través de A05, A06, A08, T06 y T07 para alcanzar una mejor puntuación global en la certificación WELL.',
      "inBiot's solutions are designed to help ensure mandatory requirements A01, A03 and T01, as well as gain additional points through A05, A06, A08, T06 and T07 to achieve a better overall score in WELL certification.",
      'Die Lösungen von inBiot sind so konzipiert, dass sie dazu beitragen, die obligatorischen Anforderungen A01, A03 und T01 zu erfüllen sowie zusätzliche Punkte durch A05, A06, A08, T06 und T07 zu erzielen, um eine bessere Gesamtpunktzahl bei der WELL-Zertifizierung zu erreichen.'
    ],
    wellStandardImageHeader2: [
      'La precisión, rango y tecnología de los sensores de MICA también satisface los requisitos de WELL – recogidos en la siguiente tabla y basados en el Performance Verification Guidebook:',
      'The accuracy, range and technology of MICA sensors also meet WELL requirements - listed in the table below and based on the Performance Verification Guidebook:',
      'Die Genauigkeit, die Reichweite und die Technologie der MICA-Sensoren erfüllen auch die WELL-Anforderungen, die in der nachstehenden Tabelle aufgeführt sind und auf dem Leitfaden für die Leistungsüberprüfung basieren:'
    ],
    wellPerformanceRatingInformationText1: [
      'Es una certificación basada en la certificación WELL que se caracteriza por la utilización de los datos del comportamiento del edificio y de la experiencia de los usuarios para ayudar a mejorar el ambiente interior de los edificios desde el enfoque de su salud.​​',
      'It is a certification based on the WELL certification that is characterized by the use of building performance data and user experience to help improve the indoor environment of buildings from a health perspective.',
      'Es handelt sich um eine Zertifizierung auf der Grundlage der WELL-Zertifizierung, die sich durch die Verwendung von Gebäudeleistungsdaten und Nutzererfahrungen auszeichnet, um das Innenraumklima von Gebäuden unter gesundheitlichen Gesichtspunkten zu verbessern.'
    ],
    wellPerformanceRatingInformationText2: [
      'Es posible alcanzar esta certificación como proceso independiente o como un hito en el camino hacia la Certificación WELL v2.​',
      'It is possible to achieve this certification as a stand-alone process or as a milestone on the way to WELL v2 Certification.',
      'Es ist möglich, diese Zertifizierung als eigenständigen Prozess oder als Meilenstein auf dem Weg zur WELL v2-Zertifizierung zu erreichen.'
    ],
    wellPerformanceRatingInformationText3: [
      'WELL PERFORMANCE RATING ofrece una solución eficaz para controlar o alcanzar umbrales de rendimiento específicos relacionados con la calidad ambiental interior (IEQ) en los conceptos de calidad del aire interior, gestión de la calidad del agua, mediciones de iluminación, condiciones térmicas, rendimiento acústico, control ambiental y experiencia de los ocupantes.​La certificación incluye 33 features agrupadas en estas 7 categorías, siendo la de Calidad del aire la que más contiene, con un total de 9 features. Todos los proyectos deben cumplir con un mínimo de 21 features para conseguir la calificación WELL Performance Rating​.',
      'WELL PERFORMANCE RATING offers an effective solution for monitoring or achieving specific performance thresholds related to indoor environmental quality (IEQ) in the concepts of indoor air quality, water quality management, lighting measurements, thermal conditions, acoustic performance, environmental control and occupant experience. The certification includes 33 features grouped into these 7 categories, with Air Quality containing the most, with a total of 9 features. All projects must comply with a minimum of 21 features to achieve the WELL Performance Rating.',
      'Das WELL PERFORMANCE RATING bietet eine wirksame Lösung zur Kontrolle oder zum Erreichen bestimmter Leistungsschwellen in Bezug auf die Umweltqualität in Innenräumen (IEQ) in den Bereichen Luftqualität in Innenräumen, Wasserqualität, Beleuchtungsmessungen, thermische Bedingungen, akustische Leistung, Umweltkontrolle und Erfahrung der Bewohner. Die Zertifizierung umfasst 33 Merkmale, die in diese 7 Kategorien eingeteilt sind, wobei die Luftqualität mit insgesamt 9 Merkmalen die meisten Merkmale enthält. Alle Projekte müssen mindestens 21 Merkmale erfüllen, um das WELL Performance Rating zu erhalten.'
    ],
    wellPerformanceRatingImageHeader1: [
      'Los dispositivos MICA pueden ayudar a cumplir hasta 12 de los 21 puntos requeridos para la certificación de Calificación de Rendimiento WELL, incluyendo 8 puntos en la categoría de "Calidad del Aire Interior", 3 puntos adicionales en la categoría de "Monitoreo Ambiental" y 1 punto en "Condiciones Térmicas".',
      'MICA devices can help meet up to 12 of the 21 points required for WELL Performance Rating certification, including 8 points in the "Indoor Air Quality" category, 3 additional points in the "Environmental Monitoring" category and 1 point in "Thermal Conditions".',
      'MICA-Geräte können dazu beitragen, bis zu 12 der 21 Punkte zu erreichen, die für eine WELL Performance Rating-Zertifizierung erforderlich sind, darunter 8 Punkte in der Kategorie "Raumluftqualität", 3 zusätzliche Punkte in der Kategorie "Umweltüberwachung" und 1 Punkt in der Kategorie "Thermische Bedingungen".'
    ],
    leedImageHeader1: [
      'Dentro de la categoría de Calidad de Ambiente Interior (EQ) de LEED, la monitorización de la calidad del aire puede ayudar a obtener puntos en distintos apartados​.',
      "Within LEED's Indoor Environmental Quality (EQ) category, air quality monitoring can help score points under several different headings.",
      'Im Rahmen der LEED-Kategorie Innenraumqualität (EQ) kann die Überwachung der Luftqualität dazu beitragen, in verschiedenen Bereichen Punkte zu erzielen.'
    ],
    resetInformationText1: [
      'RESET es un estándar de certificación de edificios basado en la monitorización continua y en el rendimiento de los edificios. Es uno de los estándares de construcción más completos, con 5 certificaciones parciales modulares y permitiendo su justificación parcial. Estas certificaciones parciales se clasifican en criterios de “embodied standard” (Embodied Carbon, embodied circularity, embodied health), que incorporan datos asociados a los procesos de construcción a lo largo del ciclo de vida de un entorno construido, y los “operational data” (RESET Air, RESET Water, RESET Energy & RESET Waste), que se basan en los datos asociados a la monitorización continua del entorno construido.​',
      'RESET is a building certification standard based on continuous monitoring and performance of buildings. It is one of the most comprehensive building standards, with 5 modular partial certifications and allowing for partial justification. These partial certifications are classified into "embodied standard" criteria (Embodied Carbon, embodied circularity, embodied health), which incorporate data associated with construction processes throughout the life cycle of a built environment, and "operational data" (RESET Air, RESET Water, RESET Energy & RESET Waste), which are based on data associated with continuous monitoring of the built environment.',
      'RESET ist ein Gebäudezertifizierungsstandard, der auf der kontinuierlichen Überwachung und Leistung von Gebäuden basiert. Es handelt sich um einen der umfassendsten Gebäudestandards, der 5 modulare Teilzertifizierungen umfasst und eine teilweise Rechtfertigung zulässt. Diese Teilzertifizierungen sind in Kriterien des "verkörperten Standards" (verkörperter Kohlenstoff, verkörperte Kreislaufwirtschaft, verkörperte Gesundheit) unterteilt, die Daten im Zusammenhang mit Bauprozessen während des gesamten Lebenszyklus einer gebauten Umgebung umfassen, und in "Betriebsdaten" (RESET Luft, RESET Wasser, RESET Energie & RESET Abfall), die auf Daten im Zusammenhang mit der kontinuierlichen Überwachung der gebauten Umgebung basieren.'
    ],
    resetInformationText2: [
      'RESET Air es la certificación específica para evaluar el impacto de la calidad del aire interior en la salud, productividad y sostenibilidad en espacios interiores.',
      'RESET Air is the specific certification for assessing the impact of indoor air quality on health, productivity and sustainability in indoor spaces. ',
      'RESET Air ist die spezifische Zertifizierung für die Bewertung der Auswirkungen der Luftqualität in Innenräumen auf Gesundheit, Produktivität und Nachhaltigkeit in Innenräumen.'
    ],
    resetInformationText3: [
      'Para cumplir los requisitos de RESET, los proyectos necesitan un monitor acreditado que monitorice en continuo CO₂, PM2.5 y TVOC, además de temperatura y humedad relativa, y transmita eficazmente esa información a la nube de RESET para su análisis. Los monitores también deben mostrar fácilmente la información, se tienen que poder instalar en pared y contar con una fuente de alimentación eléctrica continua.',
      'To meet RESET requirements, projects need an accredited monitor that continuously monitors CO₂, PM2.5 and TVOC, as well as temperature and relative humidity, and efficiently transmits that information to the RESET cloud for analysis. The monitors must also display information easily, be wall-mountable and have a continuous power supply.',
      'Um die RESET-Anforderungen zu erfüllen, benötigen die Projekte ein zugelassenes Überwachungsgerät, das CO₂, PM2,5 und TVOC sowie Temperatur und relative Luftfeuchtigkeit kontinuierlich überwacht und diese Informationen zur Analyse effizient an die RESET-Cloud überträgt. Die Monitore müssen außerdem die Informationen einfach anzeigen, an der Wand montiert werden können und über eine kontinuierliche Stromversorgung verfügen.'
    ],
    LeedInformationText1: [
      'LEED (Leadership in Energy and Environmental Design) se destaca como el sistema de certificación de edificios sostenibles más ampliamente reconocido a nivel mundial. Los edificios certificados por LEED se consideran edificios saludables y energéticamente eficientes, por lo que reducen su impacto ambiental y dan lugar a beneficios a nivel social. Como la gran mayoría de certificaciones en este ámbito, ofrece un enfoque holístico que considera la sostenibilidad del edificio como el resultado de muchos elementos clave, entre los que se encuentra el Indoor Enviromental Quality (IEQ).',
      "LEED (Leadership in Energy and Environmental Design) is the world's most widely recognised sustainable building certification system. LEED-certified buildings are considered to be healthy and energy-efficient, thereby reducing their environmental impact and providing social benefits. Like the vast majority of certifications in this field, it offers a holistic approach that considers building sustainability as the result of many key elements, including Indoor Environmental Quality (IEQ).",
      'LEED (Leadership in Energy and Environmental Design) ist das weltweit anerkannteste Zertifizierungssystem für nachhaltige Gebäude. LEED-zertifizierte Gebäude werden als gesunde und energieeffiziente Gebäude angesehen, die die Umweltbelastung reduzieren und soziale Vorteile bieten. Wie die meisten Zertifizierungen in diesem Bereich bietet es einen ganzheitlichen Ansatz, der die Nachhaltigkeit von Gebäuden als Ergebnis vieler Schlüsselelemente betrachtet, einschließlich der Umweltqualität in Innenräumen (IEQ).'
    ],
    LeedInformationText2: [
      'La versión actual de LEED es la v4.1, que se distingue de la anterior por sus mejoras en los estándares de eficiencia energética, conservación del agua, selección del lugar, selección de materiales, iluminación y reducción de residuos.',
      'The current version of LEED is v4.1, which differs from the previous version with improvements in energy efficiency standards, water savings, site selection, materials selection, lighting and waste reduction.',
      'Die aktuelle LEED Version 4.1 unterscheidet sich von der Vorgängerversion durch Verbesserungen in den Bereichen Energieeffizienzstandards, Wassereinsparung, Standortwahl, Materialauswahl, Beleuchtung und Abfallreduzierung.'
    ],
    LeedInformationText3: [
      'Los proyectos de certificación LEED se distinguen en función del tipo de edificio y la fase de la edificación, incluyendo nuevas construcciones y Core & Shell (Building Design and Construction; BD+C), nuevas construcciones y diseño de interiores (Interior Design and Construction; ID+C) y proyectos en edificios ya construidos (Building Operations and Maintenance; O+M). Esta variedad de posibilidades se refleja en sistemas de certificación específicos para cada tipo de proyecto, tal y como se expone más adelante. En todos los procesos, la certificación se basa en un sistema de puntos. El mínimo de puntos necesarios para la obtención del certificado son 40, aunque se pueden obtener distintos niveles mejorados de la certificación: Silver (>50), Gold (>60) y Platinum (>80).',
      'LEED certification projects are differentiated by building type and phase, including new construction and core & shell (Building Design and Construction; BD+C), new construction and interior design (Interior Design and Construction; ID+C), and existing building projects (Building Operations and Maintenance; O+M). This diversity is reflected in specific certification schemes for each type of project, as described below. In all cases, certification is based on a points system. The minimum number of points required for certification is 40, although different levels of enhanced certification are available: Silver (>50), Gold (>60) and Platinum (>80).',
      'LEED-Zertifizierungsprojekte werden nach Gebäudetyp und Bauphase unterschieden, einschließlich Neubauten und Rohbau (Building Design and Construction, BD+C), Neubauten und Innenausbau (Interior Design and Construction, ID+C) und Projekte in bestehenden Gebäuden (Building Operations and Maintenance, O+M). Diese Vielfalt spiegelt sich in spezifischen Zertifizierungssystemen für jede Projektart wider, die im Folgenden beschrieben werden. Bei allen Verfahren basiert die Zertifizierung auf einem Punktesystem. Die Mindestpunktzahl für eine Zertifizierung liegt bei 40 Punkten, wobei es verschiedene Stufen der Zertifizierung gibt: Silber (>50), Gold (>60) und Platin (>80).'
    ],
    resetImageHeader1: [
      'RESET™ Air es una norma de construcción basada en el rendimiento de los edificios. Para que un proyecto obtenga la certificación RESET™ Air para interiores comerciales, los parámetros de calidad del aire interior, controlados mediante monitorización continua y calculados en una media diaria en función de las horas de ocupación, deben mantenerse dentro de los límites que se indican a continuación.',
      'RESET™ Air is a performance-based building standard. For a project to achieve RESET™ Air certification for commercial interiors, indoor air quality parameters, controlled by continuous monitoring and calculated on a daily average based on occupancy hours, must be maintained within the limits listed below.',
      'RESET™ Air ist ein leistungsbezogener Gebäudestandard. Damit ein Projekt die RESET™ Air-Zertifizierung für gewerbliche Innenräume erhält, müssen die Innenraumluftqualitätsparameter, die durch kontinuierliche Überwachung kontrolliert und im Tagesdurchschnitt auf der Grundlage der Belegungsstunden berechnet werden, innerhalb der unten angegebenen Grenzwerte liegen.'
    ],
    resetImageParagraph1: [
      'Los objetivos “Standard Performance” son obligatorios y todos los proyectos deben cumplir los límites indicados, mientras que los objetivos “High Performance” se indican como referencia para proyectos que persiguen objetivos de calidad del aire interior más rigurosos y/o para proyectos situados en regiones donde los niveles de calidad del aire exterior suelen mantenerse dentro de los límites recomendados para la salud.',
      'The "Standard Performance" targets are mandatory and all projects must meet the indicated limits, while the "High Performance" targets are indicated as a reference for projects pursuing more stringent indoor air quality objectives and/or for projects located in regions where outdoor air quality levels are generally maintained within recommended health limits.',
      'Die "Standard Performance"-Ziele sind obligatorisch und alle Projekte müssen die angegebenen Grenzwerte einhalten, während die "High Performance"-Ziele als Referenz für Projekte angegeben sind, die strengere Innenraumluftqualitätsziele verfolgen und/oder für Projekte in Regionen, in denen die Außenluftqualität im Allgemeinen innerhalb der empfohlenen Gesundheitsgrenzwerte gehalten wird.'
    ],
    resetCoreShellTitle: [
      'Parámetros y requisitos para proyectos RESET “Core & Shell”​',
      'Parameters and requirements for RESET "Core & Shell" projects',
      'Parameter und Anforderungen für RESET "Core & Shell" Projekte'
    ],
    resetCoreShellParagraaph: [
      'RESET™ Air for Core & Shell es una norma de construcción basada en el rendimiento de los edificios. Para que un proyecto obtenga esta certificación, los parámetros de calidad del aire interior, controlados mediante monitorización continua, deben mantenerse dentro de los límites que se indican a continuación. Las medias diarias se calculan a partir de las horas de ocupación y se comparan con los límites internacionales de calidad del aire interior.​',
      'RESET™ Air for Core & Shell is a performance-based building standard. For a project to achieve this certification, indoor air quality parameters, controlled by continuous monitoring, must be maintained within the limits listed below. Daily averages are calculated from occupancy hours and compared to international indoor air quality limits.',
      'RESET™ Air for Core & Shell ist ein leistungsbezogener Gebäudestandard. Damit ein Projekt diese Zertifizierung erhält, müssen die Innenraumluftqualitätsparameter, die durch kontinuierliche Überwachung kontrolliert werden, innerhalb der unten aufgeführten Grenzwerte gehalten werden. Die täglichen Durchschnittswerte werden anhand der Belegungszeiten berechnet und mit den internationalen Grenzwerten für die Innenraumluftqualität verglichen.'
    ],
    resetCoreShellParagraph1: [
      '* Cuando PM2.5 en el exterior es ≤ 48 µg/m³, los niveles en el interior no pueden ser mayores que 12 µg/m³.',
      '* When outdoor PM2.5 is ≤ 48 µg/m³, indoor levels may not exceed 12 µg/m³.',
      '* Wenn die PM2,5-Werte im Freien ≤ 48 µg/m³ sind, dürfen die Werte in Innenräumen nicht höher als 12 µg/m³ sein.'
    ],
    resetCoreShellParagraph2: [
      '* Cuando PM2.5 en el exterior es > 48 µg/m³, los sistemas de filtración de aire deben remover al menos 75% del PM2.5 como mínimo.',
      '* When outdoor PM2.5 is > 48 µg/m³, air filtration systems must remove at least 75% of PM2.5 as a minimum.',
      '* Wenn die PM2,5-Werte im Freien > 48 µg/m³ sind, müssen Luftfiltersysteme mindestens 75 % der PM2,5-Werte entfernen.'
    ],
    resetSensorsTitle: [
      'Requisitos de los sensores',
      'Sensor requirements ',
      'Anforderungen an die Sensoren'
    ],
    resetSensorsParagraph: [
      'Los monitores acreditados RESET™ Air se definen y clasifican por grado y tipo. El grado del monitor define el rendimiento, la precisión y la capacidad de notificación de datos de un dispositivo de monitorización. El tipo de monitor define el propósito y marco de aplicación.La precisión y resolución de los sensores de MICA satisface los requisitos RESET Grado B - proporcionan datos sobre la calidad del aire interior combinando rendimiento, fiabilidad y coste.',
      'RESET™ Air accredited monitors are defined and classified by grade and type. The monitor grade defines the performance, accuracy and data reporting capability of a monitoring device. Monitor type defines the purpose and application framework.The accuracy and resolution of MICA sensors meet RESET Grade B requirements - they provide indoor air quality data combining performance, reliability and cost.',
      'RESET™ Air akkreditierte Überwachungsgeräte werden nach Klasse und Typ definiert und klassifiziert. Die Messgeräteklasse definiert die Leistung, die Genauigkeit und die Fähigkeit zur Datenmeldung eines Messgeräts. Der Monitortyp definiert den Zweck und den Anwendungsrahmen. Die Genauigkeit und Auflösung der MICA-Sensoren erfüllen die Anforderungen der RESET-Klasse B - sie liefern Daten zur Luftqualität in Innenräumen und kombinieren Leistung, Zuverlässigkeit und Kosten.'
    ],

    resetImageHeader2: ['TABLAS', 'TABLES', 'TABELLEN'],
    resetImageHeader3: [
      'La precisión y la resolución de los sensores también satisfacen los requisitos RESET Grado B:',
      'The accuracy and resolution of the sensors also meet RESET Grade B requirements:',
      'Die Genauigkeit und Auflösung der Sensoren entsprechen ebenfalls den Anforderungen von RESET Grade B:'
    ],
    dataReportTitle: ['Informe de datos', 'Data report', 'Datenbericht'],
    dataReportInformation: [
      'Descarga aquí el informe de datos de calidad del aire interior requerido por la certificación WELL para enviar al IWBI para su análisis anual, para la recertificación de su edificio. Exporta aquí los datos directamente en el formato requerido por WELL y completa el informe de datos (pestaña “Monitor1_Data_File”).​​',
      'Download here the indoor air quality data report required by WELL certification to send to the IWBI for annual analysis, for the recertification of your building. Export here the data directly in the format required by WELL and complete the data report (“Monitor1_Data_File” tab).',
      'Laden Sie hier den für die WELL-Zertifizierung erforderlichen Datenbericht zur Innenraumluftqualität herunter, den Sie zur jährlichen Analyse an das IWBI für die Rezertifizierung Ihres Gebäudes senden können. Exportieren Sie hier die Daten direkt in dem von WELL geforderten Format und füllen Sie den Datenbericht aus (Registerkarte „Monitor1_Data_File“).'
    ],
    calibrationCertificateTitle: [
      'Certificado de Calibración',
      'Certificate of Calibration',
      'Kalibrierungszertifikat'
    ],
    calibrationCertificateInformation: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    ],
    vocsCompliance: [
      'Cumplimiento sensor TVOC',
      'TVOC sensor compliance',
      'Übereinstimmung mit TVOC-Sensoren'
    ],
    visit: ['VISITAR', 'VISIT', 'BESUCHEN'],
    vocsComplianceInformation: [
      'Visita aquí la explicación del cumplimiento de los requisitos de precisión de WELL para el sensor de TVOC de MICA.',
      "Visit here for an explanation of how the MICA TVOC sensor meets WELL's accuracy requirements.",
      'Hier finden Sie eine Erklärung, wie der MICA TVOC-Sensor die Genauigkeitsanforderungen von WELL erfüllt.'
    ],
    staticDataTitle: [
      'Justificación de Sensores',
      'Sensor Justification',
      'Rechtfertigung von Sensoren'
    ],
    staticDataInformation: [
      'Descarga aquí la justificación de los sensores de MICA para el envío periódico al IWBI y completa el informe de datos (pestaña “WELL_ID_Monitor_Header_Master”​).',
      'Download here the MICA sensor justification for periodic submission to the IWBI and complete the data report ("WELL_ID_Monitor_Header_Master" tab).',
      'Laden Sie hier die Begründung der MICA-Sensoren für die periodische Übermittlung an das IWBI herunter und füllen Sie den Datenbericht aus (Reiter "WELL_ID_Monitor_Header_Master").'
    ],
    download: ['DESCARGAR', 'DOWNLOAD', 'HERUNTERLADEN']
  },

  downloadWellDataReportModal: {
    downloadReport: [
      'Descargar informe de datos',
      'Download data report',
      'Datenbericht herunterladen'
    ],
    navDevices: ['1. Dispositivo', '1. Device', '1. Gerät'],
    navDates: ['2. Fechas', '2. Dates', '2. Daten'],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date interval',
      'Datumsbereich auswählen'
    ]
  },

  downloadWellStaticDataModal: {
    downloadStatic: [
      'Descargar justificación de sensores',
      'Download sensors justification',
      'Download der Sensorbegründung'
    ],
    navDevices: ['Seleccionar dispositivo', 'Select  Device', 'Gerät auswählen']
  },

  // Popups de información
  infoModal: {
    moreInfo: ['Ampliar información', 'More information', 'Mehr Informationen']
  },
  // System page
  systemPage: {
    countryHelper: [
      'El país y código postal se utilizan para medir el tiempo exterior.',
      'The country and zip code are used to track the outside weather data.',
      'Das Land und die Postleitzahl werden verwendet, um die Außenwetterdaten zu verfolgen'
    ],
    typeBuilding: ['Tipo de edificio', 'Building type', 'Gebäudetyp'],
    antiquityBuilding: ['Antigüedad del edificio', 'Building age', 'Baualter'],
    ventilationBuilding: ['Tipo de ventilación', 'Type of ventilation', 'Art der Belüftung'],
    airTightnessLevelBuilding: [
      'Nivel de hermeticidad',
      'Building airtightness level',
      'Luftdichtheitsgrad des Gebäudes'
    ],
    occupancyBuilding: ['Ocupación', 'Occupancy classification', 'Belegungsklassifizierung'],
    typeOfUseBuilding: ['Tipo de uso', 'Type of use', 'Nutzungsart'],
    typeOfSystem: ['Tipo de dispositivo', 'Type of device', 'Gerätetyp']
  },
  supportModal: {
    title: ['¿Cómo podemos ayudarte?', 'How can we help you?', 'Wie können wir Ihnen helfen?'],
    subtitle: [
      'Rellena el formulario para ofrecerte la mejor solución.',
      'Fill in the form in order to offer you the best solution.',
      'Füllen Sie das Formular aus, damit wir Ihnen die beste Lösung anbieten können.'
    ],
    supportDescription: [
      'Descripción de la consulta',
      'Description of the occurrence',
      'Beschreibung der Konsultation'
    ],
    sendOccurrence: ['Enviar consulta', 'Send occurrence', 'Anfrage senden'],
    sensorIdInfo: [
      'Selecciona el ID si tu MICA está vinculado a tu cuenta o consíguelo en el proceso de configuración.',
      'Select the ID if your MICA is linked to your account or get it in the configuration process.',
      'Wählen Sie die ID aus, wenn Ihre MICA mit Ihrem Konto verknüpft ist, oder erhalten Sie sie während des Konfigurationsprozesses.'
    ]
  },
  selectedDevices: ['Dispositivos seleccionados: ', 'Selected devices: ', 'Ausgewählte Geräte: '],
  selectFifty: [
    'Seleccionar dispositivos (Max. 40)',
    'Select devices (Max. 40)',
    'Geräte auswählen (max. 40)'
  ],
  // Snackbar
  snackbar: {
    successTransfer: [
      'MICA transferida con éxito',
      'MICA successfully transferred',
      'MICA erfolgreich übertragen'
    ],
    whoops: [
      'Oops, algo ha ido mal. Por favor inténtelo otra vez.',
      'Whoops, something went wrong. Please try again or refresh the page.',
      'Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.'
    ],
    expiredToken: [
      'Tu token ha caducado. Inicie sesión otra vez.',
      'Your token has expired. Please login again.',
      'Ihr Token ist abgelaufen. Bitte melden Sie sich erneut an.'
    ],
    confirmationCodeSent: [
      'Se ha enviado un correo de confirmación a tu cuenta.',
      'A confirmation mail has been sent to your account',
      'Eine Bestätigungs-E-Mail wurde an Ihr Konto gesendet'
    ],
    confirmSignUpSuccess: [
      'Cuenta verificada con éxito. Inicie sesión.',
      "You have verified successfully your account. Now it's time to login.",
      'Sie haben Ihr Konto erfolgreich verifiziert. Jetzt ist es Zeit sich anzumelden.'
    ],
    confirmSignUpCollaboratorSuccess: [
      'Cuenta creada con éxito. Inicie sesión.',
      "You have successfully created your account. Now it's time to login.",
      'Sie haben Ihr Konto erfolgreich erstellt. Jetzt ist es Zeit, sich einzuloggen.'
    ],
    resendConfirmationMailSuccess: [
      'Se ha reenviado el correo de confirmación.',
      'Confirmation mail has been sent successfully.',
      'Bestätigungsmail wurde erfolgreich versendet.'
    ],
    resendConfirmationMailFailure: [
      'Ha habido un problema enviando el correo de confirmación.',
      'There was a problem when resending confirmation mail. ',
      'Beim erneuten Senden der Bestätigungsmail ist ein Problem aufgetreten.'
    ],
    addedSuccessful: ['Añadido con éxito', 'Successfully added', 'Erfolgreich hinzugefügt'],
    savedSuccessful: ['Guardado con éxito', 'Successfully saved', 'Erfolgreich gespeichert'],
    deleteSuccessful: ['Eliminado con éxito', 'Successfully deleted', 'Erfolgreich gelöscht'],
    createGroupSuccessful: ['Grupo creado con éxito.', 'Successfully created group.'],
    updateGroupSuccessful: ['Grupo actualizado con éxito.', 'Successfully updated group.'],
    deleteGroupSuccessful: ['Grupo borrado con éxito.', 'Successfully deleted group.'],
    deleteFromGroupSuccessful: ['Eliminado de grupo con éxito', 'Successfully deleted from group'],
    createScreenSuccessful: ['Pantalla creada con éxito', 'Successfully created screen'],
    updateScreenSuccessful: ['Pantalla editada con éxito', 'Successfully updated screen'],
    deleteScreenSuccessful: ['Pantalla borrada con éxito', 'Successfully deleted screen'],
    createAlarmSuccessful: [
      'Alarma creada con éxito',
      'Successfully created alarm',
      'Erfolgreich erstellter Alarm'
    ],
    updateAlarmSuccessful: [
      'Alarma actualizada con éxito',
      'Successfully updated alarm',
      'Erfolgreich aktualisierter Alarm'
    ],
    deleteAlarmSuccessful: [
      'Alarma eliminada con éxito',
      'Successfully deleted alarm',
      'Erfolgreich gelöschter Alarm'
    ],
    alarmActivatedSuccessful: [
      'Alarma reactivada con éxito',
      'Alarm successfully reactivated',
      'Alarm erfolgreich reaktiviert'
    ],
    alarmDesactivatedSuccessful: [
      'Alarma desactivada con éxito',
      'Alarm successfully deactivated',
      'Alarm erfolgreich abgeschaltet'
    ],
    forgottenPasswordSuccess: [
      'Se ha solicitado un cambio de contraseña. Por favor consulte su correo electronico.',
      'Reset password requested. Please check your email.',
      'Passwort zurücksetzen angefordert. Bitte überprüfen Sie Ihre E-Mail.'
    ],
    recoverPasswordSuccess: [
      'La contraseña se ha cambiado con éxito.',
      'Succesfully resetted password.',
      'Passwort erfolgreich zurückgesetzt.'
    ],
    logoUploadedSuccessful: [
      'Logo subido con éxito',
      'Logo uploaded successfully',
      'Logo erfolgreich hochgeladen'
    ],
    logoDeletedSuccessful: [
      'Logo eliminado con éxito',
      'Logo deleted successfully',
      'Logo erfolgreich gelöscht'
    ],
    sharableLinkCopiedToClipboard: [
      'El link se ha copiado en el portapapeles.',
      'Shareable link is copied to clipboard.',
      'Der teilbare Link wird in die Zwischenablage kopiert'
    ],
    zipCodeNotAvailable: [
      'Lo sentimos... No disponemos de datos de tiempo exterior para el código postal introducido',
      'Sorry... We do not have weather data for the zip code inserted',
      'Entschuldigung... Wir haben keine Wetterdaten für die eingegebene Postleitzahl'
    ],
    ScreenNameAlreadyExists: [
      'Ya existe un compartible con este nombre para este dispositivo. Inténtelo con otro nombre.',
      'There is already a shareable with this name for this device. Please try it with another name.',
      'There is already a shareable with this name for this device. Please try it with another name.'
    ],
    occurrenceSentSuccessful: [
      'Incidencia enviada con éxito. El equipo de soporte se pondrá en contacto contigo lo antes posible.',
      'Occurrence submitted successfully. The support team will contact you as soon as possible.',
      'Vorfall erfolgreich übermittelt. Das Support-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.'
    ]
  },

  // Submit errors
  submitErrors: {
    userExist: [
      'Este usuario ya tiene una cuenta.',
      'This user has already an account.',
      'Dieser Benutzer hat bereits ein Konto.'
    ],
    collaborativeUserNotValid: [
      'Cuenta no válida. No se puede dar acceso al usuario.',
      'Invalid account. The user cannot be granted access.',
      'Ungültiges Konto. Dem Benutzer kann kein Zugang gewährt werden.'
    ],
    NoSensors: [
      'Algún sensor no ha registrado datos en el intervalo seleccionado. Inténtelo de nuevo modificando el rango de fechas o contacte con soporte.',
      'Some sensor has not recorded data in the selected range. Try again by modifying the date range or contact support.',
      'Een sensor heeft geen gegevens geregistreerd in het geselecteerde bereik. Probeer het opnieuw door het datumbereik te wijzigen of neem contact op met ondersteuning.'
    ],
    Nodata: [
      'No existen datos en el intervalo de tiempo seleccionado Modifique las fechas seleccionadas e inténtelo de nuevo.',
      'No data in the selected time interval Modify the selected dates and try again.',
      'Er zijn geen gegevens in het geselecteerde tijdvak Wijzig de geselecteerde data en probeer het opnieuw.'
    ],
    unknown: [
      'Error desconocido. Por favor inténtelo más tarde.',
      'Unknown error. Please try again later.',
      'Unbekannter Fehler. Bitte versuchen Sie es später erneut.'
    ],
    invalidLogin: [
      'Correo electrónico o contraseña erróneos.',
      'Wrong email or password.',
      'Falsche Email oder Passwort.'
    ],
    checkEmail: [
      'Por favor, comprueba el correo asociado a tu cuenta (incluso carpeta Spam), ya que ha habido un cambio en la plataforma.',
      'Please check the email associated with your account (including the Spam folder), as there has been a change in the platform.',
      'Bitte überprüfen Sie die mit Ihrem Konto verknüpfte E-Mail (einschließlich des Spam-Ordners), da es eine Änderung der Plattform gegeben hat.'
    ],
    userNotConfirmed: [
      'El usuario no ha sido confirmado.',
      'User has not been confirmed yet.',
      'Benutzer wurde noch nicht bestätigt.'
    ],
    emailInUse: [
      'La dirección de correo electrónico que ha introducido esta en uso',
      'Email address already in use.',
      'E-Mail-Adresse wird schon verwendet.'
    ],
    invalidPassword: ['Contraseña incorrecta', 'Incorrect password', 'Falsches Passwort'],
    systemNotFound: [
      'No hemos encontrado un sensor que se corresponda al sensor ID introducido. Asegúrese de que el sensor ID es correcto.',
      'We were unable to find a sensor with the submitted sensor id. Please make sure the sensor id is correct.',
      'Wir konnten keinen Sensor mit der übermittelten Sensor-ID finden. Bitte stellen Sie sicher, dass die Sensor-ID korrekt ist.'
    ],
    systemAlreadyInUse: [
      'El sensor que está intentando añadir pertenece a otro usuario.',
      "The sensor you're trying to add is already used by another user.",
      'Der Sensor, den Sie hinzufügen möchten, wird bereits von einem anderen Benutzer verwendet.'
    ],
    limitSystemsUser: [
      'Se ha alcanzado el límite de dispositivos contratados en su cuenta Business. Si desea aumentarlo, por favor contacte con info@inbiot.es',
      'The limit of contracted devices in your Business account has been reached. If you wish to increase it, please contact info@inbiot.es.',
      'Die Höchstzahl der vertraglich gebundenen Geräte in Ihrem Geschäftskonto wurde erreicht. Wenn Sie den Betrag erhöhen möchten, wenden Sie sich bitte an info@inbiot.es'
    ],
    systemAlreadyInOtherAlarm: [
      'Este dispositivo ya tiene una alarma configurada.',
      'This sensor has already a configured alert.',
      'Dieser Sensor hat bereits einen konfigurierten Alarm.'
    ],
    unknownEmail: [
      'Dirección de correo electrónico desconocida.',
      'Unknown email address.',
      'Unbekannte E-Mail-Adresse.'
    ],
    incorrectOldPassword: [
      'La contraseña antigua es incorrecta',
      'Old password is incorrect',
      'das alte Passwort ist falsch'
    ],
    passwordLimitExceeded: [
      'Has superado el límite de intentos. Inténtelo en un tiempo.',
      'You have exceeded the attemps limit. Try it again in some time.',
      'Sie haben das Versuchslimit überschritten. Versuchen Sie es nach einiger Zeit noch einmal.'
    ],
    channelNotFound: [
      'No hemos podido encontrar el sensor.',
      'We were not able to find this channel.',
      'Wir konnten diesen Kanal nicht finden.'
    ],
    channelForbidden: [
      'Este sensor no es público.',
      'This is not a public channel.',
      'Dies ist kein öffentlicher Kanal.'
    ],
    expiredVerificationCode: [
      'El código de verificación del correo ha expirado. Solicite un nuevo código.',
      'The specified e-mail verification code has expired. Request another code.'
    ],
    userAlreadyConfirmed: [
      'El usuario ya ha sido confirmado.',
      'This user has already been confirmed.',
      'Dieser Benutzer wurde bereits bestätigt.'
    ],
    recipientAlreadyConfirmed: [
      'El email ya ha sido confirmado.',
      'This email has already been confirmed.',
      'Diese E-Mail wurde bereits bestätigt.'
    ],
    recipientNotFound: [
      'El email ya ha sido confirmado.',
      'The email could not be successfully confirmed.',
      'Die E-Mail konnte nicht erfolgreich bestätigt werden.'
    ]
  },

  // Validation
  validation: {
    required: ['Campo requerido', 'Required', 'Erforderlich'],
    timeRange: ['Cambiar intervalo de tiempo', 'Change time range ', 'Tijdsinterval wijzigen'],
    anyField: [
      'Debe seleccionar al menos un día',
      'You should choose at least one day',
      'U moet ten minste één dag kiezen'
    ],
    anyFieldRequired: [
      'Debes seleccionar al menos un tipo de dato',
      'You should choose at least one data type',
      'Sie sollten mindestens einen Datentyp auswählen'
    ],
    tooManyParams: [
      'El número máximo de parámetros es 8',
      'The maximum number of parameters is 8',
      'Die maximale Anzahl von Parametern beträgt 8'
    ],
    anyEmailRequired: [
      'Debes seleccionar al menos un correo.',
      'You should choose at least one e-mail.',
      'Sie sollten mindestens eine E-Mail wählen.'
    ],
    rangeValuesRequired: [
      'Debes introducir valores para el rango de inactividad',
      'You must enter values for the inactivity range',
      'Sie müssen Werte für den Inaktivitätsbereich eingeben'
    ],
    rangeValuesInvalid: [
      'Debes introducir un valor válido.',
      'You must enter a valid value.',
      'Sie müssen einen gültigen Wert eingeben.'
    ],
    anyDeviceRequired: [
      'Debes seleccionar al menos un dispositivo',
      'You should choose at least one device',
      'Sie sollten mindestens ein Gerät auswählen'
    ],
    deviceRequired: [
      'Debes seleccionar un dispositivo',
      'You should choose one device',
      'Sie sollten sich für ein Gerät entscheiden'
    ],
    userRequired: [
      'Debes seleccionar un usuario',
      'You should choose one user',
      'Sie sollten einen Benutzer auswählen'
    ],
    contactEmailRequired: [
      'Debes facilitar un correo de contacto.',
      'You must provide a contact email address.',
      'Sie müssen eine Kontakt-E-Mail-Adresse angeben.'
    ],
    descriptionRequired: [
      'Por favor, describe tu consulta.',
      'Please describe your query.',
      'Bitte beschreiben Sie Ihre Anfrage.'
    ],
    timeIntervalAllowed: [
      'Tu plan actual solo permite un mínimo de 10 minutos.',
      'Your current plan only allows a minimum of 10 minutes.',
      'Ihr aktueller Plan erlaubt nur ein Minimum von 10 Minuten.'
    ],
    timeIntervalGsmInfo: [
      'Los dispositivos con conectividad GSM tienen un mayor procesamiento y su tiempo entre datos mínimo es de 10 minutos.',
      'Devices with GSM connectivity have higher processing and have a minimum periodicity interval of 10 minutes.',
      'Geräte mit GSM-Konnektivität haben eine höhere Verarbeitungsgeschwindigkeit und eine minimale Datenübertragungszeit von 10 Minuten.'
    ],
    timeIntervalMinimum: [
      'El tiempo entre datos mínimo es 1 minuto.',
      'The minimum periodicity interval is 1 minute.',
      'Das Mindestintervall für die Periodizität beträgt 1 Minute.'
    ],
    timeIntervalMaximum: [
      'El tiempo entre datos máximo son 60 minutos.',
      'Maximum time between data is 60 minutes.',
      'Der maximale Zeitraum zwischen den Daten beträgt 60 Minuten.'
    ],
    inValidEmail: ['Correo electrónico no válido', 'Invalid email', 'Ungültige E-Mail'],
    inValidPassword: [
      'La contraseña necesita al menos 6 caracteres',
      'Password must have at least 6 characters',
      'Passwort muss mindestens 6 Zeichen haben'
    ],
    invalidRepeatPassword: [
      'Las contraseñas deben coincidir.',
      'Passwords should match.',
      'Passwörter sollten übereinstimmen.'
    ],
    invalidFormat: ['Formato inválido.', 'Invalid format.', 'Ungültiges Format'],
    invalidDateRange: [
      'Rango de fechas inválido',
      'Invalid date range',
      'Ungültiger Datumsbereich'
    ],
    invalidDateInterval: [
      'El intervalo de descarga no puede superar el límite indicado anteriormente.',
      'The download interval may not exceed the limit shown above.',
      'Das Download-Intervall darf das oben angegebene Limit nicht überschreiten.'
    ]
  },
  sortOptions: {
    groupsAZ: ['Grupos de la A-Z', 'Groups from A-Z', 'Gruppen von A-Z'],
    groupsZA: ['Grupos de la Z-A', 'Groups from Z-A', 'Gruppen von Z-A'],
    devicesAZ: ['Dispositivos de la A-Z', 'Devices from A-Z', 'A-Z Geräte'],
    devicesZA: ['Dispositivos de la Z-A', 'Devices from Z-A', 'Z-A Geräte'],
    withMoreMicas: ['Grupos más grandes primero', 'Bigger groups first', 'Größere Gruppen zuerst'],
    withLessMicas: [
      'Grupos más pequeños primero',
      'Smaller groups first',
      'Kleinere Gruppen zuerst'
    ],
    lastAdded: [
      'Dispositivos más recientes primero',
      'Latest devices first',
      'Neueste Geräte zuerst'
    ],
    firstAdded: [
      'Dispositivos más antiguos primero',
      'Older devices first',
      'Ältere Geräte zuerst'
    ],
    worstMeasuresFirst: [
      'Dispositivos más críticos primero',
      'More critical devices first',
      'Die wichtigsten Geräte zuerst'
    ],
    bestMeasuresFirst: [
      'Dispositivos menos críticos primero',
      'Less critical devices first',
      'Weniger kritische Geräte zuerst'
    ]
  },
  sortMenuOptions: {
    groupsByName: ['Grupos por nombre', 'Groups by name', 'Gruppen nach Namen'],
    groupsBySize: ['Grupos por tamaño', 'Groups by size', 'Gruppen nach Größe'],
    devicesByName: ['Dispositivos por nombre', 'Devices by name', 'Geräte nach Namen'],
    devicesByAddDate: [
      'Dispositivos por antigüedad',
      'Devices by date added',
      'Geräte nach Hinzufügedatum'
    ],
    devicesByState: ['Dispositivos por estado', 'Devices by status', 'Geräte nach Status']
  },
  // Data types
  temperature: ['Temperatura', 'Temperature', 'Temperatur'],
  tempLow: [
    'Temperatura (límite inferior)',
    'Temperature (lower limit)',
    'Temperatur (unterer Grenzwert)'
  ],
  tempHigh: [
    'Temperatura (límite superior)',
    'Temperature (upper limit)',
    'Temperatur (oberer Grenzwert)'
  ],
  humidity: ['Humedad relativa', 'Relative humidity', 'Relative Luftfeuchtigkeit'],
  rHumidity: ['Humedad R.', 'R.Humidity', 'R.Luftfeuchtigkeit'],
  humLow: [
    'Humedad (límite inferior)',
    'Humidity (lower limit)',
    'Luftfeuchtigkeit (unterer Grenzwert)'
  ],
  humHigh: [
    'Humedad (límite superior)',
    'Humidity (upper limit)',
    'Luftfeuchtigkeit (oberer Grenzwert)'
  ],
  co2: ['CO₂', 'CO₂', 'CO₂'],
  formaldehyde: ['Formaldehído', 'Formaldehyde', 'Formaldehyd'],
  vocs: ['TVOC', 'TVOC', 'TVOC'],
  vocsIndex: ['TVOC', 'TVOC', 'TVOC'],
  pm25: ['PM2.5', 'PM2.5', 'PM2.5'],
  pm10: ['PM10', 'PM10', 'PM10'],
  pm4: ['PM4.0', 'PM4.0', 'PM4.0'],
  pm1: ['PM1.0', 'PM1.0', 'PM1.0'],
  radon: ['Radón', 'Radon', 'Radon'],
  weather: ['Tiempo exterior', 'Weather', 'Wetter'],
  outdoorTemperature: ['Temperatura exterior', 'Outdoor temperature', 'Außentemperatur'],
  outdoorHumidity: ['Humedad exterior', 'Outdoor humidity', 'Außenluftfeuchtigkeit'],
  baseStationInfo: [
    'Localización de la estación base',
    'Base station location',
    'Base station location'
  ],
  co: ['CO', 'CO', 'CO'],
  o3: ['O₃', 'O₃', 'O₃'],
  covid19: ['Indicador virus', 'Virus indicator', 'Virenindikator'],
  no: ['NO', 'NO', 'NO'],
  nox: ['NOₓ', 'NOₓ', 'NOₓ'],
  no2: ['NO₂', 'NO₂', 'NO₂'],
  iaq: ['IAQ', 'IAQ', 'IAQ'],
  iaqMonitoring: ['Monitorización IAQ', 'IAQ Monitoring', 'IAQ Monitoring'],
  radiantTemperature: ['Temperatura Radiante', 'Radiant Temperature', 'Strahlende Temperatur'],
  lux: ['Iluminancia', 'Illuminance', 'Beleuchtungsstärke'],
  dB: ['Nivel de ruido', 'Noise level', 'Geräuschpegel'],
  capacity: ['Aforo actual', 'Current capacity', 'Derzeitige Kapazität'],
  pressure: ['Presión', 'Pressure', 'Luftdruck'],
  csv: ['CSV', 'CSV', 'CSV'],
  xlsx: ['XLSX', 'XLSX', 'XLSX'],
  preheating: ['Preheating', 'Preheating', 'Preheating'],
  // Graph range units
  graphRangeUnits: {
    hours: ['Horas', 'Hours', 'Stunden'],
    days: ['Días', 'Days', 'Tage'],
    weeks: ['Semanas', 'Weeks', 'Wochen']
  },
  temperatureInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempLowInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempHighInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempHighInfoNFahrenheit: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 68 - 73.4ºF </li>' +
      '<li>Amarillo/Alerta: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Rojo/Alarma: < 64.4 y > 78.8ºF </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 68 - 73.4ºF </li>' +
      '<li>Yellow/Warning: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Red/Alarm: < 64.4 and > 78.8ºF </li>' +
      '</ul>'
  ],
  tempHighInfoNKelvin: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 293 - 296ºK </li>' +
      '<li>Amarillo/Alerta: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Rojo/Alarma: < 291 y > 299ºK </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 293 - 296ºK </li>' +
      '<li>Yellow/Warning: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Red/Alarm: < 291 amd >299ºK </li>' +
      '</ul>'
  ],
  tempLowInfoNFahrenheit: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 68 - 73.4ºF </li>' +
      '<li>Amarillo/Alerta: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Rojo/Alarma: < 64.4 y > 78.8ºF </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 68 - 73.4ºF </li>' +
      '<li>Yellow/Warning: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Red/Alarm: < 64.4 and > 78.8ºF </li>' +
      '</ul>'
  ],
  tempLowInfoNKelvin: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 293 - 296ºK </li>' +
      '<li>Amarillo/Alerta: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Rojo/Alarma: < 291 y > 299ºK </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 293 - 296ºK </li>' +
      '<li>Yellow/Warning: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Red/Alarm: < 291 amd >299ºK </li>' +
      '</ul>'
  ],
  humidityInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],
  humLowInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],
  humHighInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],

  co2InfoN: [
    '<h4> CO₂ </h4>' +
      '<br/>Los niveles recomendados de CO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 800 ppm</li>' +
      '<li>Amarillo/Alerta: 800 – 1500 ppm </li>' +
      '<li>Rojo/Alarma: > 1500 ppm </li>' +
      '</ul>',
    '<h4> CO₂ </h4>' +
      '<br/>Recommended CO₂ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 800 ppm</li>' +
      '<li>Yellow/Warning: 800 – 1500 ppm </li>' +
      '<li>Red/Alarm: > 1500 ppm </li>' +
      '</ul>'
  ],
  formaldehydeInfoN: [
    '<h4> Formaldehído </h4>' +
      '<br/>Los niveles recomendados de formaldehído para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 70 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 70 – 120 µg/m³.</li>' +
      '<li>Rojo/Alarma: > 120 µg/m³.</li>' +
      '</ul>',
    '<h4> Formaldehyde </h4>' +
      '<br/>Recommended formaldehyde levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 70 µg/m³ </li>' +
      '<li>Yellow/Warning: 70 – 120 µg/m³.</li>' +
      '<li>Red/Alarm: > 120 µg/m³.</li>' +
      '</ul>'
  ],
  formaldehydeInfoNFormaldehyde: [
    '<h4> Formaldehído </h4>' +
      '<br/>Los niveles recomendados de formaldehído para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 56 ppb </li>' +
      '<li>Amarillo/Alerta: 56 – 96 ppb.</li>' +
      '<li>Rojo/Alarma: > 96 ppb.</li>' +
      '</ul>',
    '<h4> Formaldehyde </h4>' +
      '<br/>Recommended formaldehyde levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 56 ppb </li>' +
      '<li>Yellow/Warning: 56 – 96 ppb.</li>' +
      '<li>Red/Alarm: > 96 ppb.</li>' +
      '</ul>'
  ],
  o3InfoN: [
    '<h4> O₃ </h4>' +
      '<br/>Los niveles recomendados de O₃ para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 51 ppb </li>' +
      '<li>Amarillo/Alerta: 51 – 96 ppb.</li>' +
      '<li>Rojo/Alarma: > 96 ppb.</li>' +
      '</ul>',
    '<h4> O₃ </h4>' +
      '<br/>Recommended O₃ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 51 ppb </li>' +
      '<li>Yellow/Warning: 51 – 122 ppb.</li>' +
      '<li>Red/Alarm: > 122 ppb.</li>' +
      '</ul>'
  ],
  o3InfoNO3: [
    '<h4> O₃ </h4>' +
      '<br/>Los niveles recomendados de O₃ para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 100 ug/m3 </li>' +
      '<li>Amarillo/Alerta: 100 – 240 ug/m3.</li>' +
      '<li>Rojo/Alarma: > 240 ug/m3.</li>' +
      '</ul>',
    '<h4> O₃ </h4>' +
      '<br/>Recommended O₃ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 100 ug/m3 </li>' +
      '<li>Yellow/Warning: 100 – 240 ug/m3.</li>' +
      '<li>Red/Alarm: > 240 ug/m3.</li>' +
      '</ul>'
  ],

  coInfoN: [
    '<h4> CO </h4>' +
      '<br/>Los niveles recomendados de CO para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 8.6 ppm </li>' +
      '<li>Amarillo/Alerta: 8.6 – 25 ppm.</li>' +
      '<li>Rojo/Alarma: > 25 ppm.</li>' +
      '</ul>',
    '<h4> CO </h4>' +
      '<br/>Recommended CO levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 8.6 ppm </li>' +
      '<li>Yellow/Warning: 8.6 – 25 ppm.</li>' +
      '<li>Red/Alarm: > 25 ppm.</li>' +
      '</ul>'
  ],
  coInfoNCO: [
    '<h4> CO </h4>' +
      '<br/>Los niveles recomendados de CO para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 10 ug/m3 </li>' +
      '<li>Amarillo/Alerta: 10 – 29 ug/m3.</li>' +
      '<li>Rojo/Alarma: > 29 ug/m3.</li>' +
      '</ul>',
    '<h4> CO </h4>' +
      '<br/>Recommended CO levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 10 ug/m3 </li>' +
      '<li>Yellow/Warning: 10 – 29 ug/m3.</li>' +
      '<li>Red/Alarm: > 29 ug/m3.</li>' +
      '</ul>'
  ],
  tvocIndexInformationTitle: [
    '¿Por qué recomendamos utilizar el Índice de TVOC?',
    'Why do we recommend using the TVOC Index?',
    'Warum empfehlen wir die Verwendung des TVOC-Index?'
  ],
  tvocIndexInformationText: [
    'El Índice de TVOC es la herramienta óptima para monitorizar la evolución de los TVOC en el aire interior. Se recomienda esta opción por defecto en la visualización de datos en My inBiot.',
    'The TVOC Index is the optimal tool to monitor the evolution of TVOCs in indoor air. This option is recommended as default in the data display in My inBiot.',
    'Der TVOC-Index ist das optimale Instrument zur Überwachung der Entwicklung von TVOCs in der Innenraumluft. Diese Option wird als Standard für die Datenanzeige in My inBiot empfohlen.'
  ],
  vocsInfoN: [
    '<h4> TVOCs </h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 220 ppb </li>' +
      '<li>Amarillo/Alerta: 220 - 660 ppb </li>' +
      '<li>Rojo/Alarma: > 660 ppb </li>' +
      '</ul>',
    '<h4> TVOCs </h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 220 ppb </li>' +
      '<li>Yellow/Warning: 220 - 660 ppb </li>' +
      '<li>Red/Alarm: > 660 ppb </li>' +
      '</ul>'
  ],
  vocsInfoNVocsWell: [
    '<h4> TVOCs (WELL)</h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 990 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 990 - 2970 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 2970 µg/m³ </li>' +
      '</ul>',
    '<h4> TVOCs (WELL)</h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 990 µg/m³ </li>' +
      '<li>Yellow/Warning: 990 - 2970 µg/m³ </li>' +
      '<li>Red/Alarm: > 2970 µg/m³ </li>' +
      '</ul>'
  ],
  vocsInfoNVocsReset: [
    '<h4> TVOCs (RESET)</h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 506 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 506 - 1518 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 1518 µg/m³ </li>' +
      '</ul>',
    '<h4> TVOCs (RESET)</h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 506 µg/m³ </li>' +
      '<li>Yellow/Warning: 506 - 1518 µg/m³ </li>' +
      '<li>Red/Alarm: > 1518 µg/m³ </li>' +
      '</ul>'
  ],
  vocsIndexInfoN: [
    '<h4>TVOC </h4>' +
      '<br/>Los niveles recomendados de TVOC para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 200 </li>' +
      '<li>Amarillo/Alerta: 200 - 400 </li>' +
      '<li>Rojo/Alarma: > 400 </li>' +
      '</ul>',
    '<h4> TVOCs  </h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 200 </li>' +
      '<li>Yellow/Warning: 200 - 400 </li>' +
      '<li>Red/Alarm: > 400 </li>' +
      '</ul>'
  ],
  pm1InfoN: [
    '<h4> PM1.0 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM1 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm4InfoN: [
    '<h4> PM4.0 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM4 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm25InfoN: [
    '<h4> PM2.5 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM2.5 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  automatic: ['Automático', 'Automatic', 'Automatisch'],
  manual: ['Manual', 'Manual', 'Handbuch'],
  pm10InfoN: [
    '<h4> PM10 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 50 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 50 - 100 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 100 µg/m³ </li>' +
      '</ul>',
    '<h4> PM10 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 50 µg/m³ </li>' +
      '<li>Yellow/Warning: 50 - 100 µg/m³ </li>' +
      '<li>Red/Alarm: > 100 µg/m³ </li>' +
      '</ul>'
  ],
  covid19InfoN: [
    '<h4> Resistencia a virus </h4>' +
      '<br/>Los niveles recomendados del indicador de resistencia a virus para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70 </li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Virus Indicator </h4>' +
      '<br/>The recommended virus resistance indicator levels for a healthy space are :<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  ventilationIndicatorInfoN: [
    '<h4> Eficacia de ventilación </h4>' +
      '<br/>Los niveles recomendados del indicador de eficacia de ventilación para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70 </li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Ventilation Efficiency </h4>' +
      '<br/>The recommended values of the ventilation efficiency indicator for a healthy room are :<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  iaqInfoN: [
    '<h4> Calidad del Aire Interior </h4>' +
      '<br/>Los niveles recomendados de indicador Calidad del Aire Interior para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70</li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Indoor Air Quality </h4>' +
      '<br/>Recommended Indoor Air Quality indicator levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  thermalIndicatorInfoN: [
    '<h4> Indicador térmico </h4>' +
      '<br/>Los niveles recomendados de indicador térmico para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70</li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Thermal indicatorr </h4>' +
      '<br/>Recommended Thermal indicator levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  no2InfoN: [
    '<h4> NO₂ </h4>' +
      '<br/>Los niveles recomendados de NO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 21 ppb </li>' +
      '<li>Amarillo/Alerta: 21 - 105 ppb </li>' +
      '<li>Rojo/Alarma: > 105 ppb </li>' +
      '</ul>',
    '<h4> NO₂ </h4>' +
      '<br/>Recommended NO₂  levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 21 ppb </li>' +
      '<li>Yellow/Warning: 21 - 105 ppb </li>' +
      '<li>Red/Alarm: > 105 ppb </li>' +
      '</ul>'
  ],
  no2InfoNUg: [
    '<h4> NO₂ </h4>' +
      '<br/>Los niveles recomendados de NO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 40 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 40 - 198 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 198 µg/m³ </li>' +
      '</ul>',
    '<h4> NO₂ </h4>' +
      '<br/>Recommended NO₂  levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 40 µg/m³ </li>' +
      '<li>Yellow/Warning: 40 - 198 µg/m³ </li>' +
      '<li>Red/Alarm: > 198 µg/m³ </li>' +
      '</ul>'
  ],

  inactivityInfoN: [
    '<h4> Inactividad </h4>' +
      '<br/>Indica cuanto tiempo de inactividad debe transcurrir para que se genere la notificación.<br/>',
    '<h4> Inactivity </h4>' +
      '<br/>Indicates how much time of inactivity must pass before the notification is generated.<br/>'
  ],

  errorInfoN: [
    '<h4> Error </h4>' +
      '<br/>Indica el parámetro del sensor que te interese para cuando este fallé se genere una notificación.<br/>',
    '<h4> Error </h4>' +
      '<br/>Indicate the parameter of the sensor that interests you so that when this fails, a notification is generated.<br/>'
  ],

  emailInfoN: [
    '<h4> Correos </h4>' +
      '<br/>Las notificaciones de las alarmas llegarán a los correos seleccionados. Los correos en gris mas claro están pendietes de verificar, estás personas no recbiran notificaciones hasta que verifiquen su correo.<br/>',
    '<h4> Emails </h4>' +
      '<br/>Alarm notifications will reach the selected emails. The emails in lighter gray are pending verification, these people will not receive notifications until they verify their email.<br/>'
  ],
  newUserInfoN: [
    '<h4> Nuevo usuario </h4>' +
      '<br/>Se creará automáticamente una cuenta en My inBiot con el correo indicado (si no existía previamente). El usuario debe aceptar la invitación en el correo electrónico para acceder a los dispositivos y completar la información de la cuenta si esta fuese nueva. <br/>',
    '<h4> New user </h4>' +
      '<br/>A My inBiot account will be automatically created with the specified email address (if it does not already exist). The user must accept the email invitation to access the devices and complete the account information if the account is new.<br/>',
    '<h4> Neuer Benutzer </h4>' +
      '<br/>Es wird automatisch ein My inBiot-Konto mit der angegebenen E-Mail-Adresse erstellt (falls es nicht bereits existiert). Der Benutzer muss die E-Mail-Einladung akzeptieren, um auf die Geräte zuzugreifen, und die Kontoinformationen vervollständigen, wenn das Konto neu ist.<br/>'
  ],
  rolesInfoN: [
    '<h4> Descripción de los roles </h4>' +
      '<br/><b>Visualización</b>: Ver los datos de los dispositivos en tiempo real y crear/editar/borrar compartibles (links, QR y pantalla completa).<br/>' +
      '<br/><b>Manager</b>: Lo mismo que el rol de visualización + editar la información y configuración de los dispositivos, utilizar la descarga de datos, descargar informes inteligentes y reportes de las certificaciones.<br/>',
    '<h4> Roles description </h4>' +
      '<br/><b>Viewer</b>: View device data in real time and create/edit/delete shareables (links, QR and full screen).<br/>' +
      '<br/><b>Manager</b>: Same as visualisation role + edit device information and configuration, use data download, download smart reports and certification reports.<br/>',
    '<h4> Beschreibung der Rollen </h4>' +
      '<br/><b>Betrachter</b>: Zeigt Gerätedaten in Echtzeit an und erstellt/bearbeitet/löscht Shareables (Links, QR und Vollbild).<br/>' +
      '<br/><b>Verwalter</b>: Gleiche Rolle wie Betrachter + Bearbeiten von Geräteinformationen und -konfiguration, Verwendung des Datendownloads, Herunterladen von Smart Reports und Zertifizierungsberichten.<br/>'
  ],

  privacyPolicy: [
    '<h1>Política de Privacidad</h1>' +
      '<br/>' +
      '<br/>' +
      '<p>La presente política de privacidad explica qué tipo de información recopilamos, almacenamos y usamos cuando utiliza los dispositivos y servicios. </p>' +
      '<br/>' +
      '<p>Nuestra actividad se rige por el comúnmente conocido como Reglamento General de Protección de Datos (RGPD), que es el Reglamento (UE) 2016/679 del Parlamento y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE, y demás normativa nacional que sea de aplicación.</p>' +
      '<br/>' +
      '<h3>Información que nos proporcionas</h3>' +
      '<br/>' +
      '<h4>Información de registro de cuenta</h4>' +
      '<br/>' +
      '<p>Cuando creas una cuenta en <b>myinbiot.com</b>, recopilamos información de identificación personal, que puede incluir su nombre, apellidos, dirección de correo electrónico, contraseña y localización donde el dispositivo será utilizado.  Crear una cuenta le permite usar todas las funciones disponibles del Servicio.<p>' +
      '<br/>' +
      '<p>No utilizaremos su información personal para ningún otro propósito sin su consentimiento.</p>' +
      '<br/>' +
      '<h4>Información de configuración del dispositivo</h4>' +
      '<br/>' +
      '<p>Cuando añades dispositivos a la plataforma, proporcionas voluntariamente su código postal. Esto garantiza que pueda ver con precisión mediciones del tiempo exterior en la zona en la que se encuentra. Una vez añadido a tu cuenta los datos recopilados a través de los sensores en sus dispositivos se transfieren a nuestros servidores. Esto te permite acceder a todas sus mediciones de datos actuales e históricos de la calidad del aire para ver cómo sus hábitos y actividades afectan su    ambiente interior.</p>' +
      '<br/>' +
      '<h4>Información cuando el dispositivo no opera correctamente</h4>' +
      '<br/>' +
      '<p>Nuestro objetivo es garantizar que los dispositivos funcionen correctamente en todo momento, entendemos que a veces los dispositivos pueden encontrar un problema al transmitir información, conectarse a los servicios o al recopilar datos ambientales. Para mejorar continuamente su experiencia, cuando los dispositivos encuentran un problema, se nos puede contactar para que podamos ayudarlo. Para verificar que le ocurre al dispositivo en algunos casos será necesario añadirlo a nuestro sistema de manera que tendremos acceso a los datos registrados por el dispositivo, pero en ningún caso tendremos acceso a los datos personales.</p>' +
      '<br/>' +
      '<h3>Tratamiento de datos</h3>' +
      '<br/>' +
      '<p>Utilizamos la información que recopilamos para responder a tus correos electrónicos y para enviarte mensajes de servicio. inBiot Monitoring S.L. recoge exclusivamente la información personal en la medida necesaria para alcanzar un propósito específico. La información no se utilizará para una finalidad incompatible con la descrita.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente revela la información a terceros si es necesaria para el cumplimiento de la finalidad del servicio y únicamente a las personas que deben conocerlos. Todo ello al objeto de que se pueda prestar el servicio tratando tus datos personales con confidencialidad y reserva, conforme a la legislación vigente.</p>' +
      '<br/>' +
      '<h3>¿Cuánto tiempo guardamos tus datos?</h3>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente guarda los datos el tiempo necesario para cumplir la finalidad de su recogida o de su procesamiento posterior. El periodo de conservación de los datos dependerá del servicio y en cada servicio se indicará la duración del tratamiento de datos personales.</p>' +
      '<br/>' +
      '<h3>Derechos de los usuarios</h3>' +
      '<br/>' +
      '<p>Cualquier persona tiene derecho a acceder y modificar su información personal, como su nombre, apellido, contraseña y país. Así como solicitar el acceso, rectificación, supresión, limitación del tratamiento, oposición o derecho a la portabilidad de sus datos personales, escribiéndonos a nuestra dirección postal o electrónica indicada en el apartado “Identidad del titular de este sitio web” del presente Términos de Uso, comunicándonos el derecho que desea ejercer. Tendrá derecho igualmente a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento no afectará a la licitud del tratamiento efectuado antes de la retirada de dicho consentimiento. Tiene derecho a presentar una reclamación ante la autoridad de control si considera que pueden haber sido vulnerados sus derechos en lo concerniente a la protección de sus datos personales.</p>' +
      '<br/>' +
      '<h3>Modificación de este documento</h3>' +
      '<br/>' +
      '<p>La legislación puede cambiar, nuestro sitio web y otros servicios pueden evolucionar, nosotros podemos recurrir a nuevos proveedores de servicios. Como resultado, es posible que tengamos que realizar algunos cambios en la Política de Privacidad y se reserva el derecho a actualizarla o modificarla en cualquier momento. Esta Política de Privacidad puede consultarse en myinbiot.com en cualquier momento.</p>',
    '<h1>Privacy Policy</h1>' +
      '<br/>' +
      '<br/>' +
      '<p>This privacy policy explains what information we collect, store, and use when you use the devices and services. </p>' +
      '<br/>' +
      '<p>Our activity is governed by what is known as the General Data Protection Regulation (GDPR), which is the Regulation (EU) 2016/679 of the Parliament and of the Council, of April 27, 2016, regarding the protection of natural persons. Protection with regard to the processing of personal data and the free circulation of these data and by which the Directive 95/46/EC and other applicable national regulations are repealed.</p>' +
      '<br/>' +
      '<h3>INFORMATION YOU PROVIDE US</h3>' +
      '<br/>' +
      '<h4>ACCOUNT REGISTRATION INFORMATION</h4>' +
      '<br/>' +
      '<p>When you create an account on  <b>myinbiot.com</b>, we collect personal information, which may include your first name, last name, email address, password, and the location where the device will be used. Creating an account allows you to use all available features of the Service.<p>' +
      '<br/>' +
      '<p>We will not use your personal information for any other purpose without your consent.</p>' +
      '<br/>' +
      '<h4>DEVICE CONFIGURATION INFORMATION</h4>' +
      '<br/>' +
      '<p>When you add devices to the platform, you voluntarily provide their zip code. This ensures that you can accurately see outside weather measurements in the area you are in. Once added to your account, the data collected through the sensors on your devices is transferred to our servers. This allows you to access all of your current and historical air quality data measurements to see how your habits and activities affect your indoor environment.</p>' +
      '<br/>' +
      '<h4>INFORMATION WHEN THE DEVICE DOES NOT OPERATE CORRECTLY</h4>' +
      '<br/>' +
      '<p>Our goal is to ensure devices are working properly at all times. We understand that sometimes devices may encounter a problem transmitting information, connecting to services, or collecting environmental data. To continually improve your experience, when devices encounter a problem, we can contact you so we can help you. To verify what is happening to the device, in some cases it will be necessary to add it to our system so that we will have access to the data recorded by the device, but in no case will we have access to personal data.</p>' +
      '<br/>' +
      '<h3>DATA TREATMENT</h3>' +
      '<br/>' +
      '<p>We use the information we collect to respond to your emails and to send you service messages. inBiot Monitoring S.L. only collects personal information to the extent necessary to achieve a specific purpose. The information will not be used for a purpose incompatible with the one described.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. only reveals information to third parties if it is necessary for the fulfillment of the purpose of the service and only to the people who must know them. All this in order to provide, always treating your personal data with confidentiality and reserve, in accordance with current legislation.</p>' +
      '<br/>' +
      '<h3>HOW LONG DO WE KEEP YOUR DATA?</h3>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. only keeps the data for the time necessary to fulfill the purpose of its collection or its subsequent processing. The retention period of the data will depend on the service and in each service the duration of the processing of personal data will be indicated.</p>' +
      '<br/>' +
      '<h3>USERS RIGHTS</h3>' +
      '<br/>' +
      '<p>Anyone has the right to access and modify their personal information, such as their first name, last name, password and country. As well as request access, rectification, deletion, limitation of treatment, opposition or right to portability of your personal data, by writing to our postal or electronic address indicated in the section "Identity of the owner of this website" of these Terms of Use , informing us of what is needed. You will also have the right to withdraw the consent given at any time. The withdrawal of consent will not affect the legality of the treatment carried out before the withdrawal of said consent. You have the right to present a claim with the supervisory authority if you consider that your rights may have been violated with regard to the protection of your personal data.</p>' +
      '<br/>' +
      '<h3>MODIFICATION OF THIS DOCUMENT</h3>' +
      '<br/>' +
      '<p>Legislation may change, our website and other services may evolve, we may use new service providers. As a result, we may have to make some changes to the Privacy Policy. inBiot reserves the right to update or modify it at any time. This Privacy Policy can be viewed at myinbiot.com at any time.</p>'
  ],

  termsOfUse: [
    '<h1>Términos de Uso</h1>' +
      '<br/>' +
      '<p>Al acceder y / o utilizar los servicios, usted acepta estos Términos y nuestra Política de privacidad en su nombre o en la entidad que representa en relación con dicho acceso y / o uso. Usted declara y garantiza que tiene el derecho, la autoridad y la capacidad de aceptar y aceptar estos Términos en su nombre o en la entidad que representa. Usted acepta permitirnos comunicarnos electrónicamente con respecto a los dispositivos y los servicios.</p>' +
      '<br/>' +
      '<p>Como se describe a continuación, acepta permitirnos actualizar automáticamente el software relacionado con los dispositivos y los servicios sin notificarle ni solicitar su consentimiento siempre que esas actualizaciones no afecten a los datos privados. Si una actualización necesita modificar o actualizar sus datos privados de alguna manera, le enviaremos una solicitud de consentimiento antes de cualquier modificación.</p>' +
      '<br/>' +
      '<p>Si no acepta algunos de los Términos, no cree una cuenta en myinbiot.com.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en su Web o en la configuración y presentación de ésta.</p>' +
      '<p>Con objeto de mantener actualizada la información publicada en los portales, los contenidos del mismo podrán ser modificados, corregidos, eliminados o añadidos en cualquier momento, por lo que será conveniente comprobar la vigencia o exactitud de los mismos acudiendo a las fuentes oficiales.</p>' +
      '<br/>' +
      '<h2>Identidad del Titular</h2>' +
      '<br/>' +
      '<p>El titular del presente Sitio Web es:</p>' +
      '<ul>' +
      '<li>Denominación social: <b> inBiot Monitoring S.L.</b></li>' +
      '<li>C.I.F.: B71361596</li>' +
      '<li>Domicilio Social: P.º Santxiki, 2, Oficina LB5, 31192 Mutilva, Navarra (España)</li>' +
      '<li>e-mail de contacto: info@inbiot.es</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Legislación</h2>' +
      '<br/>' +
      '<p>Con carácter general, el presente Sitio Web y las relaciones entre el Titular del presente Sitio Web con los Usuarios, se rigen a todos los efectos por la legislación y jurisdicción españolas.</p>' +
      '<br/>' +
      '<h2>Propiedad Intelectual</h2>' +
      '<br/>' +
      '<p>Los derechos de propiedad intelectual del contenido, diseño gráfico y códigos del presente Sitio Web, tanto existente en el momento actual como en el futuro, son titularidad del Titular del presente Sitio Web. Por lo tanto, queda prohibida su reproducción, distribución, divulgación, comunicación pública, copia, transformación o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aún citando las fuentes, salvo consentimiento por escrito del Titular del presente Sitio Web. Todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en las páginas web del presente Sitio Web son propiedad de sus dueños y están protegidos por ley. El acceso por parte de los Usuarios al Sitio Web no supone la concesión de derecho alguno sobre dichos derechos de propiedad intelectual.</p>' +
      '<br/>' +
      '<h2>Confidencialidad de la cuenta</h2>' +
      '<br/>' +
      '<p>Usted es responsable de mantener la confidencialidad de su Cuenta, incluida su información de inicio de sesión y contraseña. Usted acepta mantener su contraseña de forma segura y será responsable de todas las actividades que ocurran en su Cuenta. Si sospecha un uso no autorizado de su cuenta, acepta notificarnos de inmediato a info@inbiot.es. No somos responsables de ninguna pérdida o daño que surja debido a su incumplimiento de los requisitos anteriores.</p>' +
      '<br/>' +
      '<h2>Restricciones de Uso</h2>' +
      '<br/>' +
      '<p>Al usar los dispositivos y los servicios, usted acepta lo siguiente: </p>' +
      '<ul>' +
      '<li>No comercializará los dispositivos o los servicios alquilando, vendiendo, otorgando licencias o distribuyendo los dispositivos o los servicios.</li>' +
      '<li>No accederá a los servicios para crear un servicio similar o competitivo.</li>' +
      '<li>No realizará ingeniería inversa, desmontará, compilará inversa ni realizará trabajos derivados de ningún componente de los dispositivos o los servicios.</li>' +
      '<li>No copiará, reproducirá, distribuirá, republicará, descargará, mostrará, publicará ni transmitirá los servicios de ninguna forma ni por ningún medio.</li>' +
      '<li>No utilizará los servicios para distribuir virus, gusanos u otro software que pueda dañar cualquier elemento de los servicios.</li>' +
      '<li>No eliminará, alterará ni ocultará los avisos de derechos de propiedad intelectual que puedan mostrarse en relación con los dispositivos o los servicios.</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Exclusión de Garantías y de Responsabilidad</h2>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. rechaza todas las garantías y condiciones relativas a los productos, ya sean expresas, implícitas o legales, incluso las garantías implícitas de calidad comercial, conforme a un uso específico, de titularidad, no violación de los derechos de terceros, disfrute pacífico y exactitud. inBiot Monitoring S.L. no garantiza que la utilización del producto no incluya ningún error o no se interrumpa jamás o que el producto trate siempre los datos correctamente.</p>' +
      '<br/>' +
      '<p>La plataforma facilita información relativa a las mediciones interiores. Toda la información relativa al producto se ofrece para mayor comodidad del cliente, tal como está y según disponibilidad. inBiot Monitoring S.L.  no puede asegurar ni garantizar que la información relativa al producto esté disponible, sea precisa o fiable. La utilización de la información relativa al producto, de los servicios o del producto se hace a su propia discreción y bajo su propia responsabilidad. Usted es plenamente responsable (y inBiot Monitoring S.L.  declina cualquier responsabilidad) en caso de pérdida, responsabilidad o daño derivado del uso que usted haga de la información relativa al producto, de los servicios o del producto.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. no será responsable por ningún daño de ningún tipo que surja del uso del sitio myinbiot.com o de cualquier información, contenido, materiales, productos (incluido software) o servicios incluidos o puestos a su disposición de otro modo a través del sitio, incluyendo, sin limitarse a ellos, daños directos, indirectos, incidentales, punitivos, y emergentes, a no ser que se especifique lo contrario en este contrato.</p>',
    '<h1>TERMS OF USE</h1>' +
      '<br/>' +
      '<p>By accessing and/or using the Services, you agree to these Terms and our Privacy Policy on behalf of yourself or the entity you represent in connection with such access and/or use. You represent and warrant that you have the right, authority, and capacity to accept and agree to these Terms on behalf of yourself or the entity you represent. You agree to allow us to communicate electronically regarding Devices and Services.</p>' +
      '<br/>' +
      '<p>As described below, you agree to allow us to automatically update device-related software and services without notice to you or your consent as long as such updates do not affect private data. If an update needs to modify or update your private data in any way, we will send you a consent request prior to any modification.</p>' +
      '<br/>' +
      '<p>If you do not agree to any of the Terms, please do not create an account on myinbiot.com.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. reserves the right to make, at any time and without the need for prior notice, modifications and updates to the information contained on its website or in its configuration and presentation.</p>' +
      '<p>In order to keep the information published on the portals up-to-date, its contents may be modified, corrected, deleted or added at any time, so it will be convenient to check their validity or accuracy by going to official sources.</p>' +
      '<br/>' +
      '<h2>IDENTITY OF THE OWNER</h2>' +
      '<br/>' +
      '<p>The owner of this Website is:</p>' +
      '<ul>' +
      '<li>Company name:  <b> inBiot Monitoring S.L.</b></li>' +
      '<li>C.I.F.: B71361596</li>' +
      '<li>Registered Office:  Pol. Ind. Mocholí, plaza CEIN 5 of. T-7 Código Postal: 31110 Noáin- Navarra (España)</li>' +
      '<li>Contact e-mail: info@inbiot.es</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>LEGISLATION </h2>' +
      '<br/>' +
      '<p>In general, this Website and the relations between the Owner of this Website and the Users are governed for all purposes by Spanish legislation and jurisdiction.</p>' +
      '<br/>' +
      '<h2>INTELLECTUAL PROPERTY</h2>' +
      '<br/>' +
      '<p>The intellectual property rights of the content, graphic design and codes of this Website, both existing at the present time and in the future, are the property of the Owner of this Website. Therefore, its reproduction, distribution, disclosure, public communication, copying, transformation or any other activity that may be carried out with the contents of its web pages, even citing the sources, is prohibited, except with the written consent of the Owner of this Website. All trade names, trademarks or distinctive signs of any kind contained in the web pages of this Website are the property of their owners and are protected by law. Access by Users to the Website does not imply the granting of any right over said intellectual property rights.</p>' +
      '<br/>' +
      '<h2>CONFIDENTIALITY </h2>' +
      '<br/>' +
      '<p>You are responsible for maintaining the confidentiality of your Account, including your login information and password. You agree to keep your password secure and you will be responsible for all activities that occur under your Account. If you suspect unauthorized use of your account, you agree to notify us immediately at info@inbiot.es. We are not responsible for any loss or damage arising from your failure to comply with the above requirements.</p>' +
      '<br/>' +
      '<h2>USE RESTRICTIONS </h2>' +
      '<br/>' +
      '<p>By using the Devices and Services, you agree to the following: </p>' +
      '<ul>' +
      '<li>You will not market the Devices or the Services by renting, selling, licensing, or distributing the Devices or the Services.</li>' +
      '<li>You will not access the Services to create a similar or competitive service.</li>' +
      '<li>You will not reverse engineer, disassemble, reverse compile, or make derivative works of any component of the Devices or the Services.</li>' +
      '<li>You will not copy, reproduce, distribute, republish, download, display, post or transmit the Services in any form or by any means.</li>' +
      '<li>You will not use the Services to distribute viruses, worms, or other software that may damage any element of the Services.</li>' +
      '<li>You will not remove, alter, or obscure any intellectual property rights notices that may be displayed in connection with the Devices or the Services.</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>EXCLUSION OF GUARANTEES AND RESPONSIBILITIES</h2>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. disclaims all warranties and conditions relating to the products, whether express, implied or statutory, including the implied warranties of merchantability, fitness for purpose, title, non-infringement of third party rights, peaceful enjoyment and accuracy. inBiot Monitoring S.L. does not guarantee that the use of the product will not include any errors or will never be interrupted or that the product always treats the data correctly.</p>' +
      '<br/>' +
      '<p>The platform provides information regarding interior measurements. All information regarding the product is provided for the convenience of the customer, as it is and according to availability. inBiot Monitoring S.L. cannot ensure or warrant that the information relating to the product is available, accurate or reliable. The use of the information related to the product, the services or the product is done at your own discretion and under your own responsibility. You are fully responsible (and inBiot Monitoring S.L. declines any responsibility) in case of loss, liability or damage arising from your use of the information related to the product, the services or the product.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. shall not be liable for any damages of any kind arising from the use of the myinbiot.com site or any information, content, materials, products (including software) or services included on or otherwise made available to you through the site, including, without limitation, direct, indirect, incidental, punitive, and consequential damages, unless otherwise specified in this agreement.</p>'
  ],
  Configuration: ['Configuración', 'Configuration', 'Konfiguration'],
  accountSettings: ['Configuración de la cuenta', 'Account Settings', 'Kontoeinstellungen'],
  addUser: ['Añadir nuevo usuario', 'Add new user', 'Neuen Benutzer hinzufügen'],
  emailAddress: ['Indicar correo electrónico', 'Indicate e-mail address', 'E-Mail Adresse angeben'],
  newEmail: ['Nuevo usuario (correo)', 'New user (e-mail)', 'Neuer Benutzer (e-mail)'],
  Viewer: ['Visualización', 'Viewer', 'Betrachter'],
  Editor: ['Editor', 'Editor', 'Herausgeber'],
  Manager: ['Manager', 'Manager', 'Manager'],
  role: ['Seleccionar rol', 'Select role', 'Rolle auswählen'],
  deviceAccess: ['Acceso a dispositivos', 'Device access', 'Zugang zu Geräten'],
  selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
  signal: ['Señal', 'Signal', 'Signal'],
  connectivity: ['Conectividad', 'Connectivity', 'Konnektivität'],
  serialNumber: ['Número de serie', 'Serial Number', 'Seriennummer'],
  moreRoleInfo: [
    'Más información acerca de los roles',
    'More information about roles',
    'Weitere Informationen zu den Rollen'
  ]
}
