import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const GET_SYSTEM_BY_ID = '@system/GET_SYSTEM_BY_ID'
export const GET_SYSTEM_BY_ID_WITH_DATA_RANGE = '@system/GET_SYSTEM_BY_ID_WITH_DATA_RANGE'
export const GET_SYSTEMS_HOME = '@system/GET_SYSTEMS_HOME'
export const UPDATE_SYSTEM = '@system/UPDATE_SYSTEM'
export const UPDATE_SYSTEM_DATA = '@system/UPDATE_SYSTEM_DATA'
export const UPDATE_SYSTEM_LIGHTS = '@system/UPDATE_SYSTEM_LIGHTS'
export const UPDATE_SYSTEM_GLOBAL_LIGHTS = '@system/UPDATE_SYSTEM_GLOBAL_LIGHTS'
export const UPDATE_SYSTEM_CALIBRATION = '@system/UPDATE_SYSTEM_CALIBRATION'
export const UPDATE_SYSTEM_GLOBAL_CALIBRATION = '@system/UPDATE_SYSTEM_GLOBAL_CALIBRATION'
export const UPDATE_SYSTEM_PERIODICITY = '@system/UPDATE_SYSTEM_PERIODICITY'
export const UPDATE_SYSTEM_GLOBAL_PERIODICITY = '@system/UPDATE_SYSTEM_GLOBAL_PERIODICITY'
export const POLL_SYSTEM_START = '@system/POLL_SYSTEM_START'
export const POLL_SYSTEM_STOP = '@system/POLL_SYSTEM_STOP'
export const POLL_SYSTEMS_START = '@system/POLL_SYSTEMS_START'
export const POLL_SYSTEMS_STOP = '@system/POLL_SYSTEMS_STOP'
export const DELETE_DATA_FROM_USER_SYSTEM = '@system/DELETE_DATA_FROM_USER_SYSTEM'

export const CREATE_GROUP = '@system/CREATE_GROUP'
export const UPDATE_GROUP = '@system/UPDATE_GROUP'
export const DELETE_GROUP = '@system/DELETE_GROUP'
export const DELETE_GROUP_SYSTEM = '@system/DELETE_GROUP_SYSTEM'

export const GET_SCREENS = '@system/GET_SCREENS'
export const CREATE_SCREEN = '@system/CREATE_SCREEN'
export const UPDATE_SCREEN = '@system/UPDATE_SCREEN'
export const DELETE_SCREEN = '@system/DELETE_SCREEN'

export const CREATE_ALARM = '@system/CREATE_ALARM'
export const UPDATE_ALARM = '@system/UPDATE_ALARM'
export const DELETE_ALARM = '@system/DELETE_ALARM'
export const GET_ALARMS = '@system/GET_ALARMS'
export const CHANGE_ALARM_STATUS = '@system/CHANGE_ALARM_STATUS'

export const CREATE_INACTIVITY_ALARM = '@system/CREATE_INACTIVITY_ALARM'
export const UPDATE_INACTIVITY_ALARM = '@system/UPDATE_INACTIVITY_ALARM'
export const DELETE_INACTIVITY_ALARM = '@system/DELETE_INACTIVITY_ALARM'
export const GET_INACTIVITY_ALARMS = '@system/GET_INACTIVITY_ALARMS'
export const CHANGE_INACTIVITY_ALARM_STATUS = '@system/CHANGE_INACTIVITY_ALARM_STATUS'

export const GET_SYSTEM_BY_ID_REQUEST = createRequestTypes('@system/GET_SYSTEM_BY_ID_REQUEST')
export const GET_SYSTEM_BY_ID_WITH_DATA_RANGE_REQUEST =
  '@system/GET_SYSTEM_BY_ID_WITH_DATA_RANGE_REQUEST'
export const GET_SYSTEMS_HOME_REQUEST = createRequestTypes('@system/GET_SYSTEMS_HOME_REQUEST')
export const UPDATE_SYSTEM_REQUEST = createRequestTypes('@system/UPDATE_SYSTEM')
export const UPDATE_SYSTEM_DATA_REQUEST = createRequestTypes('@system/UPDATE_SYSTEM_DATA_REQUEST')

export const UPDATE_SYSTEM_LIGHTS_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_LIGHTS_REQUEST'
)
export const UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST'
)
export const UPDATE_SYSTEM_CALIBRATION_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_CALIBRATION_REQUEST'
)
export const UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST'
)
export const UPDATE_SYSTEM_PERIODICITY_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_PERIODICITY_REQUEST'
)
export const UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST'
)
export const POLL_SYSTEM_REQUEST = createRequestTypes('@system/POLL_SYSTEM_REQUEST')
export const POLL_SYSTEMS_REQUEST = createRequestTypes('@system/POLL_SYSTEMS_REQUEST')
export const DELETE_DATA_FROM_USER_SYSTEM_REQUEST = createRequestTypes(
  '@system/DELETE_DATA_FROM_USER_SYSTEM_REQUEST'
)

export const CREATE_GROUP_REQUEST = createRequestTypes('@system/CREATE_GROUP_REQUEST')
export const UPDATE_GROUP_REQUEST = createRequestTypes('@system/UPDATE_GROUP_REQUEST')
export const DELETE_GROUP_REQUEST = createRequestTypes('@system/DELETE_GROUP_REQUEST')
export const DELETE_GROUP_SYSTEM_REQUEST = createRequestTypes('@system/DELETE_GROUP_SYSTEM_REQUEST')

export const GET_SCREENS_REQUEST = createRequestTypes('@system/GET_SCREENS_REQUEST')
export const CREATE_SCREEN_REQUEST = createRequestTypes('@system/CREATE_SCREEN_REQUEST')
export const UPDATE_SCREEN_REQUEST = createRequestTypes('@system/UPDATE_SCREEN_REQUEST')
export const DELETE_SCREEN_REQUEST = createRequestTypes('@system/DELETE_SCREEN_REQUEST')

export const CREATE_ALARM_REQUEST = createRequestTypes('@system/CREATE_ALARM_REQUEST')
export const CREATE_REPORT_REQUEST = createRequestTypes('@system/CREATE_REPORT_REQUEST')
export const UPDATE_ALARM_REQUEST = createRequestTypes('@system/UPDATE_ALARM_REQUEST')
export const DELETE_ALARM_REQUEST = createRequestTypes('@system/DELETE_ALARM_REQUEST')
export const GET_ALARMS_REQUEST = createRequestTypes('@system/GET_ALARMS_REQUEST')
export const CHANGE_ALARM_STATUS_REQUEST = createRequestTypes('@system/CHANGE_ALARM_STATUS_REQUEST')
export const CREATE_INACTIVITY_ALARM_REQUEST = createRequestTypes(
  '@system/CREATE_INACTIVITY_ALARM_REQUEST'
)
export const UPDATE_INACTIVITY_ALARM_REQUEST = createRequestTypes(
  '@system/UPDATE_INACTIVITY_ALARM_REQUEST'
)
export const DELETE_INACTIVITY_ALARM_REQUEST = createRequestTypes(
  '@system/DELETE_INACTIVITY_ALARM_REQUEST'
)
export const GET_INACTIVITY_ALARMS_REQUEST = createRequestTypes(
  '@system/GET_INACTIVITY_ALARMS_REQUEST'
)
export const CHANGE_INACTIVITY_ALARM_STATUS_REQUEST = createRequestTypes(
  '@system/CHANGE_INACTIVITY_ALARM_STATUS_REQUEST'
)

export const constants = {
  GET_SYSTEM_BY_ID,
  GET_SYSTEM_BY_ID_WITH_DATA_RANGE,
  GET_SYSTEMS_HOME,
  UPDATE_SYSTEM,
  UPDATE_SYSTEM_DATA,
  UPDATE_SYSTEM_CALIBRATION,
  UPDATE_SYSTEM_GLOBAL_CALIBRATION,
  UPDATE_SYSTEM_LIGHTS,
  UPDATE_SYSTEM_GLOBAL_LIGHTS,
  UPDATE_SYSTEM_PERIODICITY,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY,
  POLL_SYSTEM_START,
  POLL_SYSTEM_STOP,
  POLL_SYSTEMS_START,

  POLL_SYSTEMS_STOP,
  DELETE_DATA_FROM_USER_SYSTEM,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  DELETE_GROUP_SYSTEM,
  GET_SCREENS,
  CREATE_SCREEN,
  UPDATE_SCREEN,
  DELETE_SCREEN,
  CREATE_ALARM,
  UPDATE_ALARM,
  DELETE_ALARM,
  GET_ALARMS,
  CHANGE_ALARM_STATUS,
  CREATE_INACTIVITY_ALARM,
  UPDATE_INACTIVITY_ALARM,
  DELETE_INACTIVITY_ALARM,
  GET_INACTIVITY_ALARMS,
  CHANGE_INACTIVITY_ALARM_STATUS,

  GET_SYSTEM_BY_ID_REQUEST,
  GET_SYSTEM_BY_ID_WITH_DATA_RANGE_REQUEST,
  GET_SYSTEMS_HOME_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_DATA_REQUEST,
  UPDATE_SYSTEM_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_LIGHTS_REQUEST,
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST,
  UPDATE_SYSTEM_PERIODICITY_REQUEST,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST,
  POLL_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  DELETE_DATA_FROM_USER_SYSTEM_REQUEST,
  CREATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SYSTEM_REQUEST,
  GET_SCREENS_REQUEST,
  CREATE_SCREEN_REQUEST,
  UPDATE_SCREEN_REQUEST,
  DELETE_SCREEN_REQUEST,
  CREATE_ALARM_REQUEST,
  CREATE_REPORT_REQUEST,
  UPDATE_ALARM_REQUEST,
  DELETE_ALARM_REQUEST,
  GET_ALARMS_REQUEST,
  CHANGE_ALARM_STATUS_REQUEST,
  CREATE_INACTIVITY_ALARM_REQUEST,
  UPDATE_INACTIVITY_ALARM_REQUEST,
  DELETE_INACTIVITY_ALARM_REQUEST,
  GET_INACTIVITY_ALARMS_REQUEST,
  CHANGE_INACTIVITY_ALARM_STATUS_REQUEST
}

export const getSystemById = createAction(GET_SYSTEM_BY_ID)
export const getSystemsHome = createAction(GET_SYSTEMS_HOME)
export const updateSystem = createAction(UPDATE_SYSTEM)
export const updateSystemLights = createAction(UPDATE_SYSTEM_LIGHTS)
export const updateSystemGlobalLights = createAction(UPDATE_SYSTEM_GLOBAL_LIGHTS)
export const updateSystemCalibration = createAction(UPDATE_SYSTEM_CALIBRATION)
export const updateSystemGlobalCalibration = createAction(UPDATE_SYSTEM_GLOBAL_CALIBRATION)
export const updateSystemPeriodicity = createAction(UPDATE_SYSTEM_PERIODICITY)
export const updateSystemGlobalPeriodicity = createAction(UPDATE_SYSTEM_GLOBAL_PERIODICITY)
export const pollSystemStart = createAction(POLL_SYSTEM_START)
export const pollSystemStop = createAction(POLL_SYSTEM_STOP)
export const pollSystemsStart = createAction(POLL_SYSTEMS_START)

export const pollSystemsStop = createAction(POLL_SYSTEMS_STOP)
export const deleteDataFromUser = createAction(DELETE_DATA_FROM_USER_SYSTEM)
export const createGroup = createAction(CREATE_GROUP)
export const updateGroup = createAction(UPDATE_GROUP)
export const deleteGroup = createAction(DELETE_GROUP)
export const deleteGroupSystem = createAction(DELETE_GROUP_SYSTEM)
export const getScreens = createAction(GET_SCREENS)
export const createScreen = createAction(CREATE_SCREEN)
export const updateScreen = createAction(UPDATE_SCREEN)
export const deleteScreen = createAction(DELETE_SCREEN)
export const createAlarm = createAction(CREATE_ALARM)
export const updateAlarm = createAction(UPDATE_ALARM)
export const deleteAlarm = createAction(DELETE_ALARM)
export const getAlarms = createAction(GET_ALARMS)
export const changeAlarmStatus = createAction(CHANGE_ALARM_STATUS)
export const createInactivityAlarm = createAction(CREATE_INACTIVITY_ALARM)
export const updateInactivityAlarm = createAction(UPDATE_INACTIVITY_ALARM)
export const deleteInactivityAlarm = createAction(DELETE_INACTIVITY_ALARM)
export const getInactivityAlarms = createAction(GET_INACTIVITY_ALARMS)
export const changeInactivityAlarmStatus = createAction(CHANGE_INACTIVITY_ALARM_STATUS)
export const getSystemByIdRequest = createRequestAction(GET_SYSTEM_BY_ID_REQUEST)
export const getSystemsHomeRequest = createRequestAction(GET_SYSTEMS_HOME_REQUEST)
export const updateSystemRequest = createRequestAction(UPDATE_SYSTEM_REQUEST)
export const updateSystemLightsRequest = createRequestAction(UPDATE_SYSTEM_LIGHTS_REQUEST)
export const updateSystemCalibrationRequest = createRequestAction(UPDATE_SYSTEM_CALIBRATION_REQUEST)
export const updateSystemGlobalLightsRequest = createRequestAction(
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST
)
export const updateSystemGlobalCalibrationRequest = createRequestAction(
  UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST
)
export const updateSystemPeriodicityRequest = createRequestAction(UPDATE_SYSTEM_PERIODICITY_REQUEST)
export const updateSystemGlobalPeriodicityRequest = createRequestAction(
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST
)
export const pollSystemRequest = createRequestAction(POLL_SYSTEM_REQUEST)
export const pollSystemsRequest = createRequestAction(POLL_SYSTEMS_REQUEST)
export const deleteDataFromUserRequest = createRequestAction(DELETE_DATA_FROM_USER_SYSTEM_REQUEST)
export const createGroupRequest = createRequestAction(CREATE_GROUP_REQUEST)
export const updateGroupRequest = createRequestAction(UPDATE_GROUP_REQUEST)
export const deleteGroupRequest = createRequestAction(DELETE_GROUP_REQUEST)
export const deleteGroupSystemRequest = createRequestAction(DELETE_GROUP_SYSTEM_REQUEST)
export const getScreensRequest = createRequestAction(GET_SCREENS_REQUEST)
export const createScreenRequest = createRequestAction(CREATE_SCREEN_REQUEST)
export const updateScreenRequest = createRequestAction(UPDATE_SCREEN_REQUEST)
export const deleteScreenRequest = createRequestAction(DELETE_SCREEN_REQUEST)
export const createAlarmRequest = createRequestAction(CREATE_ALARM_REQUEST)
export const createReportRequest = createRequestAction(CREATE_REPORT_REQUEST)
export const updateAlarmRequest = createRequestAction(UPDATE_ALARM_REQUEST)
export const deleteAlarmRequest = createRequestAction(DELETE_ALARM_REQUEST)
export const getAlarmsRequest = createRequestAction(GET_ALARMS_REQUEST)
export const changeAlarmStatusRequest = createRequestAction(CHANGE_ALARM_STATUS_REQUEST)
export const createInactivityAlarmRequest = createRequestAction(CREATE_INACTIVITY_ALARM_REQUEST)
export const updateInactivityAlarmRequest = createRequestAction(UPDATE_INACTIVITY_ALARM_REQUEST)
export const deleteInactivityAlarmRequest = createRequestAction(DELETE_INACTIVITY_ALARM_REQUEST)
export const getInactivityAlarmsRequest = createRequestAction(GET_INACTIVITY_ALARMS_REQUEST)
export const changeInactivityAlarmStatusRequest = createRequestAction(
  CHANGE_INACTIVITY_ALARM_STATUS_REQUEST
)

export default {
  getSystemById,
  getSystemsHome,
  updateSystem,
  updateSystemLights,
  updateSystemCalibration,
  updateSystemGlobalLights,
  updateSystemGlobalCalibration,
  updateSystemPeriodicity,
  updateSystemGlobalPeriodicity,
  pollSystemStart,
  pollSystemStop,
  pollSystemsStart,

  pollSystemsStop,
  deleteDataFromUser,
  createGroup,
  updateGroup,
  deleteGroup,
  deleteGroupSystem,
  getScreens,
  createScreen,
  updateScreen,
  deleteScreen,
  createAlarm,
  updateAlarm,
  deleteAlarm,
  getAlarms,
  changeAlarmStatus,
  createInactivityAlarm,
  updateInactivityAlarm,
  deleteInactivityAlarm,
  getInactivityAlarms,
  changeInactivityAlarmStatus,

  getSystemByIdRequest,
  getSystemsHomeRequest,
  updateSystemRequest,
  updateSystemLightsRequest,
  updateSystemCalibrationRequest,
  updateSystemGlobalLightsRequest,
  updateSystemGlobalCalibrationRequest,
  updateSystemPeriodicityRequest,
  updateSystemGlobalPeriodicityRequest,
  pollSystemRequest,
  pollSystemsRequest,
  deleteDataFromUserRequest,
  createGroupRequest,
  updateGroupRequest,
  deleteGroupRequest,
  deleteGroupSystemRequest,
  getScreensRequest,
  createScreenRequest,
  updateScreenRequest,
  deleteScreenRequest,
  createAlarmRequest,
  updateAlarmRequest,
  deleteAlarmRequest,
  getAlarmsRequest,
  changeAlarmStatusRequest,
  createReportRequest,
  createInactivityAlarmRequest,
  updateInactivityAlarmRequest,
  deleteInactivityAlarmRequest,
  getInactivityAlarmsRequest,
  changeInactivityAlarmStatusRequest
}
