import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import * as Routes from 'constants/Routes'

import actions, { constants } from 'common/actions/user'
import snackbarActions from 'common/actions/snackbar'
import modalActions from 'common/actions/modal'
import { getUserId } from 'common/selectors/user'
import systemsActions from 'common/actions/systems'

import * as api from 'api/user'
import * as schemas from 'schemas'
import { browserHistory } from 'react-router'
import { clearTokenHeader } from 'utils/request'
import { clearAuthStoreData } from 'utils/authStore'

export function* onUpdateUser({ payload: data }) {
  yield put(actions.updateUserRequest.start())

  try {
    const { user } = yield call(api.updateUser, data)
    const norm = yield call(normalize, user, schemas.user)
    const redirect = route => {
      browserHistory.push(route)
    }
    yield put(actions.updateUserRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    location.reload(redirect(Routes.HOME))
  } catch (err) {
    // eslint-disable-next-line no-console
    //  if (__DEV__) console.log({ err })

    yield put(actions.updateUserRequest.failure(err))
  }
}

function* onDeleteUserDevice({ payload: data }) {
  yield put(actions.deleteUserDeviceRequest.start())
  try {
    yield call(api.deleteUserDevice, data)
    yield put(actions.deleteUserDeviceRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.deleteUserDeviceRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onTransferDevicesUser({ payload: data }) {
  yield put(actions.transferDevicesUserRequest.start())
  try {
    yield call(api.transferDevicesUser, data)
    yield put(actions.transferDevicesUserRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.successTransfer'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.transferDevicesUserRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onConfirmTransferDevice({ payload: data }) {
  yield put(actions.confirmTransferDeviceRequest.start())
  try {
    yield call(api.confirmTransferDevice, data)
    yield put(actions.confirmTransferDeviceRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.successTransfer'))
    yield put(modalActions.hideModal())
    location.reload()
  } catch (err) {
    yield put(actions.confirmTransferDeviceRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export function* onUpdateUserPassword({ payload: data }) {
  yield put(actions.updateUserPasswordRequest.start())

  try {
    const { user } = yield call(api.updateUserPassword, data)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.updateUserPasswordRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    // eslint-disable-next-line no-console
    yield put(actions.updateUserPasswordRequest.failure(err))
  }
}

export function* onUpdateCognitoUserPassword({ payload: data }) {
  const redirect = route => {
    browserHistory.push(route)
  }

  yield put(actions.updateUserPasswordRequest.start())

  try {
    const result = yield call(api.updateCognitoUserPassword, data)
    if (result === 'SUCCESS') {
      yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
      yield put(redirect(Routes.HOME))
    } else {
      yield put(actions.updateUserPasswordRequest.failure(result))
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    yield put(actions.updateUserPasswordRequest.failure(err))
  }
}

export function* onAddToUserSystem({ payload: data }) {
  yield put(actions.addSystemToUserRequest.start())

  try {
    const userId = yield select(getUserId)
    const { system } = yield call(api.addSystemToUser, userId, data)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.addSystemToUserRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.addedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.addSystemToUserRequest.failure(err))
  }
}
export function* onDeleteSystemFromUser({ payload: systemId }) {
  yield put(actions.deleteSystemFromUserRequest.start())
  try {
    const userId = yield select(getUserId)
    yield call(api.deleteSystemFromUser, userId, systemId)

    yield put(actions.deleteSystemFromUserRequest.success({ userId, systemId }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.deleteSystemFromUserRequest.failure(err))
  }
}

export function* onUploadUserLogo({ payload: data }) {
  yield put(actions.uploadUserLogoRequest.start())

  try {
    const userId = yield select(getUserId)
    const { user } = yield call(api.uploadUserLogo, userId, data)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.uploadUserLogoRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.logoUploadedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.uploadUserLogoRequest.failure(err))
  }
}

export function* onDeleteUserLogo({ payload: data }) {
  yield put(actions.deleteUserLogoRequest.start())

  try {
    const userId = yield select(getUserId)
    yield call(api.deleteUserLogo, userId)

    yield put(actions.deleteUserLogoRequest.success({ userId }))
    yield put(snackbarActions.showSnackbar('snackbar.logoDeletedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.deleteUserLogoRequest.failure(err))
  }
}

export function* onSendUserOccurrence({ payload: data }) {
  yield put(actions.sendUserOccurrenceRequest.start())
  try {
    yield call(api.sendUserOccurrence, data)

    yield put(actions.sendUserOccurrenceRequest.success())
    yield put(snackbarActions.showSnackbar('snackbar.occurrenceSentSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.sendUserOccurrenceRequest.failure(err))
  }
}

export function* onConfirmUserNews({ payload: data }) {
  yield put(actions.confirmUserNewsRequest.start())
  try {
    const userId = yield select(getUserId)
    const { user } = yield call(api.confirmUserNews, userId)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.confirmUserNewsRequest.success(norm))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.confirmUserNewsRequest.failure(err))
  }
}

export function* onConfirmRecipient({ payload: data }) {
  yield put(actions.confirmRecipientRequest.start())
  try {
    yield call(api.confirmRecipient, data.email, data.userId)

    yield put(actions.confirmRecipientRequest.success())
  } catch (err) {
    yield put(actions.confirmRecipientRequest.failure(err))
  }
}

export function* onConfirmCollaborator({ payload: data }) {
  yield put(actions.confirmCollaboratorRequest.start())
  try {
    yield call(api.confirmCollaborator, data)

    yield put(actions.confirmCollaboratorRequest.success())
  } catch (err) {
    yield put(actions.confirmCollaboratorRequest.failure(err))
  }
}

export function* onAddAlertRecipient({ payload: data }) {
  yield put(actions.addAlertRecipientRequest.start())
  try {
    const { user } = yield call(api.addAlertRecipient, data.userId, data.email)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.addAlertRecipientRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.addedSuccessful'))
  } catch (err) {
    yield put(actions.addAlertRecipientRequest.failure(err))
  }
}

export function* onDeleteAlertRecipient({ payload: data }) {
  yield put(actions.deleteAlertRecipientRequest.start())
  try {
    const { user } = yield call(api.deleteAlertRecipient, data.userId, data.email)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.deleteAlertRecipientRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
  } catch (err) {
    yield put(actions.deleteAlertRecipientRequest.failure(err))
  }
}

export function* onDeleteAccount({ payload: data }) {
  yield put(actions.deleteAccountRequest.start())
  try {
    yield call(api.deleteAccount, data._id, data)
    yield put(actions.deleteAccountRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
    yield put(systemsActions.pollSystemsStop())
    yield call(clearAuthStoreData)
    yield call(clearTokenHeader)
    browserHistory.push(Routes.LOGIN)
  } catch (err) {
    yield put(actions.deleteAccountRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export function* onDeleteCollaborativeAccount({ payload: data }) {
  yield put(actions.deleteCollaborativeAccountRequest.start())
  try {
    const { user } = yield call(api.deleteCollaborativeAccount, data.userId, data.email)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.deleteCollaborativeAccountRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    yield put(actions.deleteCollaborativeAccountRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export function* onDeleteCollaborativeAccess({ payload: data }) {
  yield put(actions.deleteCollaborativeAccessRequest.start())
  try {
    const redirect = route => {
      browserHistory.push(route)
    }
    const { user } = yield call(api.deleteCollaborativeAccess, data.userId, data.mainEmail)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.deleteCollaborativeAccessRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
    yield put(modalActions.hideModal())
    location.reload(redirect(Routes.HOME))
  } catch (err) {
    // eslint-disable-next-line no-console
    yield put(actions.deleteCollaborativeAccessRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export function* onEditCollaborativeAccount({ payload: data }) {
  yield put(actions.editCollaborativeAccountRequest.start())

  try {
    const { user } = yield call(api.editCollaborativeAccount, data.userId, data)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.editCollaborativeAccountRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    yield put(actions.editCollaborativeAccountRequest.failure(err))
  }
}

function* onAddCollaborativeAccount({ payload: data }) {
  yield put(actions.addCollaborativeAccountRequest.start())
  try {
    const { user } = yield call(api.addCollaborativeAccount, data.userId, data)

    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.addCollaborativeAccountRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.addedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.addCollaborativeAccountRequest.failure(err))
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(constants.UPDATE_USER, onUpdateUser),
    takeLatest(constants.UPDATE_USER_PASSWORD, onUpdateCognitoUserPassword),
    takeLatest(constants.ADD_SYSTEM_TO_USER, onAddToUserSystem),
    takeLatest(constants.DELETE_SYSTEM_FROM_USER, onDeleteSystemFromUser),
    takeLatest(constants.UPLOAD_USER_LOGO, onUploadUserLogo),
    takeLatest(constants.DELETE_USER_LOGO, onDeleteUserLogo),
    takeLatest(constants.SEND_USER_OCCURRENCE, onSendUserOccurrence),
    takeLatest(constants.CONFIRM_USER_NEWS, onConfirmUserNews),
    takeLatest(constants.CONFIRM_RECIPIENT, onConfirmRecipient),
    takeLatest(constants.ADD_ALERT_RECIPIENT, onAddAlertRecipient),
    takeLatest(constants.TRANSFER_DEVICES_USER, onTransferDevicesUser),
    takeLatest(constants.DELETE_USER_DEVICES, onDeleteUserDevice),
    takeLatest(constants.DELETE_ALERT_RECIPIENT, onDeleteAlertRecipient),
    takeLatest(constants.CONFIRM_TRANSFER_DEVICE, onConfirmTransferDevice),
    takeLatest(constants.DELETE_ACCOUNT, onDeleteAccount),
    takeLatest(constants.DELETE_COLLABORATIVE_ACCOUNT, onDeleteCollaborativeAccount),
    takeLatest(constants.DELETE_COLLABORATIVE_ACCESS, onDeleteCollaborativeAccess),
    takeLatest(constants.ADD_COLLABORATIVE_ACCOUNT, onAddCollaborativeAccount),
    takeLatest(constants.EDIT_COLLABORATIVE_ACCOUNT, onEditCollaborativeAccount),
    takeLatest(constants.CONFIRM_COLLABORATOR, onConfirmCollaborator)
  ])
}
