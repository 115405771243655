import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { Translate, withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { conformToMask } from 'react-text-mask'
import { browserHistory } from 'react-router'

import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { Button, TextField, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import * as Routes from 'constants/Routes'

import Select from 'common/components/Select'
import styles from './EditSystemModalForm.module.scss'
import Languages from 'constants/Languages'
import * as BuildingFormJson from 'constants/building'
import countries from 'constants/countries'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '12px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  }
}))

const EditSystemModalForm = props => {
  const classes = useStyles()

  const { isSubmitting, initialValues, activeLanguage, translate, onSubmit } = props
  const { building, macAddress, name, description, country, zipCode, _id } = initialValues

  const [macAddressField, setMacAddress] = useState()
  const [nameField, setNameField] = useState(name)
  const [descriptionField, setDescriptionField] = useState(
    description === undefined ? '' : description
  )
  const [zipCodeField, setZipCode] = useState(zipCode)

  const [activeTab, setActiveTab] = useState(0)
  const [useTypeState, setUseTypeState] = useState(building?.useType)
  const [openInfo, setOpenInfo] = useState({})
  const [infoTab, setInfoTab] = useState('')

  const { handleSubmit, register, errors, control, setValue, getValues, setError, clearErrors } =
    useForm()

  const [typeBuildingOptionsSelect, setTypeBuildingOptionsSelect] = useState()
  const [typeOccupancyOptions, setTypeOccupancyOptions] = useState()
  const [typeVentilationOptions, setTypeVentilationOptions] = useState()

  const redirect = route => {
    browserHistory.push(route)
  }

  const mask = [
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    '-',
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    '-',
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/
  ]

  const lang = Languages.find(l => l.code === activeLanguage.code).code

  const countryOptions = countries.map(c => ({
    label: c[lang],
    value: c.code
  }))

  const zipCodeFormatted = value => {
    if (!value) return ''

    value = value.replace(/[^\w]/g, '')

    return value
  }

  const macAddressInitial = conformToMask(macAddress, mask, {
    guide: false
  }).conformedValue

  const handleonChange = event => {
    const macAddressParse = conformToMask(event.target.value, mask, {
      guide: false
    })
    const macAddressValue = macAddressParse.conformedValue
    setMacAddress(macAddressValue)
  }

  const handleZipCode = event => {
    const valueZip = zipCodeFormatted(event.target.value)
    setZipCode(valueZip)
  }

  const handleNameField = event => {
    setNameField(event.target.value)
  }

  const handleDescriptionField = event => {
    setDescriptionField(event.target.value)
  }

  const handleDeleteClick = id => {
    props.deleteSystem(
      id,
      () => {
        redirect(Routes.HOME)
      },
      name
    )
  }

  const typeBuildingOptions = BuildingFormJson.typeOfBuilding.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const antiquityOptions = BuildingFormJson.antiquity.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const airtightnessOptions = BuildingFormJson.airTightness.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const occupancyResidentialOptions = BuildingFormJson.occupancyResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const occupancyTertiaryOptions = BuildingFormJson.occupancyTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const typeBuildingTertiaryOptions = BuildingFormJson.typeBuildingTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const typeBuildingResidentialOptions = BuildingFormJson.typeBuildingResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  // const typeOfSystemOptions = BuildingFormJson.typeOfSystem.map(c => ({
  //   label: c[lang],
  //   value: c.value
  // }))

  const ventilationResidentialOptions = BuildingFormJson.ventilationResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const ventilationTertiaryOptions = BuildingFormJson.ventilationTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  let initialUseType,
    initialBuildingType,
    initialVentilation,
    initialOccupancy,
    initialAntiquity,
    initialAirThightness
  // initialTypeSystem

  if (building && building.useType) {
    initialUseType = BuildingFormJson.typeOfBuilding.find(c => c.value === building.useType)
    initialUseType = {
      label: initialUseType[lang],
      value: initialUseType.value
    }
  }

  if (building && building.buildingType) {
    initialBuildingType = BuildingFormJson.typeBuildingResidential.find(
      c => c.value === building.buildingType
    )
    if (!initialBuildingType)
      initialBuildingType = BuildingFormJson.typeBuildingTertiary.find(
        c => c.value === building.buildingType
      )
    initialBuildingType = {
      label: initialBuildingType[lang],
      value: initialBuildingType.value
    }
  }

  if (building && building.occupation) {
    initialOccupancy = BuildingFormJson.occupancyResidential.find(
      c => c.value === building.occupation
    )
    if (!initialOccupancy)
      initialOccupancy = BuildingFormJson.occupancyTertiary.find(
        c => c.value === building.occupation
      )
    initialOccupancy = {
      label: initialOccupancy[lang],
      value: initialOccupancy.value
    }
  }

  if (building && building.ventilation) {
    initialVentilation = BuildingFormJson.ventilationResidential.find(
      c => c.value === building.ventilation
    )
    if (!initialVentilation)
      initialVentilation = BuildingFormJson.ventilationTertiary.find(
        c => c.value === building.ventilation
      )
    initialVentilation = {
      label: initialVentilation[lang],
      value: initialVentilation.value
    }
  }

  if (building && building.antiquity) {
    initialAntiquity = BuildingFormJson.antiquity.find(c => c.value === building.antiquity)
    initialAntiquity = {
      label: initialAntiquity[lang],
      value: initialAntiquity.value
    }
  }

  if (building && building.airTightness) {
    initialAirThightness = BuildingFormJson.airTightness.find(
      c => c.value === building.airTightness
    )
    initialAirThightness = {
      label: initialAirThightness[lang],
      value: initialAirThightness.value
    }
  }

  // if (building && building.micaType) {
  //   initialTypeSystem = BuildingFormJson.typeOfSystem.find(c => c.value === building.micaType)
  //   initialTypeSystem = { label: initialTypeSystem[lang], value: initialTypeSystem.value }
  // }

  let initialCountry

  if (country) {
    initialCountry = countries.find(e => e.code === country)
    initialCountry = { label: initialCountry[lang], value: initialCountry.code }
  }

  const handleUseTypeChange = event => {
    setUseTypeState(event.value)
    if (event.value === 'Residential') {
      setTypeBuildingOptionsSelect(typeBuildingResidentialOptions)
      setTypeVentilationOptions(ventilationResidentialOptions)
      setTypeOccupancyOptions(occupancyResidentialOptions)
    } else {
      setTypeBuildingOptionsSelect(typeBuildingTertiaryOptions)
      setTypeVentilationOptions(ventilationTertiaryOptions)
      setTypeOccupancyOptions(occupancyTertiaryOptions)
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }

  const onClickNext = () => {
    if (
      !(getValues('macAddress') === '') &&
      !(getValues('name') === '') &&
      !(getValues('zipCode') === '') &&
      !(getValues('country') === undefined) &&
      !(getValues('macAddress').length !== 14)
    ) {
      setActiveTab(1)
    } else {
      // set errors
      if (getValues('country') === undefined) {
        setError('country', { shouldFocus: 'false' })
      }
      if (getValues('zipCode') === '') {
        setError('zipCode', { shouldFocus: 'false' })
      }
      if (getValues('name') === '') {
        setError('name', { shouldFocus: 'false' })
      }
      if (getValues('macAddress') === '') {
        setError('macAddress', { shouldFocus: 'false' })
      }
      if (getValues('macAddress').length !== 14) {
        setError('macAddress', { shouldFocus: 'false' })
      }
      // erase errors
      if (!(getValues('country') === undefined)) {
        clearErrors('country')
      }
      if (!(getValues('zipCode') === '')) {
        clearErrors('zipCode')
      }
      if (!(getValues('name') === '')) {
        clearErrors('name')
      }
      if (getValues('macAddress').length === 14) {
        clearErrors('macAddress')
      }
    }
  }

  useEffect(() => {
    if (building && building.buildingType !== '' && !typeBuildingOptionsSelect) {
      if (building.buildingType === 'Residential') {
        setTypeBuildingOptionsSelect(typeBuildingResidentialOptions)
        setTypeVentilationOptions(ventilationResidentialOptions)
        setTypeOccupancyOptions(occupancyResidentialOptions)
      } else {
        setTypeBuildingOptionsSelect(typeBuildingTertiaryOptions)
        setTypeVentilationOptions(ventilationTertiaryOptions)
        setTypeOccupancyOptions(occupancyTertiaryOptions)
      }
    }
  }, [
    typeBuildingResidentialOptions,
    ventilationResidentialOptions,
    occupancyResidentialOptions,
    typeBuildingTertiaryOptions,
    ventilationTertiaryOptions,
    occupancyTertiaryOptions,
    typeBuildingOptionsSelect,
    useTypeState,
    building
  ])

  const onClickBack = () => {
    setActiveTab(0)
    // if you are coming back the validation is already
    clearErrors('macAddress')
    clearErrors('name')
    clearErrors('zipCode')
    clearErrors('country')
  }
  const onClickAdd = () => {
    setActiveTab(1)
  }

  const handleInfo = keyInfo => {
    const openDataType = openInfo[keyInfo]
    setOpenInfo({ ...openInfo, [keyInfo]: !openDataType })
    if (infoTab === keyInfo) {
      setInfoTab('')
    } else {
      setInfoTab(keyInfo)
    }
  }

  const closeInfo = keyInfo => {
    setOpenInfo({ ...openInfo, [keyInfo]: false })
    setInfoTab('')
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('editDevice')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={styles.firstPage}
              style={{ display: activeTab === 0 ? 'block' : 'none' }}
            >
              <div className={styles.nav}>
                <Typography className={styles.subheaderDeviceSelected}>
                  {translate('navDevice')}
                </Typography>
                <Typography className={styles.subheaderEnviroment}>
                  {translate('navEnviroment')}
                </Typography>
              </div>
              <TextField
                inputRef={register({ required: true, minLength: 14 })}
                variant='outlined'
                required
                setFocus
                autoFocus
                shouldFocusOnError
                fullWidth
                disabled
                defaultValue={macAddressInitial}
                id='macAddress'
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                label={translate('sensorId')}
                value={macAddressField}
                onChange={handleonChange}
                placeholder='XXXX-XXXX-XXXX'
                inputProps={{ maxLength: 14 }}
                name='macAddress'
                autoComplete='macAddress'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.macAddress
                })}
              />
              {errors.macAddress && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              {errors.macAddress?.type === 'minLength' && (
                <div className={styles.error}>
                  <small>{translate('validation.invalidFormat')}</small>
                </div>
              )}
              <TextField
                variant='outlined'
                required
                fullWidth
                id='name'
                defaultValue={name}
                label={translate('systemName')}
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                value={nameField}
                onChange={handleNameField}
                helperText={`${nameField.length}/36`}
                inputProps={{ maxLength: 36 }}
                inputRef={register({ required: true })}
                name='name'
                autoComplete='name'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.name
                })}
              />
              {errors.name && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              <TextField
                variant='outlined'
                label={translate('systemDescription')}
                inputRef={register()}
                fullWidth
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                multiline
                expands='true'
                inputProps={{ maxLength: 160 }}
                id='description'
                name='description'
                autoComplete='description'
                value={descriptionField}
                defaultValue={description}
                onChange={handleDescriptionField}
                helperText={`${descriptionField.length}/160`}
                className={cx(styles.inputDescription, classes.input)}
              />
              <TextField
                variant='outlined'
                required
                inputRef={register({ required: true })}
                fullWidth
                id='zipCode'
                onChange={handleZipCode}
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                inputProps={{ maxLength: 10 }}
                value={zipCodeField}
                helperText={`${zipCodeField.length}/10`}
                defaultValue={zipCode}
                placeholder='XXXXX'
                label={translate('zipCode')}
                name='zipCode'
                autoComplete='zipCode'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.zipCode
                })}
              />
              {errors.zipCode && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              <div className={styles.cselect}>
                <Controller
                  id='country'
                  name='country'
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        required
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping country'
                        options={countryOptions}
                        placeholder={translate('country')}
                        label={translate('country')}
                        defaultValue={initialCountry}
                        error={errors.country}
                        inputRef={register({ required: true })}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.country
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={country && initialCountry.value}
                  inputRef={register({ required: true })}
                  rules={{ required: true }}
                />
                {errors.country && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <Typography className={styles.helper}>
                  {translate('systemPage.countryHelper')}
                </Typography>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonSiguiente}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickNext}
                >
                  {translate('next')}
                  <ArrowForward className={styles.arrow} />
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonDeleteDevice}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={() => handleDeleteClick(_id)}
                >
                  {translate('deleteSystem')}
                </Button>
              </div>
            </div>
            <div
              className={styles.secondPage}
              style={{ display: activeTab === 1 ? 'block' : 'none' }}
            >
              <div className={styles.nav}>
                <Typography className={styles.subheaderDevice}>{translate('navDevice')}</Typography>
                <Typography className={styles.subheaderEnviromentSelected}>
                  {translate('navEnviroment')}
                </Typography>
              </div>
              <div className={styles.infoPublicWrapper}>
                <Typography className={styles.buildingTypeInfoLabel}>
                  {translate('buildingTypeInfo')}
                </Typography>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        label={translate('systemPage.typeOfUseBuilding')}
                        handleChange={e => {
                          handleUseTypeChange(e)
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping useType'
                        error={errors.useType}
                        options={typeBuildingOptions}
                        defaultValue={initialUseType}
                        placeholder={translate('systemPage.typeOfUseBuilding')}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.useType
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  name='building.useType'
                  defaultValue={initialUseType ? initialUseType.value : null}
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('typeOfUseBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'typeOfUseBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('typeOfUseBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='typeOfUseBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping buildingType'
                        options={typeBuildingOptionsSelect}
                        placeholder={translate('systemPage.typeBuilding')}
                        disabled={!useTypeState}
                        error={errors.buildingType}
                        defaultValue={initialBuildingType}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.building
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  name='building.buildingType'
                  defaultValue={initialBuildingType ? initialBuildingType.value : null}
                />
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        onChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping ventilation'
                        options={typeVentilationOptions}
                        placeholder={translate('systemPage.ventilationBuilding')}
                        error={errors.ventilation}
                        disabled={!useTypeState}
                        defaultValue={initialVentilation}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.ventilation
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialVentilation ? initialVentilation.value : null}
                  name='building.ventilation'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('ventilationBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'ventilationBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('ventilationBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='ventilationBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping occupation'
                        options={typeOccupancyOptions}
                        disabled={!useTypeState}
                        placeholder={translate('systemPage.occupancyBuilding')}
                        error={errors.occupation}
                        defaultValue={initialOccupancy}
                        className={cx(styles.selector, {
                          [styles.hasError]: errors.occupation
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialOccupancy ? initialOccupancy.value : null}
                  name='building.occupation'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('occupancyBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'occupancyBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('occupancyBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='occupancyBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping antiquity'
                        options={antiquityOptions}
                        placeholder={translate('systemPage.antiquityBuilding')}
                        error={errors.antiquity}
                        defaultValue={initialAntiquity}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.building
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialAntiquity ? initialAntiquity.value : null}
                  name='building.antiquity'
                />
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping airTightness'
                        options={airtightnessOptions}
                        disabled={
                          !useTypeState &&
                          !typeBuildingOptionsSelect &&
                          !typeVentilationOptions &&
                          !typeOccupancyOptions &&
                          !antiquityOptions
                        }
                        placeholder={translate('systemPage.airTightnessLevelBuilding')}
                        error={errors.airTightness}
                        defaultValue={initialAirThightness}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.airTightness
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialAirThightness ? initialAirThightness.value : null}
                  name='building.airTightness'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('airTightnessLevelBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'airTightnessLevelBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('airTightnessLevelBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='airTightnessLevelBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={styles.buttonAdd}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={onClickAdd}
              >
                {translate('saveChanges')}
              </Button>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickBack}
                >
                  <ArrowBack className={styles.arrow} />
                  {translate('back')}
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonDeleteDevice}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={() => handleDeleteClick(_id)}
                >
                  {translate('deleteSystem')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditSystemModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object
}

export default withLocalize(EditSystemModalForm)
