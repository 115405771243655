import { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import localeSpanish from 'date-fns/locale/es'
import localeEnglish from 'date-fns/locale/en-US'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  createMuiTheme,
  TextField,
  RadioGroup,
  Radio,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  ListItem,
  FormControlLabel,
  Collapse,
  ListItemText
} from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import { ThemeProvider } from '@material-ui/styles'

import styles from './DownloadWellDataReportForm.module.scss'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-1px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  },
  MuiTimePicker: {
    styleOverrides: {
      root: {
        backgroundColor: blue[600]
      }
    }
  }
}))

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        textDecoration: 'line-through'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          borderColor: 'transparent',
          outline: 'none'
        },
        '&:focus': {
          borderColor: 'transparent',
          outline: 'none'
        }
      }
    }
  }
})

const DownloadWellDataReportForm = props => {
  const { onSubmit, translate, systems, systemsByGroup, activeLanguage } = props

  let { formState, handleSubmit, register, errors, control, getValues, setError, clearErrors } =
    useForm()

  const { isSubmitting } = formState
  const classes = useStyles()

  const [open, setOpen] = useState({})
  const [selectedDeviceField, setSelectedDeviceField] = useState()
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)

  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})

  const handleChange = event => {
    if (event.target.type === 'radio') {
      clearErrors('selectedDevices')
    }
  }

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleChangeSelectedDevice = event => {
    setSelectedDeviceField(event.target.value)
    clearErrors('selectedDevice')
  }

  const onClickNext = () => {
    if (activeTab === 0) {
      if (
        getValues('selectedDevices') !== null &&
        getValues('selectedDevices') !== undefined &&
        getValues('selectedDevices') !== ''
      ) {
        setActiveTab(1)
      }

      if (
        getValues('selectedDevices') === null ||
        getValues('selectedDevices') === undefined ||
        getValues('selectedDevices') === ''
      ) {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    }
  }

  const checkInputs = () => {
    if (moment(getValues('endDate')) < moment(getValues('startDate'))) {
      setError('endDate', { type: 'validate', message: 'Invalid date' })
      return
    }
    let data = {}
    data.deviceId = selectedDeviceField
    data.endDate = getValues('endDate')
    data.startDate = getValues('startDate')
    setDataState(data)
  }

  const onClickDownload = () => {
    checkInputs()
  }

  const onClickBack = () => {
    setActiveTab(0)
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }

  let locale
  if (activeLanguage.code === 'es') locale = localeSpanish
  if (activeLanguage.code === 'en') locale = localeEnglish

  const onChangeDates = () => {
    if (moment(getValues('endDate')) > moment(getValues('startDate'))) {
      if (errors.endDate.message === 'Invalid date') clearErrors('endDate')
    }
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('downloadWellDataReportModal.downloadReport')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('downloadWellDataReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderDates}>
                    {translate('downloadWellDataReportModal.navDates')}
                  </Typography>
                </div>
                <div>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='search'
                    onChange={filterExpressionChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    id='searchDevice'
                    name='searchDevice'
                    placeholder={translate('searchDevice')}
                    className={cx(styles.input, classes.input, {
                      [styles.hasError]: errors.reportName
                    })}
                  />
                  <Paper
                    className={styles.devicesListPaper}
                    classes={{ root: classes.paper }}
                  >
                    <List
                      disableTouchRipple
                      className={styles.devicesList}
                    >
                      <FormControl component='fieldset'>
                        <RadioGroup onChange={handleChange}>
                          {_.map(systemsGroupState, function (systems, group) {
                            systems = _.groupBy(systems, 'micaType')
                            return (
                              <div className={styles.noGroupWrapper}>
                                {group !== 'undefined' && (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.groupListItem}
                                    >
                                      {!open[group] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(group)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(group)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={group}
                                        className={styles.listItemText}
                                      />
                                    </ListItem>
                                  </div>
                                )}
                                {group === 'undefined' && (
                                  /* Desplegamos Por Tipos, micas sin Grupo definido */
                                  <div>
                                    {_.map(systems, function (systems, type) {
                                      return (
                                        <div className={styles.typeRowWrapper}>
                                          <ListItem
                                            button
                                            disableTouchRippleindex
                                            className={styles.typeListItem}
                                          >
                                            {!open[type] ? (
                                              <ExpandLess
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            ) : (
                                              <ExpandMore
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            )}
                                            <ListItemText
                                              primary={translate(type)}
                                              className={styles.micaTypeItemText}
                                            />
                                          </ListItem>
                                          <Collapse
                                            in={!open[type]}
                                            timeout='auto'
                                            unmountOnExit
                                          >
                                            {systems.map((system, index) => {
                                              return (
                                                <div
                                                  className={styles.micaItem}
                                                  key={system._id}
                                                >
                                                  <FormControlLabel
                                                    onKeyPress={e => {
                                                      e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    className={styles.checkboxForm}
                                                    value={system._id}
                                                    onChange={handleChangeSelectedDevice}
                                                    control={
                                                      <Radio
                                                        className={cx(
                                                          styles.checkbox,
                                                          styles.radioItem
                                                        )}
                                                        classes={{ checked: styles.checkedBox }}
                                                        inputRef={register()}
                                                        disableRipple
                                                        checked={system._id === selectedDeviceField}
                                                        name={'selectedDevices'}
                                                        value={system._id}
                                                      />
                                                    }
                                                    label={system.name}
                                                  />
                                                </div>
                                              )
                                            })}
                                          </Collapse>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                                {group !== 'undefined' && (
                                  <Collapse
                                    in={!open[group]}
                                    timeout='auto'
                                    unmountOnExit
                                    classes={{
                                      wrapper: classes.wrapper
                                    }}
                                  >
                                    {_.map(systems, function (systems, type) {
                                      return (
                                        <div className={styles.typeRowWrapper}>
                                          <ListItem
                                            button
                                            disableTouchRipple
                                            className={styles.typeListItem}
                                          >
                                            {!open[type] ? (
                                              <ExpandLess
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            ) : (
                                              <ExpandMore
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            )}
                                            <ListItemText
                                              primary={type}
                                              className={styles.micaTypeItemText}
                                            />
                                          </ListItem>
                                          <Collapse
                                            in={!open[type]}
                                            timeout='auto'
                                            unmountOnExit
                                          >
                                            {systems.map((system, index) => {
                                              return (
                                                <div
                                                  className={styles.micaItem}
                                                  key={system._id}
                                                >
                                                  <FormControlLabel
                                                    onKeyPress={e => {
                                                      e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    className={styles.checkboxForm}
                                                    value={system._id}
                                                    onChange={handleChangeSelectedDevice}
                                                    control={
                                                      <Radio
                                                        className={cx(
                                                          styles.checkbox,
                                                          styles.radioItem
                                                        )}
                                                        classes={{ checked: styles.checkedBox }}
                                                        inputRef={register()}
                                                        disableRipple
                                                        error={errors.selectedDevices}
                                                        checked={system._id === selectedDeviceField}
                                                        name={'selectedDevices'}
                                                        value={system._id}
                                                      />
                                                    }
                                                    label={system.name}
                                                  />
                                                </div>
                                              )
                                            })}
                                          </Collapse>
                                        </div>
                                      )
                                    })}
                                  </Collapse>
                                )}
                              </div>
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                    </List>
                  </Paper>
                  <div className={styles.wrapperError}>
                    {errors.selectedDevices && (
                      <div className={styles.error}>
                        <small>{translate('validation.anyDeviceRequired')}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={onClickNext}
                  >
                    {translate('addReportModal.next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('addReportModal.cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div className={styles.secondPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevices}>
                    {translate('downloadWellDataReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderDatesSelected}>
                    {translate('downloadWellDataReportModal.navDates')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadWellDataReportModal.selectDateInterval')}
                  </Typography>
                </div>
                <div className={styles.datePickerWrapper}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                  >
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('startDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              variant='inline'
                              margin='0'
                              inputRef={register()}
                              autoOk
                              name='startDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.startDate
                              })}
                              format='dd/MM/yyyy'
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              onChange={onChangeDates}
                            />
                          }
                          control={control}
                          name='startDate'
                          defaultValue={moment}
                          rules={{ required: true }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.startDate && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('endDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              variant='inline'
                              inputRef={register()}
                              format='dd/MM/yyyy'
                              autoOk
                              margin='0'
                              name='endDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.endDate
                              })}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              onChange={onChangeDates}
                            />
                          }
                          control={control}
                          name='endDate'
                          defaultValue={moment}
                          rules={{ required: true, validate: checkInputs }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.endDate && errors.endDate?.type === 'required' && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    {errors.endDate &&
                      errors.endDate?.type === 'validate' &&
                      errors.endDate?.message === 'Invalid date' && (
                        <div className={styles.error}>
                          <small>{translate('validation.invalidDateRange')}</small>
                        </div>
                      )}
                    {errors.endDate &&
                      errors.endDate?.type === 'validate' &&
                      errors.endDate?.message === 'Invalid range' && (
                        <div className={styles.error}>
                          <small>{translate('validation.invalidDateInterval')}</small>
                        </div>
                      )}
                  </MuiPickersUtilsProvider>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickDownload}
                  >
                    {translate('downloadData')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadWellDataReportForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadWellDataReportForm)
