import styles from './SupportButton.module.scss'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import { Fab } from '@mui/material'
import { connect } from 'react-redux'
import { getUser, getUserLang } from '../../selectors/user'
import { getActiveLanguage, getLanguages, getTranslate, withLocalize } from 'react-localize-redux'
import { hideModal, showModal } from '../../actions/modal'
import * as ModalTypes from '../../../constants/ModalTypes'

const SupportButton = props => {
  const { showModal, user } = props
  const { collaborativeInformation } = user.toJS()

  const handleSupport = () => {
    showModal({
      type: ModalTypes.SUPPORT_MODAL
    })
  }

  if (collaborativeInformation && collaborativeInformation.length > 0) {
    return null
  }

  return (
    <Fab
      className={styles.floatingButton}
      disableTouchRipple
      disableFocusRipple
      size='medium'
      onClick={handleSupport}
    >
      <ContactSupportOutlinedIcon />
    </Fab>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  showModal,
  hideModal
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(SupportButton))
