import { useState } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { getActiveLanguage, getLanguages, getTranslate, withLocalize } from 'react-localize-redux'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import { Button, Fade, Menu, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'

import * as Routes from 'constants/Routes'
import styles from './Footer.module.scss'
import { getUser, getUserLang } from 'common/selectors/user'
import { setActiveLanguage } from 'common/actions/localize'
import { setActiveMeasurementSystem } from 'common/actions/measurementSystem'

const Footer = props => {
  const { translate } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuLanguageOpen = event => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleLanguageClick = option => {
    props.setActiveLanguage(option.toLowerCase())
    setAnchorEl(null)
  }

  const redirect = route => {
    browserHistory.push(route)
  }

  const handlePolicyLink = () => {
    redirect(Routes.POLICY)
  }

  const handleTermsLink = () => {
    redirect(Routes.TERMS)
  }

  const languageDropDown = () => {
    const { languages, activeLanguage, userLanguage } = props
    const lang = userLanguage ? userLanguage.toUpperCase() : activeLanguage.name

    const dropDownItems = languages

    const StyledMenu = withStyles(styles.menuLanguagePaper)(props => (
      <Menu
        id='menu-language'
        {...props}
        classes={{
          paper: styles.menuLanguagePaper,
          list: styles.menuLanguageContent
        }}
      />
    ))

    return (
      <div>
        <Button
          disableRipple
          aria-controls='fade-menu'
          aria-haspopup='true'
          onClick={handleMenuLanguageOpen}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <LanguageOutlinedIcon style={{ width: '18px' }} />
          <Typography className={styles.languageTitle}>{translate(lang.toString())}</Typography>
          {Boolean(anchorEl) && (
            <KeyboardArrowDownIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
          {!anchorEl && (
            <KeyboardArrowUpIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          className={styles.languageMenu}
        >
          {dropDownItems.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleLanguageClick(option.name)}
              className={styles.menuItem}
            >
              <div className={styles.languageSelectedIcon}>
                {lang === option.name && <CheckOutlinedIcon style={{ width: '20px' }} />}
              </div>
              {translate(option.name.toString())}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    )
  }

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <div className={styles.languageDropdown}>{languageDropDown()}</div>
        <hr className={styles.footerSeparator} />
        <div className={styles.footerContent}>
          <div className={styles.contact}>
            <div className={styles.linkWrapper}>
              <a
                className={styles.link}
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.inbiot.es/'
              >
                {translate('discoverInbiot')}
              </a>
            </div>
            <div>
              <a
                className={styles.link}
                rel='noopener'
                onClick={ev => {
                  ev.preventDefault()
                  handleTermsLink()
                }}
                href='/#'
              >
                {translate('acceptPolicyandTerms.terms')}
              </a>
            </div>
            <div>
              <a
                className={styles.link}
                rel='noopener'
                onClick={ev => {
                  ev.preventDefault()
                  handlePolicyLink()
                }}
                href='/#'
              >
                {translate('acceptPolicyandTerms.policy')}
              </a>
            </div>
          </div>
          <div className={styles.copyright}>
            <p className={styles.copyrightText}>{translate('copyright')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  setActiveLanguage,
  setActiveMeasurementSystem
}

export default connect(mapStateToProps, mapActionsToProps)(withLocalize(Footer))
