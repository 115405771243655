import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import styles from './SupportModal.module.scss'
import { makeStyles } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../../common/selectors/user'
import { sendUserOccurrence } from '../../../../common/actions/user'
import cx from 'classnames'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { blue } from '@material-ui/core/colors'
import { Autocomplete, Box } from '@mui/material'
import { getSystemsRaw } from '../../../../common/selectors/systems'
import { conformToMask } from 'react-text-mask'
import { getError, getIsSubmitting } from '../selectors'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 54,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  descriptionInput: {
    [`& fieldset`]: {
      borderRadius: 23,
      borderColor: 'grey',
      minHeight: 205
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  inputRoot: {
    color: 'blue',
    fontFamily: 'Roboto Mono',
    backgroundColor: '#f2f2f2',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'blue'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'blue'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'blue'
    },
    '& .MuiAutocomplete-endAdornment': {
      position: 'relative',
      order: 2
    }
  },
  endAdornment: {
    '& > div.MuiAutocomplete-endAdornment': {
      position: 'relative', // default was absolute. we make it relative so that it is now within the flow of the other two elements
      order: 2
    }
  }
}))

const SupportModal = props => {
  const classes = useStyles()
  const { hideModal, translate, user, systems, sendUserOccurrence, isSubmitting } = props

  const { handleSubmit, register, errors } = useForm()

  const [open] = useState(true)
  const [devicesList, setDevicesList] = useState([])
  const [devicesAuxList, setDevicesAuxList] = useState([])
  const [deviceIdField, setDeviceId] = useState('')

  useEffect(() => {
    const systemJS = systems.toJS()
    const devices = []
    for (let i = 0; i < Object.keys(systemJS).length; i++) {
      devices.push({
        name: systemJS[Object.keys(systemJS)[i]].name,
        id: systemJS[Object.keys(systemJS)[i]].macAddress
      })
    }
    setDevicesList(devices)
    setDevicesAuxList(devices)
  }, [systems])

  const handleOnSubmit = data => {
    sendUserOccurrence(data)
  }

  const mask = [
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    '-',
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    '-',
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/,
    /[A-F0-9a-f]/
  ]

  const handleonChange = event => {
    if (event.target.value === '') {
      setDevicesAuxList(devicesList)
    } else {
      const macAddressParse = conformToMask(event.target.value.toUpperCase(), mask, {
        guide: false
      })
      const macAddressValue = macAddressParse.conformedValue
      setDeviceId(macAddressValue)
      const devicesFiltered = devicesList.filter(device => {
        if (device.id) {
          const conformedDeviceID = conformToMask(device.id, mask, {
            guide: false
          }).conformedValue
          return conformedDeviceID.indexOf(macAddressValue) !== -1
        } else {
          return false
        }
      })
      setDevicesAuxList(devicesFiltered)
    }
  }

  const handleonChangeOption = (event, value, reason) => {
    if (reason === 'clear') {
      setDeviceId('')
      setDevicesAuxList(devicesList)
    } else if (reason === 'selectOption') {
      const macAddressParse = conformToMask(value.id, mask, { guide: false })
      const macAddressValue = macAddressParse.conformedValue
      setDeviceId(macAddressValue)
    }
  }
  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
    >
      <div className={styles.modalContainer}>
        <div className={styles.header}>
          {translate('supportModal.title')}
          <IconButton
            disableRipple
            aria-label='close'
            className={styles.titleIconClose}
            onClick={hideModal}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.subtitle}>{translate('supportModal.subtitle')}</div>
        <div className={styles.content}>
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
          >
            <TextField
              variant='outlined'
              required
              fullWidth
              id='userContact'
              inputRef={register({ required: true })}
              label={translate('emailContact')}
              placeholder={translate('emailContact')}
              name='userContact'
              autoComplete='userContact'
              defaultValue={user.toJS().email}
              className={cx(styles.input, classes.input)}
            />
            {errors.email && (
              <div className={styles.error}>
                <small>{translate('validation.contactEmailRequired')}</small>
              </div>
            )}

            <TextField
              variant='outlined'
              fullWidth
              id='phoneContact'
              inputRef={register()}
              label={translate('phoneContact')}
              placeholder={translate('phoneContact')}
              name='phoneContact'
              autoComplete='phoneContact'
              className={cx(styles.input, classes.input)}
            />

            <Autocomplete
              id='autocomplete-devices'
              classes={classes.inputRoot}
              freeSolo
              disableClearable
              getOptionLabel={() => deviceIdField}
              onChange={handleonChangeOption}
              ListboxComponent={props => <div {...props} />}
              renderOption={(props, option, { selected }) => (
                <li
                  key={'li-' + option.id}
                  id={'li-' + option.id}
                  {...props}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: '#586069'
                      }
                    }}
                    key={'box-' + option.id}
                    component='li'
                    id={'box-li-' + option.id}
                  >
                    {conformToMask(option.id, mask, { guide: false }).conformedValue}
                    <br />
                    <span>{option.name}</span>
                  </Box>
                </li>
              )}
              options={devicesAuxList}
              renderInput={params => (
                <TextField
                  {...params}
                  label={translate('sensorId')}
                  variant='outlined'
                  inputRef={register({ required: true })}
                  name='deviceId'
                  onChange={handleonChange}
                  value={deviceIdField}
                  placeholder='XXXX-XXXX-XXXX'
                  required
                  classes={{
                    root: classes.endAdornment
                  }}
                  className={cx(styles.input, classes.input)}
                />
              )}
            />
            <div className={styles.infoPublicWrapper}>
              <Typography className={styles.sensorIdInfoLabel}>
                {translate('supportModal.sensorIdInfo')}
              </Typography>
            </div>

            {errors.deviceId && (
              <div className={styles.error}>
                <small>{translate('validation.deviceRequired')}</small>
              </div>
            )}

            <TextField
              variant='outlined'
              label={translate('supportModal.supportDescription')}
              inputRef={register({ required: true })}
              fullWidth
              onKeyPress={e => {
                e.key === 'Enter' && e.preventDefault()
              }}
              multiline
              rowsMax='8'
              expands='true'
              id='description'
              name='description'
              autoComplete='description'
              className={cx(styles.descriptionInput, classes.descriptionInput)}
            />

            {errors.description && (
              <div className={styles.error}>
                <small>{translate('validation.descriptionRequired')}</small>
              </div>
            )}

            <LoadingButton
              type='submit'
              fullWidth
              loading={isSubmitting}
              variant='contained'
              loadingPosition='end'
              className={styles.button}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {translate('supportModal.sendOccurrence')}
            </LoadingButton>
          </form>
        </div>
      </div>
    </Modal>
  )
}

SupportModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

const mapStateToProps = state => ({
  user: getUser(state),
  systems: getSystemsRaw(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  sendUserOccurrence
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(SupportModal))
