import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'
import * as schemas from 'schemas'

import {
  getEntities,
  getSystems as getSystemsEntities,
  getWeather as getWeatherEntities
} from './entities'

export const getSystemsRaw = createSelector(
  [getEntities, getSystemsEntities, getWeatherEntities],
  (entities, systems, weather) => {
    systems = systems.map(s => {
      const systemWeather = weather.get(s.get('zipCode'))
      if (systemWeather) {
        s.set('weather', systemWeather)
      }
      return s
    })
    return denormalize(systems, [schemas.system], entities)
  }
)

export const getSystemByIdRaw = id =>
  createSelector(
    [getEntities, getSystemsEntities, getWeatherEntities],
    (entities, systems, weather) => {
      const system = systems.find(s => s.get('_id') === id)
      if (!system) return null
      const systemWeather = weather.get(system.get('zipCode'))
      if (systemWeather) {
        system.set('weather', systemWeather)
      }
      return denormalize(system, schemas.system, entities)
    }
  )

export const getSystemById = id =>
  createSelector([getSystemsEntities], entities => entities.find(s => s.get('_id') === id))
