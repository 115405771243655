import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Checkbox, FormControlLabel, TextField } from '@material-ui/core'

import styles from './EditSystemPeriodicityForm.module.scss'
import { withLocalize } from 'react-localize-redux'
import { blue } from '@material-ui/core/colors'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import cx from 'classnames'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0,155,229,1)'
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgba(0,155,229,1)',
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-3px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  }
}))

const EditSystemPeriodicityForm = props => {
  const classes = useStyles()

  const [timeIntervalField, setTimeIntervalField] = useState(0)
  const [allDeviceSelected, setAllDevicesSelected] = useState(false)

  const { isSubmitting, initialValues, translate, onSubmit, submitError, user, system } = props

  const { handleSubmit, register, errors, setError, clearErrors } = useForm()

  const notAllowedTimeInterval = timeInterval => {
    if (!user.toJS().isPremium && timeInterval < 10 && timeInterval >= 1) {
      return 0
    }
    if (
      (system.model.includes('GSM') || system.model.includes('TSIM')) &&
      timeInterval < 10 &&
      timeInterval >= 1
    ) {
      return 1
    }
    if (timeInterval < 1) {
      return 2
    }
    if (timeInterval >= 60) {
      return 4
    }
    return 3
  }

  const handleChangeTimeInterval = event => {
    if (!isNaN(event.target.value)) {
      clearErrors('timeInterval')
      clearErrors('timeIntervalGsm')
      clearErrors('timeIntervalMinimum')
      setTimeIntervalField(event.target.value)
      if (notAllowedTimeInterval(event.target.value) === 0) {
        setError('timeInterval', { shouldFocus: true })
      } else if (notAllowedTimeInterval(event.target.value) === 1) {
        setError('timeIntervalGsm', { shouldFocus: true })
      } else if (notAllowedTimeInterval(event.target.value) === 2) {
        setError('timeIntervalMinimum', { shouldFocus: true })
      } else if (notAllowedTimeInterval(event.target.value) === 4) {
        setError('timeIntervalMaximum', { shouldFocus: true })
      }
    }
  }

  const handleSelectAllDevices = event => {
    setAllDevicesSelected(event.target.checked)
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const error = () => {
    const message = translate('submitErrors.unknown')

    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
        style={{ visibility: submitError ? 'visible' : 'hidden' }}
      >
        {message}
      </Alert>
    )
  }

  useEffect(() => {
    setTimeIntervalField(initialValues.timeInterval)
  }, [initialValues.timeInterval])

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('editDevicePeriodicity')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.secondPage}>
              <div className={styles.checkedLigthsWrapper}>
                <TextField
                  variant='outlined'
                  required
                  inputRef={register()}
                  fullWidth
                  id='timeInterval'
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  onChange={handleChangeTimeInterval}
                  onBlur={handleChangeTimeInterval}
                  value={timeIntervalField}
                  defaultValue={timeIntervalField}
                  inputProps={{ maxLength: 10 }}
                  label={translate('timeInterval')}
                  name='timeInterval'
                  autoComplete='timeInterval'
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.timeInterval
                  })}
                />
              </div>
              {errors.timeInterval && (
                <div className={styles.error}>
                  <small>{translate('validation.timeIntervalAllowed')}</small>
                </div>
              )}
              {errors.timeIntervalGsm && (
                <div className={styles.error}>
                  <small>{translate('validation.timeIntervalGsmInfo')}</small>
                </div>
              )}
              {errors.timeIntervalMinimum && (
                <div className={styles.error}>
                  <small>{translate('validation.timeIntervalMinimum')}</small>
                </div>
              )}
              {errors.timeIntervalMaximum && (
                <div className={styles.error}>
                  <small>{translate('validation.timeIntervalMaximum')}</small>
                </div>
              )}
              <div className={styles.infoPublicWrapper}>
                <div className={styles.iconPublic}>
                  <InfoOutlinedIcon />
                </div>
                <Typography className={styles.deviceLightsInfoLabel}>
                  {translate('timeIntervalInfo')}
                </Typography>
              </div>
              <div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      onChange={handleSelectAllDevices}
                      inputRef={register()}
                      isChecked={allDeviceSelected}
                    />
                  }
                  label={translate('applyToAllDevices')}
                />
              </div>
              <div className={styles.infoPublicWrapper}>
                <div className={styles.iconPublic}>
                  <InfoOutlinedIcon />
                </div>
                <Typography className={styles.deviceLightsInfoLabel}>
                  {translate('applyToAllDevicesInfo')}
                </Typography>
              </div>
              {error()}
              <div className={styles.buttons}>
                <div className={styles.infoUpdateWrapper}>
                  <div className={styles.iconPublic}>
                    <FeedbackOutlinedIcon />
                  </div>
                  <Typography className={styles.deviceLightsInfoLabel}>
                    {translate('deviceUpdateInfo')}
                  </Typography>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting || errors.timeInterval}
                    loading={isSubmitting}
                    isLoading={isSubmitting}
                    loadingPosition='end'
                  >
                    {translate('saveChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    disableRipple
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditSystemPeriodicityForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  // submitError: PropTypes.any,
  translate: PropTypes.func.isRequired
}

export default withLocalize(EditSystemPeriodicityForm)
