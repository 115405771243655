import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { push as pushHistory } from 'react-router-redux'
import { setActiveLanguage } from 'react-localize-redux'

import actions, { constants } from '../actions/auth'
import systemsActions from '../actions/systems'
import snackbarActions from 'common/actions/snackbar'
import * as schemas from 'schemas'
import * as api from 'api/auth'
import * as userApi from 'api/user'
import * as Routes from 'constants/Routes'
import { setTokenHeader, clearTokenHeader } from 'utils/request'
import { getAuthStoreData, setAuthStoreData, clearAuthStoreData } from 'utils/authStore'
import * as LocalizeStore from 'utils/localizeStore'
import { setUserMeasurementSystem } from 'utils/auxStore'

function* postAuth(routing) {
  if (routing.locationBeforeTransitions.pathname === Routes.MAIN) {
    return yield put(pushHistory(Routes.HOME))
  }
  yield put(pushHistory(routing.locationBeforeTransitions))
}

export function* onAuthUser() {
  yield put(actions.authUserRequest.start())
  const routing = yield select(state => state.routing)
  // Pages to ignore
  if (
    routing.locationBeforeTransitions.pathname.includes(Routes.CHANNEL) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.LOGIN) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.FULLSCREEN_LINK) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.FORGOTTEN_PASSWORD) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_USER) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.RECOVER_PASSWORD) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_RECIPIENT) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_COLLABORATOR) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.SIGN_UP_COLLABORATOR)
  ) {
    return yield put(actions.authUserRequest.failure(null))
  }

  const authStoreData = getAuthStoreData()

  if (!authStoreData) {
    yield put(actions.authUserRequest.failure())

    return yield call(postAuth, routing)
  }
  try {
    const session = yield call(api.getCognitoSession)
    setTokenHeader(authStoreData)
    const { user } = yield call(userApi.getCognitoUser, {
      username: session.getAccessToken().payload.username
    }) // Pedimos el usuario de la Dynamo
    setUserMeasurementSystem(user.measurementSystem || 'mS')
    yield put(setActiveLanguage(user.language))
    yield call(LocalizeStore.setAuthStoreData, user.language)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.authUserRequest.success(norm))
    yield call(postAuth, routing)
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.authUserRequest.failure(err))
    yield put(pushHistory(Routes.LOGIN))
    yield put(snackbarActions.showSnackbar('snackbar.expiredToken'))
  }
}

export function* onLoginCognito({ payload: data }) {
  yield put(actions.loginRequest.start())

  try {
    const cognitoUser = yield call(api.loginCognito, data)
    if (cognitoUser.username === undefined) {
      yield put(actions.loginRequest.failure(cognitoUser))
    } else {
      const session = yield call(api.getCognitoSession)

      yield call(setAuthStoreData, 'Bearer ' + session.getAccessToken().getJwtToken())
      yield call(setTokenHeader, 'Bearer ' + session.getAccessToken().getJwtToken())

      const { user } = yield call(userApi.getCognitoUser, {
        username: cognitoUser.username
      }) // Pedimos el usuario de la Dynamo
      setUserMeasurementSystem(user.measurementSystem || 'mS')
      yield put(setActiveLanguage(user.language))
      yield call(LocalizeStore.setAuthStoreData, user.language)
      const norm = yield call(normalize, user, schemas.user)

      yield put(actions.loginRequest.success(norm))
      yield put(pushHistory(Routes.HOME))
    }
  } catch (err) {
    yield put(actions.loginRequest.failure(err))
  }
}

export function* onSignUpCollaboratorCognito({ payload: data }) {
  yield put(actions.signUpCollaboratorRequest.start())

  try {
    const cognitoUser = yield call(api.signUpCognito, data)
    if (cognitoUser.user === undefined || cognitoUser.user.username === undefined) {
      // Error creando la cuenta
      yield put(actions.signUpCollaboratorRequest.failure(cognitoUser))
    } else {
      yield call(api.signUpCollaborator, data) // Si el registo en cognito ha sido correcto, lo metemos también en Dynamo DB
      yield put(actions.signUpCollaboratorRequest.success())

      yield put(snackbarActions.showSnackbar('snackbar.confirmSignUpCollaboratorSuccess'))

      yield put(
        pushHistory({
          pathname: Routes.CONFIRM_COLLABORATOR + '/' + data.email + '/' + data.mainEmail
        })
      )
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.signUpCollaboratorRequest.failure(err))
  }
}

export function* onSignUpCognito({ payload: data }) {
  yield put(actions.signUpRequest.start())

  try {
    const cognitoUser = yield call(api.signUpCognito, data)
    if (cognitoUser.user === undefined || cognitoUser.user.username === undefined) {
      // Error creando la cuenta
      yield put(actions.signUpRequest.failure(cognitoUser))
    } else {
      yield call(api.signUp, data) // Si el registo en cognito ha sido correcto, lo metemos también en Dynamo DB
      yield put(actions.signUpRequest.success())
      yield put(snackbarActions.showSnackbar('snackbar.confirmationCodeSent'))
      yield put(
        pushHistory({
          pathname: Routes.CONFIRM_USER_INFO,
          state: { username: cognitoUser.user.username }
        })
      )
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.signUpRequest.failure(err))
  }
}

export function* onConfirmUser({ payload: data }) {
  yield put(actions.confirmUserRequest.start())
  try {
    const confirmation = yield call(api.confirmSignUpCognito, data)
    if (confirmation.code !== undefined) {
      yield put(actions.confirmUserRequest.failure(confirmation))
    } else {
      yield put(actions.confirmUserRequest.success())
      yield put(snackbarActions.showSnackbar('snackbar.confirmSignUpSuccess'))
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.confirmUserRequest.failure(err))
  }
}

export function* onResendConfirmationMail({ payload: data }) {
  yield put(actions.resendConfirmationMailRequest.start())
  try {
    const mailSent = yield call(api.resendConfirmationMail, data.username)
    if (mailSent) {
      yield put(actions.resendConfirmationMailRequest.success())
      yield put(snackbarActions.showSnackbar('snackbar.resendConfirmationMailSuccess'))
    } else {
      yield put(actions.resendConfirmationMailRequest.failure(mailSent))
      yield put(snackbarActions.showSnackbar('snackbar.resendConfirmationMailFailure'))
    }
  } catch (err) {
    yield put(actions.resendConfirmationMailRequest.failure(err))
  }
}

export function* onLogoutCognito() {
  yield put(systemsActions.pollSystemsStop())
  yield call(api.logoutCognito)
  yield call(clearAuthStoreData)
  yield call(clearTokenHeader)
  yield put(pushHistory(Routes.LOGIN))
}

export default function* watchAuth() {
  yield all([
    takeLatest(constants.AUTH_USER, onAuthUser),
    takeLatest(constants.LOGIN, onLoginCognito),
    takeLatest(constants.SIGN_UP, onSignUpCognito),
    takeLatest(constants.SIGN_UP_COLLABORATOR, onSignUpCollaboratorCognito),
    takeLatest(constants.CONFIRM_USER, onConfirmUser),
    takeLatest(constants.RESEND_CONFIRMATION_MAIL, onResendConfirmationMail),
    takeLatest(constants.LOGOUT, onLogoutCognito)
  ])
}
