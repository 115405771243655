import { useState } from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './DownloadMultipleSettingsContainer.module.scss'

import DownloadMultipleSettingsForm from '../components/DownloadMultipleSettingsForm'
import { requestFile } from 'utils/request'
import { getAuthStoreData } from 'utils/authStore'

const DownloadMultipleSettingsContainer = props => {
  const [open] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { hideModal, user, systems } = props
  const { isPremium, _id } = user.toJS()

  const handleSubmit = async data => {
    if (props.isSubmitting) return

    setIsSubmitting(true)
    const startDate = new Date(data.startDate.toString())
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)

    const endDate = new Date(data.endDate.toString())
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const names = []
    const bases = []
    const zipcodes = []

    for (let i = 0; i < data.devices.length; i++) {
      const system = systems.filter(s => {
        return s._id === data.devices[i]
      })[0]
      names.push(system.name)
      if (bases.indexOf(system.baseStationLocation) === -1) {
        bases.push(system.baseStationLocation)
        zipcodes.push(system.zipCode)
      }
    }
    let submitData
    if (data.devices?.length > 1) {
      submitData = {
        params: data.params,
        devices: data.devices,
        order: Number(data.order),
        interval: data.interval,
        names,
        base: bases,
        zipcodes,
        tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        CustomerId: _id
      }

      if (!data.weather) delete submitData.base

      const authStoreData = getAuthStoreData()
      await requestFile(
        `systems/multiple/` + startDate.toISOString() + `/` + endDate.toISOString(),
        {
          method: 'POST',
          data: {
            ...submitData
          },
          headers: {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'content-type': 'application/json',
            Authorization: authStoreData
          }
        }
      ).then(async response => {
        const blob = await response.blob().then(blob => blob)
        const fileName = response.headers.get('filename')
        FileSaver.saveAs(blob, fileName)
      })
      hideModal()
    } else {
      submitData = {
        fileType: data.fileType,
        params: data.params,
        order: Number(data.order),
        interval: data.interval,
        micaname: names[0],
        base: bases[0],
        tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        CustomerId: _id
      }
      if (!data.weather) delete submitData.base
      if (data.fileType === 'xlsx') {
        const authStoreData = getAuthStoreData()
        await requestFile(
          `systems/` +
            data.devices[0] +
            `/` +
            startDate.toISOString() +
            `/` +
            endDate.toISOString(),
          {
            method: 'POST',
            data: {
              ...submitData
            },
            headers: {
              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'content-type': 'application/json',
              Authorization: authStoreData
            }
          }
        ).then(async response => {
          const blob = await response.blob().then(blob => blob)
          const fileName = response.headers.get('filename')
          FileSaver.saveAs(blob, fileName)
        })
      } else {
        await requestFile(
          `systems/` +
            data.devices[0] +
            `/` +
            startDate.toISOString() +
            `/` +
            endDate.toISOString(),
          {
            method: 'POST',
            data: {
              ...submitData
            }
          }
        ).then(async response => {
          const blob = await response.blob().then(blob => blob)
          const fileName = response.headers.get('filename')
          FileSaver.saveAs(blob, fileName)
        })
      }
      hideModal()
    }
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadMultipleSettingsForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    </Modal>
  )
}

DownloadMultipleSettingsContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default withLocalize(DownloadMultipleSettingsContainer)
