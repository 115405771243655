import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DataUnits from 'constants/DataUnits'
import DataTypes from 'constants/DataTypes'
import { getWeatherIcon } from 'utils/weatherIcons'
import styles from './WeatherInfo.module.scss'
import * as DataUtils from 'utils/data'

const WeatherInfo = ({ weather, translate, user }) => {
  const getWeatherDescription = () => {
    const weatherDescription = weather.measurements[0].weather[0].description
    switch (weatherDescription) {
      case 'clear sky':
        return translate('outdoorWeather.clearSky')
      case 'few clouds':
        return translate('outdoorWeather.fewClouds')
      case 'scattered clouds':
        return translate('outdoorWeather.scatteredClouds')
      case 'broken clouds':
        return translate('outdoorWeather.brokenClouds')
      case 'shower rain':
        return translate('outdoorWeather.showerRain')
      case 'rain':
        return translate('outdoorWeather.rain')
      case 'thunderstorm':
        return translate('outdoorWeather.thunderstorm')
      case 'snow':
        return translate('outdoorWeather.snow')
      case 'mist':
        return translate('outdoorWeather.mist')
    }
  }

  const measurementUnits = user.measurementUnits

  if (weather.measurements.length > 0) {
    return (
      <div className='background_shadow  h-100'>
        <div className={styles.box}>
          <div>
            <div className='location'>
              <div className={styles.weatherLocation}>
                <LocationOnOutlinedIcon className={styles.locationIcon} />
                <div className='text'>
                  <h6>{translate('fullScreenPage.outdoorWeather')}</h6>
                  <p className={styles.responsiveAdress}>{weather.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.weatherIconContainer}>
              <div className={styles.weatherIconWrapper}>
                <img src={getWeatherIcon(weather.measurements[0].weather[0].icon)} />
              </div>
              <span className={styles.responsiveAdress}>{getWeatherDescription()}</span>
            </div>
          </div>
          <div>
            <div className='text-center d-inline-block'>
              <h3 className={styles.temperatureValue}>
                <span>
                  {DataUtils.formatData(
                    weather.measurements[0].temp,
                    DataTypes.TEMPERATURE,
                    measurementUnits
                  )?.toFixed(0)}
                </span>
                {DataUtils.getDataUnit(DataTypes.TEMPERATURE, measurementUnits?.temperature)}
              </h3>
              <span className={styles.responsiveAdress}>{translate('temperature')}</span>
            </div>
          </div>
          <div className={styles.humidity}>
            <div className='text-center d-inline-block'>
              <h3 className={styles.temperatureValue}>
                <span>{weather.measurements[0].humidity}</span>
                <span style={{ paddingRight: '5px' }}>{DataUnits[DataTypes.HUMIDITY]}</span>
              </h3>
              <span className={styles.responsiveAdress}>{translate('humidity')}</span>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default WeatherInfo
