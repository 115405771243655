import request from 'utils/request'
import { Auth } from 'aws-amplify'

export function getCognitoUser(data) {
  return request('users/me', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function updateUser(data) {
  return request('users', {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function updateUserPassword(data) {
  return request('users/password', {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export async function updateCognitoUserPassword(data) {
  try {
    return await Auth.currentAuthenticatedUser().then(user => {
      return Auth.changePassword(user, data.password, data.newPassword)
    })
  } catch (err) {
    return err
  }
}

export function addSystemToUser(userId, data) {
  return request(`users/${userId}/system`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteSystemFromUser(userId, systemId) {
  return request(`users/${userId}/system/${systemId}`, {
    method: 'DELETE'
  })
}

export function uploadUserLogo(userId, data) {
  return request(`users/${userId}/logo`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteUserLogo(userId) {
  return request(`users/${userId}/logo`, {
    method: 'DELETE'
  })
}

export function sendUserOccurrence(data) {
  return request('users/support', {
    method: 'POST',
    data
  })
}

export function setUserMeasurementSystem(userId, data) {
  return request(`users/${userId}/measurementSystem`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function confirmUserNews(userId) {
  return request(`users/${userId}/confirmNews`, {
    method: 'POST'
  }).then(([body]) => body)
}

export function confirmRecipient(email, userId) {
  return request(`users/${userId}/confirmAlarmRecipient/${email}`, {
    method: 'POST'
  }).then(([body]) => body)
}

export function addAlertRecipient(userId, email) {
  return request(`users/${userId}/alarmRecipient/${email}`, {
    method: 'POST'
  }).then(([body]) => body)
}

export function confirmAlertRecipient(userId, email) {
  return request(`users/${userId}/confirmAlarmRecipient/{email}`, {
    method: 'POST',
    email
  }).then(([body]) => body)
}

export function deleteAlertRecipient(userId, email) {
  return request(`users/${userId}/alarmRecipient/${email}`, {
    method: 'DELETE'
  }).then(([body]) => body)
}

export function transferDevicesUser(data) {
  return request(`systems/receiveDevices`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteUserDevice(data) {
  return request(`systems/deleteDevices`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function confirmTransferDevice(data) {
  return request(`systems/transfer`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteAccount(id, data) {
  return request(`users/${id}/deleteUser`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteCollaborativeAccount(userId, email) {
  return request(`users/${userId}/collaborator/${email}`, {
    method: 'DELETE'
  }).then(([body]) => body)
}

export function deleteCollaborativeAccess(userId, mainEmail) {
  return request(`users/${userId}/collaborator-access/${mainEmail}`, {
    method: 'DELETE'
  }).then(([body]) => body)
}

export function addCollaborativeAccount(userId, data) {
  return request(`users/${userId}/collaborator`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function editCollaborativeAccount(userId, data) {
  return request(`users/${userId}/collaborator`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function confirmCollaborator(data) {
  return request(`users/collaborator-confirm/`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}
