import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const UPDATE_USER = '@user/UPDATE_USER'
export const UPDATE_USER_PASSWORD = '@user/UPDATE_USER_PASSWORD'
export const ADD_SYSTEM_TO_USER = '@user/ADD_SYSTEM_TO_USER'
export const DELETE_SYSTEM_FROM_USER = '@user/DELETE_SYSTEM_FROM_USER'
export const UPLOAD_USER_LOGO = '@user/UPLOAD_USER_LOGO'
export const DELETE_USER_LOGO = '@user/DELETE_USER_LOGO'
export const SEND_USER_OCCURRENCE = '@user/SEND_USER_OCCURRENCE'
export const CONFIRM_TRANSFER_DEVICE = '@user/CONFIRM_TRANSFER_DEVICE'
export const CONFIRM_USER_NEWS = '@user/CONFIRM_USER_NEWS'
export const CONFIRM_RECIPIENT = '@user/CONFIRM_RECIPIENT'
export const CONFIRM_COLLABORATOR = '@user/CONFIRM_COLLABORATOR'

export const ADD_ALERT_RECIPIENT = '@user/ADD_ALERT_RECIPIENT'
export const DELETE_ALERT_RECIPIENT = '@user/DELETE_ALERT_RECIPIENT'
export const TRANSFER_DEVICES_USER = '@user/TRANSFER_DEVICES_USER'
export const DELETE_USER_DEVICES = '@user/DELETE_USER_DEVICES'
export const DELETE_ACCOUNT = '@user/DELETE_ACCOUNT'
export const DELETE_COLLABORATIVE_ACCOUNT = '@user/DELETE_COLLABORATIVE_ACCOUNT'
export const DELETE_COLLABORATIVE_ACCESS = '@user/DELETE_COLLABORATIVE_ACCESS'

export const ADD_COLLABORATIVE_ACCOUNT = '@user/ADD_COLLABORATIVE_ACCOUNT'
export const EDIT_COLLABORATIVE_ACCOUNT = '@user/EDIT_COLLABORATIVE_ACCOUNT'
export const UPDATE_USER_REQUEST = createRequestTypes('@user/UPDATE_USER_REQUEST')
export const UPDATE_USER_PASSWORD_REQUEST = createRequestTypes('@user/UPDATE_USER_PASSWORD_REQUEST')
export const ADD_SYSTEM_TO_USER_REQUEST = createRequestTypes('@user/ADD_SYSTEM_TO_USER_REQUEST')

export const DELETE_SYSTEM_FROM_USER_REQUEST = createRequestTypes(
  '@user/DELETE_SYSTEM_FROM_USER_REQUEST'
)

export const TRANSFER_DEVICES_USER_REQUEST = createRequestTypes(
  '@user/TRANSFER_DEVICES_USER_REQUEST'
)
export const DELETE_USER_DEVICES_REQUEST = createRequestTypes('@user/DELETE_USER_DEVICES_REQUEST')
export const UPLOAD_USER_LOGO_REQUEST = createRequestTypes('@user/UPLOAD_USER_LOGO_REQUEST')
export const DELETE_USER_LOGO_REQUEST = createRequestTypes('@user/DELETE_USER_LOGO_REQUEST')
export const SEND_USER_OCCURRENCE_REQUEST = createRequestTypes('@user/SEND_USER_OCCURRENCE_REQUEST')
export const CONFIRM_TRANSFER_DEVICE_REQUEST = createRequestTypes(
  '@user/CONFIRM_TRANSFER_DEVICE_REQUEST'
)
export const CONFIRM_USER_NEWS_REQUEST = createRequestTypes('@user/CONFIRM_USER_NEWS_REQUEST')
export const CONFIRM_RECIPIENT_REQUEST = createRequestTypes('@user/CONFIRM_RECIPIENT_REQUEST')
export const CONFIRM_COLLABORATOR_REQUEST = createRequestTypes('@user/CONFIRM_COLLABORATOR_REQUEST')
export const ADD_ALERT_RECIPIENT_REQUEST = createRequestTypes('@user/ADD_ALERT_RECIPIENT_REQUEST')
export const DELETE_ALERT_RECIPIENT_REQUEST = createRequestTypes(
  '@user/DELETE_ALERT_RECIPIENT_REQUEST'
)
export const DELETE_ACCOUNT_REQUEST = createRequestTypes('@user/DELETE_ACCOUNT_REQUEST')
export const DELETE_COLLABORATIVE_ACCOUNT_REQUEST = createRequestTypes(
  '@user/DELETE_COLLABORATIVE_ACCOUNT_REQUEST'
)
export const DELETE_COLLABORATIVE_ACCESS_REQUEST = createRequestTypes(
  '@user/DELETE_COLLABORATIVE_ACCESS_REQUEST'
)
export const ADD_COLLABORATIVE_ACCOUNT_REQUEST = createRequestTypes(
  '@user/ADD_COLLABORATIVE_ACCOUNT_REQUEST'
)
export const EDIT_COLLABORATIVE_ACCOUNT_REQUEST = createRequestTypes(
  '@user/EDIT_COLLABORATIVE_ACCOUNT_REQUEST'
)
export const constants = {
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  ADD_SYSTEM_TO_USER,
  DELETE_SYSTEM_FROM_USER,
  UPLOAD_USER_LOGO,
  DELETE_USER_LOGO,
  SEND_USER_OCCURRENCE,
  CONFIRM_USER_NEWS,
  CONFIRM_RECIPIENT,
  ADD_ALERT_RECIPIENT,
  DELETE_ALERT_RECIPIENT,
  CONFIRM_TRANSFER_DEVICE,
  DELETE_ACCOUNT,
  DELETE_COLLABORATIVE_ACCOUNT,
  DELETE_COLLABORATIVE_ACCESS,
  ADD_COLLABORATIVE_ACCOUNT,
  EDIT_COLLABORATIVE_ACCOUNT,
  TRANSFER_DEVICES_USER_REQUEST,
  TRANSFER_DEVICES_USER,
  CONFIRM_COLLABORATOR,
  CONFIRM_COLLABORATOR_REQUEST,
  DELETE_USER_DEVICES,
  DELETE_USER_DEVICES_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  UPLOAD_USER_LOGO_REQUEST,
  DELETE_USER_LOGO_REQUEST,
  SEND_USER_OCCURRENCE_REQUEST,
  CONFIRM_USER_NEWS_REQUEST,
  CONFIRM_RECIPIENT_REQUEST,
  ADD_ALERT_RECIPIENT_REQUEST,
  DELETE_ALERT_RECIPIENT_REQUEST,
  CONFIRM_TRANSFER_DEVICE_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  DELETE_COLLABORATIVE_ACCOUNT_REQUEST,
  DELETE_COLLABORATIVE_ACCESS_REQUEST,
  ADD_COLLABORATIVE_ACCOUNT_REQUEST,
  EDIT_COLLABORATIVE_ACCOUNT_REQUEST
}

export const addSystemToUser = createAction(ADD_SYSTEM_TO_USER)
export const deleteSystemFromUser = createAction(DELETE_SYSTEM_FROM_USER)
export const updateUser = createAction(UPDATE_USER)
export const updateUserPassword = createAction(UPDATE_USER_PASSWORD)
export const uploadUserLogo = createAction(UPLOAD_USER_LOGO)
export const deleteUserLogo = createAction(DELETE_USER_LOGO)
export const sendUserOccurrence = createAction(SEND_USER_OCCURRENCE)
export const confirmTransferDevice = createAction(CONFIRM_TRANSFER_DEVICE)
export const confirmUserNews = createAction(CONFIRM_USER_NEWS)
export const confirmRecipient = createAction(CONFIRM_RECIPIENT)
export const confirmCollaborator = createAction(CONFIRM_COLLABORATOR)

export const addAlertRecipient = createAction(ADD_ALERT_RECIPIENT)
export const deleteAlertRecipient = createAction(DELETE_ALERT_RECIPIENT)
export const transferDevicesUser = createAction(TRANSFER_DEVICES_USER)
export const deleteUserDevice = createAction(DELETE_USER_DEVICES)
export const deleteAccount = createAction(DELETE_ACCOUNT)
export const deleteCollaborativeAccount = createAction(DELETE_COLLABORATIVE_ACCOUNT)
export const deleteCollaborativeAccess = createAction(DELETE_COLLABORATIVE_ACCESS)

export const addCollaborativeAccount = createAction(ADD_COLLABORATIVE_ACCOUNT)
export const editCollaborativeAccount = createAction(EDIT_COLLABORATIVE_ACCOUNT)
export const addSystemToUserRequest = createRequestAction(ADD_SYSTEM_TO_USER_REQUEST)
export const deleteSystemFromUserRequest = createRequestAction(DELETE_SYSTEM_FROM_USER_REQUEST)
export const updateUserRequest = createRequestAction(UPDATE_USER_REQUEST)
export const updateUserPasswordRequest = createRequestAction(UPDATE_USER_PASSWORD_REQUEST)
export const uploadUserLogoRequest = createRequestAction(UPLOAD_USER_LOGO_REQUEST)
export const deleteUserLogoRequest = createRequestAction(DELETE_USER_LOGO_REQUEST)
export const sendUserOccurrenceRequest = createRequestAction(SEND_USER_OCCURRENCE_REQUEST)
export const confirmUserNewsRequest = createRequestAction(CONFIRM_USER_NEWS_REQUEST)
export const confirmRecipientRequest = createRequestAction(CONFIRM_RECIPIENT_REQUEST)
export const confirmCollaboratorRequest = createRequestAction(CONFIRM_COLLABORATOR_REQUEST)

export const addAlertRecipientRequest = createRequestAction(ADD_ALERT_RECIPIENT_REQUEST)
export const deleteAlertRecipientRequest = createRequestAction(DELETE_ALERT_RECIPIENT_REQUEST)
export const transferDevicesUserRequest = createRequestAction(TRANSFER_DEVICES_USER_REQUEST)
export const deleteUserDeviceRequest = createRequestAction(DELETE_USER_DEVICES_REQUEST)
export const confirmTransferDeviceRequest = createRequestAction(CONFIRM_TRANSFER_DEVICE_REQUEST)
export const deleteAccountRequest = createRequestAction(DELETE_ACCOUNT_REQUEST)
export const deleteCollaborativeAccountRequest = createRequestAction(
  DELETE_COLLABORATIVE_ACCOUNT_REQUEST
)
export const deleteCollaborativeAccessRequest = createRequestAction(
  DELETE_COLLABORATIVE_ACCESS_REQUEST
)

export const addCollaborativeAccountRequest = createRequestAction(ADD_COLLABORATIVE_ACCOUNT_REQUEST)
export const editCollaborativeAccountRequest = createRequestAction(
  EDIT_COLLABORATIVE_ACCOUNT_REQUEST
)

export default {
  updateUser,
  updateUserPassword,
  addSystemToUser,
  deleteSystemFromUser,
  uploadUserLogo,
  deleteUserLogo,
  sendUserOccurrence,
  confirmUserNews,
  confirmRecipient,
  confirmCollaborator,
  addAlertRecipient,
  deleteAlertRecipient,
  transferDevicesUser,
  deleteUserDevice,
  confirmTransferDevice,
  deleteAccount,
  deleteCollaborativeAccount,
  deleteCollaborativeAccess,
  addCollaborativeAccount,
  editCollaborativeAccount,
  updateUserRequest,
  updateUserPasswordRequest,
  addSystemToUserRequest,
  deleteSystemFromUserRequest,
  uploadUserLogoRequest,
  deleteUserLogoRequest,
  sendUserOccurrenceRequest,
  confirmUserNewsRequest,
  confirmRecipientRequest,
  confirmCollaboratorRequest,
  addAlertRecipientRequest,
  transferDevicesUserRequest,
  deleteAlertRecipientRequest,
  deleteUserDeviceRequest,
  confirmTransferDeviceRequest,
  deleteAccountRequest,
  deleteCollaborativeAccountRequest,
  deleteCollaborativeAccessRequest,
  addCollaborativeAccountRequest,
  editCollaborativeAccountRequest
}
