/* eslint-disable no-useless-escape */
// ---------------------- External imports ------------------------ //
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'

// ---------------------- Material-UI imports ------------------------ //
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  IconButton,
  TextField,
  Typography,
  Input,
  Button,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

// ---------------------- Internal imports ------------------------ //
import { getUser } from 'common/selectors/user'
import styles from './EditInactivityAlertModalForm.module.scss'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const EditInactivityAlertModalForm = props => {
  const {
    onSubmit,
    user,
    translate,
    isSubmitting,
    submitError,
    alarm,
    addAlertRecipient,
    onDeleteAlertRecipient
  } = props

  const classes = useStyles()

  const checkedEmails = {}

  useEffect(() => {
    const emailsConfAux = []
    const emailsUnconfAux = []
    for (let i = 0; i < user.toJS().confirmedRecipients?.length; i++) {
      emailsConfAux[i] = {
        email: user.toJS().confirmedRecipients[i],
        confirmed: true
      }
    }
    for (let i = 0; i < user.toJS().unconfirmedRecipients?.length; i++) {
      emailsUnconfAux[i] = {
        email: user.toJS().unconfirmedRecipients[i],
        confirmed: false
      }
    }
    setEmailsConf(emailsConfAux)
    setEmailsUnconf(emailsUnconfAux)
    setSearched([...emailsConfAux, ...emailsUnconfAux])
  }, [user])

  const [alarmNameField, setAlarmNameField] = useState(alarm.name)
  const [timeRangeValue, setTimeRangeValue] = useState(alarm.userPeriod.rangeValue)
  const [unitValue, setUnitValue] = useState(alarm.userPeriod.rangeUnit)
  const [searched, setSearched] = useState([])

  const [openInfo, setOpenInfo] = useState()
  const [openInfoE, setOpenInfoE] = useState({})
  const [emailsConf, setEmailsConf] = useState([])
  const [emailsUnconf, setEmailsUnconf] = useState([])
  const [emails, setEmails] = useState(checkedEmails)
  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})
  const [emailForAdd, setEmailForAdd] = useState('')

  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()

  const handleAlarmNameChange = event => {
    setAlarmNameField(event.target.value)
    if (event.target.value !== '') {
      clearErrors('alarmName')
    }
  }

  const handleInfoE = () => {
    setOpenInfoE(!openInfoE)
  }

  const handleInfo = () => {
    setOpenInfo(!openInfo)
  }

  const handleChangeSelectedEmails = event => {
    setEmails({
      ...emails,
      [event.target.value]: event.target.checked
    })
  }

  const handleTimeInputChange = event => {
    setTimeRangeValue(event.target.value)
    if (event.target.value !== '') {
      clearErrors('rangeUnit')
    }
    if (Number(event.target.value) > 0) {
      clearErrors('rangeValue')
    }
  }

  const handleChangeInactivityUnit = event => {
    setUnitValue(event.target.value)
    if (event.target.value !== '') {
      clearErrors('rangeUnit')
    }
  }

  const onClickNext = () => {
    const values = getValues()
    if (activeTab === 0) {
      if (
        values.alarmName !== '' &&
        values.rangeUnit !== '' &&
        Number(values.rangeValue) > 0 &&
        values.rangeValue !== ''
      ) {
        setActiveTab(1)
      }
      if (values.alarmName === '') {
        setError('alarmName', { shouldFocus: 'alarmName' })
      }
      if (values.rangeUnit === '' || values.rangeValue === '') {
        setError('rangeUnit', { shouldFocus: 'rangeUnit' })
      }
      if (Number(values.rangeValue) === 0) {
        setError('rangeValue', { shouldFocus: 'rangeValue' })
      }
    }
  }

  const onClickBack = () => {
    if (activeTab === 1) {
      setActiveTab(0)
    }
  }

  const checkInputs = () => {
    const data = {}
    const rangeUnit = unitValue

    const values = getValues()
    if (values.emailRecipients.length === 0) {
      setError('emailRecipients', { shouldFocus: 'emailRecipients' })
      return
    }
    data.alarmName = values.alarmName
    data.rangeValue = values.rangeValue
    data.rangeUnit = rangeUnit
    data.emailRecipients = values.emailRecipients
    setDataState(data)
    onSubmit(data)
  }

  const onClickCreate = () => {
    checkInputs()
  }

  const onClickAdd = () => {
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (getValues('emailForAdd').match(emailFormat)) {
      addAlertRecipient({
        email: getValues('emailForAdd'),
        userId: user.toJS()._id
      })
      setSearched([...searched, { email: getValues('emailForAdd'), confirmed: false }])
    } else {
      setError('emailForAdd', { shouldFocus: 'emailForAdd' })
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleChangeAddEmail = event => {
    setEmailForAdd(event.target.value)
    if (event.target.value !== '') {
      clearErrors('emailForAdd')
    }
  }

  const error = () => {
    if (!submitError) return null
    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 404) {
      message = translate('submitErrors.systemNotFound')
    } else if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.systemAlreadyInUse')
    }

    return (
      <Alert
        severity='error'
        className={styles.error}
      >
        {message}
      </Alert>
    )
  }

  const filterEmailsChange = event => {
    let searchedUnconfEmails = []
    searchedUnconfEmails = emailsUnconf.filter(e => {
      return e.email.indexOf(event.target.value) !== -1
    })
    let searchedConfEmails = []
    searchedConfEmails = emailsConf.filter(e => {
      return e.email.indexOf(event.target.value) !== -1
    })
    setSearched([...searchedConfEmails, ...searchedUnconfEmails])
  }

  useEffect(() => {
    const emails = {}
    for (let i = 0; i < alarm.emailRecipients?.length; i++) {
      emails[alarm.emailRecipients[i]] = true
    }
    if (!alarm.emailRecipients) emails[user.toJS().email] = true
    setEmails(emails)
  }, [alarm.emailRecipients])

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('alertsPage.editAlert')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderValuesSelected}>
                    {translate('editInactivityAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('editInactivityAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='alarmName'
                  label={translate('alertsPage.alarmName')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  value={alarmNameField}
                  onChange={handleAlarmNameChange}
                  inputRef={register({ required: true })}
                  name='alarmName'
                  autoComplete='alarmName'
                  helperText={`${alarmNameField.length}/36`}
                  inputProps={{ maxLength: 36 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                {errors.alarmName && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <div className={styles.wrapperInfoE}>
                  <div className={styles.dataInput}>
                    {translate('inactivity')}
                    <div className={styles.wrapperInfoButton}>
                      <IconButton
                        className={styles.infoButton}
                        variant='outlined'
                        onClick={() => handleInfo()}
                        value={' '}
                        name='infoParam'
                        disableRipple
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div
                    style={{
                      display: openInfo ? 'block' : 'none'
                    }}
                    className={styles.caja}
                  >
                    {
                      <Translate
                        id={'inactivityInfoN'}
                        options={{ renderInnerHtml: true }}
                      />
                    }
                  </div>
                  <div className={styles.wrapperDataInfo}>
                    <div className={styles.inputField}>
                      <Input
                        classes={{
                          checked: styles.checkedBox,
                          input: styles.inputUnit
                        }}
                        onChange={event => handleTimeInputChange(event)}
                        required
                        position='end'
                        type='number'
                        name={'rangeValue'}
                        value={timeRangeValue}
                        defaultValue={timeRangeValue}
                        inputRef={register()}
                        disableRipple
                      />
                    </div>
                    <div className={styles.unitRangeWrapper}>
                      <FormControl>
                        <Select
                          id='rangeUnit'
                          name={'rangeUnit'}
                          value={unitValue}
                          onChange={handleChangeInactivityUnit}
                          variant='standard'
                          disableUnderline
                          className={styles.select}
                          IconComponent={KeyboardArrowDownIcon}
                          classes={{
                            icon: styles.selectIcon,
                            select: styles.selectFocus
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem
                            value='h'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.hours')}
                          </MenuItem>
                          <MenuItem
                            value='d'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.days')}
                          </MenuItem>
                          <MenuItem
                            value='s'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.weeks')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {errors.rangeUnit && (
                  <div className={styles.error}>
                    <small>{translate('validation.rangeValuesRequired')}</small>
                  </div>
                )}
                {errors.rangeValue && (
                  <div className={styles.error}>
                    <small>{translate('validation.rangeValuesInvalid')}</small>
                  </div>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderValues}>
                    {translate('editInactivityAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParamsSelected}>
                    {translate('editInactivityAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='emailForAdd'
                  label={translate('alertsPage.addEmail')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  inputRef={register()}
                  name='emailForAdd'
                  value={emailForAdd}
                  onChange={handleChangeAddEmail}
                  autoComplete='emailForAdd'
                  helperText={`${emailForAdd.length}/128`}
                  inputProps={{ maxLength: 128 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.emailForAdd
                  })}
                />
                <div className={styles.wrapperError}>
                  {errors.emailForAdd && (
                    <div className={styles.error}>
                      <small>{translate('validation.inValidEmail')}</small>
                    </div>
                  )}
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickAdd}
                  >
                    {translate('add')}
                  </Button>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterEmailsChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('alertsPage.searchEmail')}
                  className={cx(styles.input, classes.input)}
                />
                <div className={styles.wrapperInfo}>
                  <div className={styles.dataInput}>
                    {translate('selectEmails')}
                    <div className={styles.wrapperInfoButton}>
                      <IconButton
                        className={styles.infoButton}
                        variant='outlined'
                        onClick={() => handleInfoE()}
                        value={' '}
                        name='infoParam'
                        disableRipple
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className={styles.wrapperDataInfo}>
                    <div
                      style={{
                        display: openInfoE ? 'none' : 'block'
                      }}
                      className={styles.cajaEmail}
                    >
                      {
                        <Translate
                          id={'emailInfoN'}
                          options={{ renderInnerHtml: true }}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.checkboxEmailWrapper}>
                  <div className={styles.checkboxFormDiv}>
                    <FormControlLabel
                      className={(styles.checkboxForm, styles.confirmed)}
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          id={user.toJS().email}
                          onChange={handleChangeSelectedEmails}
                          classes={{ checked: styles.checkedBox }}
                          required
                          checked={emails[user.toJS().email] === true}
                          name='emailRecipients'
                          value={user.toJS().email}
                          inputRef={register()}
                          disableRipple
                        />
                      }
                      label={user.toJS().email + ' ' + translate('thisUser')}
                    />
                  </div>
                  {searched.map((email, i) => {
                    return (
                      <div
                        key={i + 'email'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={
                            (styles.checkboxForm,
                            `${
                              email.confirmed ? `${styles.confirmed}` : `${styles.notConfirmed}`
                            } `)
                          }
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              id={email}
                              onChange={handleChangeSelectedEmails}
                              classes={{ checked: styles.checkedBox }}
                              required
                              checked={emails[email.email] === true}
                              name='emailRecipients'
                              value={email.email}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={email.email}
                        />
                        <IconButton
                          onClick={() => onDeleteAlertRecipient(email.email)}
                          className={styles.deleteEmailRecipientIcon}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    )
                  })}
                </div>
                {errors.emailRecipients && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyEmailRequired')}</small>
                  </div>
                )}
                <div className={styles.errorWrapper}>{error()}</div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.buttonAdd}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingPosition='end'
                    onClick={onClickCreate}
                  >
                    {translate('saveChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditInactivityAlertModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.any,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state)
})

export default withLocalize(connect(mapStateToProps)(EditInactivityAlertModalForm))
