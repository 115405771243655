import { call, put, all, take, takeLatest, fork, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { normalize } from 'normalizr'

import actions, { constants } from 'common/actions/systems'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/system'
import modalActions from '../actions/modal'
import LogRocket from 'logrocket'

const POLLING_DELAY = 600000 // 10 minute
let pollSystemTask = null
let pollSystemsTask = null

function* onGetSystemById({ payload: id }) {
  yield put(actions.getSystemByIdRequest.start())

  try {
    const { system } = yield call(api.getSystemById, id)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.getSystemByIdRequest.success(norm))
  } catch (err) {
    yield put(actions.getSystemByIdRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onGetSystemsHome({ payload: data }) {
  yield put(actions.getSystemsHomeRequest.start())

  try {
    const { systems } = yield call(api.getSystemsHome, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.getSystemsHomeRequest.success(norm))
  } catch (err) {
    yield put(actions.getSystemsHomeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateSystem({ payload: data }) {
  yield put(actions.updateSystemRequest.start())

  try {
    const { system } = yield call(api.updateSystem, data)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.updateSystemRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateSystemRequest.failure(err))
    if (err.responseError === undefined) yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    if (err.responseError.message === 'Unknown zip code') {
      yield put(snackbarActions.showZipcodeSnackbar('snackbar.zipCodeNotAvailable'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    }
  }
}

function* onUpdateSystemLights({ payload: data }) {
  yield put(actions.updateSystemLightsRequest.start())

  try {
    yield call(api.updateSystemLights, data._id, data)
    yield put(actions.updateSystemLightsRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemLightsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}
function* onUpdateSystemCalibration({ payload: data }) {
  yield put(actions.updateSystemCalibrationRequest.start())

  try {
    yield call(api.updateSystemCalibration, data._id, data)
    yield put(actions.updateSystemCalibrationRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemCalibrationRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateSystemPeriodicity({ payload: data }) {
  yield put(actions.updateSystemPeriodicityRequest.start())

  try {
    yield call(api.updateSystemPeriodicity, data._id, data)
    yield put(actions.updateSystemPeriodicityRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemPeriodicityRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateMultipleSystemLights({ payload: data }) {
  yield put(actions.updateSystemGlobalLightsRequest.start())

  try {
    yield call(api.updateMultipleSystemLights, data)
    yield put(actions.updateSystemGlobalLightsRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemGlobalLightsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}
function* onUpdateMultipleSystemCalibration({ payload: data }) {
  yield put(actions.updateSystemGlobalCalibrationRequest.start())

  try {
    yield call(api.updateMultipleSystemCalibration, data)
    yield put(actions.updateSystemGlobalCalibrationRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemGlobalCalibrationRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateMultipleSystemPeriodicity({ payload: data }) {
  yield put(actions.updateSystemGlobalPeriodicityRequest.start())

  try {
    yield call(api.updateMultipleSystemPeriodicity, data)
    yield put(actions.updateSystemGlobalPeriodicityRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })
    yield put(actions.updateSystemGlobalPeriodicityRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onPollSystem(id) {
  try {
    while (true) {
      yield put(actions.pollSystemRequest.start())
      try {
        const { system } = yield call(api.getSystemById, id)
        const norm = yield call(normalize, system, schemas.system)
        yield put(actions.pollSystemRequest.success(norm))
        yield delay(POLLING_DELAY)
      } catch (err) {
        // Stop polling task when error
        yield put(actions.pollSystemRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollSystemStop())
      }
    }
  } catch (error) {
    LogRocket.captureException(error)
  }
}
function* onPollSystems(id) {
  try {
    while (true) {
      yield put(actions.pollSystemsRequest.start())
      try {
        const { systems } = yield call(api.getSystemsHome, id)
        const norm = yield call(normalize, systems, [schemas.system])
        yield put(actions.pollSystemsRequest.success(norm))
        yield delay(POLLING_DELAY)
      } catch (err) {
        // Stop polling task when error
        yield put(actions.pollSystemsRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollSystemsStop())
      }
    }
  } catch (error) {
    LogRocket.captureException(error)
  }
}

function* watchPollSystem() {
  while (true) {
    const { payload: id } = yield take(constants.POLL_SYSTEM_START)

    // Start polling task
    pollSystemTask = yield fork(onPollSystem, id)

    // Wait until POLL_SYSTEM_STOP action
    yield take(constants.POLL_SYSTEM_STOP)

    // Cancel polling task
    yield cancel(pollSystemTask)
  }
}

function* watchPollSystems() {
  while (true) {
    const { payload: id } = yield take(constants.POLL_SYSTEMS_START)

    // Start polling task
    pollSystemsTask = yield fork(onPollSystems, id)

    // Wait until POLL_SYSTEM_STOP action
    yield take(constants.POLL_SYSTEMS_STOP)

    // Cancel polling task
    yield cancel(pollSystemsTask)
  }
}

function* onDeleteDataFromUser({ payload: systemId }) {
  yield put(actions.deleteDataFromUserRequest.start())

  try {
    // const userId = yield select(getUserId)
    yield call(api.deleteDataFromUser, systemId)
    yield put(actions.deleteDataFromUserRequest.success({ systemId }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))

    // yield call(onPollSystem, systemId)
  } catch (err) {
    yield put(actions.deleteDataFromUserRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onCreateGroup({ payload: data }) {
  yield put(actions.createGroupRequest.start())
  try {
    const { systems } = yield call(api.createGroup, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.createGroupRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.createGroupSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.createGroupRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateGroup({ payload: data }) {
  yield put(actions.updateGroupRequest.start())
  try {
    const { systems } = yield call(api.createGroup, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.updateGroupRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.updateGroupSuccessful'))
  } catch (err) {
    yield put(actions.updateGroupRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onDeleteGroup({ payload: data }) {
  yield put(actions.deleteGroupRequest.start())
  try {
    yield call(api.deleteGroup, data)
    yield put(actions.deleteGroupRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteGroupSuccessful'))
  } catch (err) {
    yield put(actions.deleteGroupRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onDeleteSystemFromGroup({ payload: systemId }) {
  yield put(actions.deleteGroupSystemRequest.start())

  try {
    yield call(api.deleteGroupSystem, systemId)
    yield put(actions.deleteGroupSystemRequest.success({ systemId }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteFromGroupSuccessful'))
  } catch (err) {
    yield put(actions.deleteGroupSystemRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onGetScreens({ payload: data }) {
  yield put(actions.getScreensRequest.start())
  try {
    const { screens } = yield call(api.getScreens, data)
    const norm = yield call(normalize, screens, [schemas.screen])
    yield put(actions.getScreensRequest.success(norm))
  } catch (err) {
    yield put(actions.getScreensRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onCreateScreen({ payload: data }) {
  yield put(actions.createScreenRequest.start())
  try {
    const { screen } = yield call(api.createScreen, data)
    const norm = yield call(normalize, screen, schemas.screen)
    yield put(actions.createScreenRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.createScreenSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.createScreenRequest.failure(err))
    if (err.res.status === 409) {
      yield put(snackbarActions.showZipcodeSnackbar('snackbar.ScreenNameAlreadyExists'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    }
  }
}

function* onUpdateScreen({ payload: data }) {
  yield put(actions.updateScreenRequest.start())
  try {
    const { screen } = yield call(api.updateScreen, data.ScreenId, data.jsonData)
    const norm = yield call(normalize, screen, schemas.screen)
    yield put(actions.updateScreenRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.updateScreenSuccessful'))
  } catch (err) {
    yield put(actions.updateScreenRequest.failure(err))
    if (err.res.status === 409) {
      yield put(snackbarActions.showZipcodeSnackbar('snackbar.ScreenNameAlreadyExists'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    }
  }
}

function* onDeleteScreen({ payload: data }) {
  yield put(actions.deleteScreenRequest.start())
  try {
    yield call(api.deleteScreen, data)
    yield put(actions.deleteScreenRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteScreenSuccessful'))
  } catch (err) {
    yield put(actions.deleteScreenRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onCreateAlarm({ payload: data }) {
  yield put(actions.createAlarmRequest.start())
  try {
    const { alarms } = yield call(api.createAlarm, data)
    const norm = yield call(normalize, alarms, [schemas.alarm])
    yield put(actions.createAlarmRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.createAlarmSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.createAlarmRequest.failure(err))
  }
}

function* onUpdateAlarm({ payload: data }) {
  yield put(actions.updateAlarmRequest.start())
  try {
    const { alarm } = yield call(api.updateAlarm, data.AlarmId, data.jsonData)
    const norm = yield call(normalize, alarm, schemas.alarm)
    yield put(actions.updateAlarmRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.updateAlarmSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateAlarmRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onDeleteAlarm({ payload: data }) {
  yield put(actions.deleteAlarmRequest.start())
  try {
    yield call(api.deleteAlarm, data)
    yield put(actions.deleteAlarmRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteAlarmSuccessful'))
  } catch (err) {
    yield put(actions.deleteAlarmRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onGetAlarms({ payload: data }) {
  yield put(actions.getAlarmsRequest.start())
  try {
    const { alarms } = yield call(api.getAlarms, data)
    const norm = yield call(normalize, alarms, [schemas.alarm])
    yield put(actions.getAlarmsRequest.success(norm))
  } catch (err) {
    yield put(actions.getAlarmsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onChangeAlarmStatus({ payload: data }) {
  yield put(actions.changeAlarmStatusRequest.start())
  try {
    yield call(api.changeAlarmStatus, data.AlarmId, data.jsonData)
    yield put(actions.changeAlarmStatusRequest.success({ data }))
    if (data.jsonData.active) {
      yield put(snackbarActions.showSnackbar('snackbar.alarmActivatedSuccessful'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.alarmDesactivatedSuccessful'))
    }
  } catch (err) {
    yield put(actions.changeAlarmStatusRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onCreateInactivityAlarm({ payload: data }) {
  yield put(actions.createInactivityAlarmRequest.start())
  try {
    const { alarms } = yield call(api.createInactivityAlarm, data)
    const norm = yield call(normalize, alarms, [schemas.inactivityAlarm])
    yield put(actions.createInactivityAlarmRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.addedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.createInactivityAlarmRequest.failure(err))
  }
}

function* onUpdateInactivityAlarm({ payload: data }) {
  yield put(actions.updateInactivityAlarmRequest.start())
  try {
    const { alarm } = yield call(api.updateInactivityAlarm, data.InactivityAlarmId, data.jsonData)
    const norm = yield call(normalize, alarm, schemas.inactivityAlarm)
    yield put(actions.updateInactivityAlarmRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.updateAlarmSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateInactivityAlarmRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onDeleteInactivityAlarm({ payload: data }) {
  yield put(actions.deleteInactivityAlarmRequest.start())
  try {
    yield call(api.deleteInactivityAlarm, data)
    yield put(actions.deleteInactivityAlarmRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteAlarmSuccessful'))
  } catch (err) {
    yield put(actions.deleteInactivityAlarmRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onGetInactivityAlarms({ payload: data }) {
  yield put(actions.getInactivityAlarmsRequest.start())
  try {
    const { alarms } = yield call(api.getInactivityAlarms, data)
    const norm = yield call(normalize, alarms, [schemas.inactivityAlarm])
    yield put(actions.getInactivityAlarmsRequest.success(norm))
  } catch (err) {
    yield put(actions.getInactivityAlarmsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onChangeInactivityAlarmStatus({ payload: data }) {
  yield put(actions.changeInactivityAlarmStatusRequest.start())
  try {
    yield call(api.changeInactivityAlarmStatus, data.InactivityAlarmId, data.jsonData)
    yield put(actions.changeInactivityAlarmStatusRequest.success({ data }))
    if (data.jsonData.active) {
      yield put(snackbarActions.showSnackbar('snackbar.alarmActivatedSuccessful'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.alarmDesactivatedSuccessful'))
    }
  } catch (err) {
    yield put(actions.changeInactivityAlarmStatusRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export default function* watchSystems() {
  yield fork(watchPollSystem)
  yield fork(watchPollSystems)

  yield all([
    takeLatest(constants.GET_SYSTEM_BY_ID, onGetSystemById),
    takeLatest(constants.GET_SYSTEMS_HOME, onGetSystemsHome),
    takeLatest(constants.UPDATE_SYSTEM, onUpdateSystem),
    takeLatest(constants.UPDATE_SYSTEM_LIGHTS, onUpdateSystemLights),
    takeLatest(constants.UPDATE_SYSTEM_CALIBRATION, onUpdateSystemCalibration),
    takeLatest(constants.UPDATE_SYSTEM_PERIODICITY, onUpdateSystemPeriodicity),
    takeLatest(constants.UPDATE_SYSTEM_GLOBAL_LIGHTS, onUpdateMultipleSystemLights),
    takeLatest(constants.UPDATE_SYSTEM_GLOBAL_CALIBRATION, onUpdateMultipleSystemCalibration),
    takeLatest(constants.UPDATE_SYSTEM_GLOBAL_PERIODICITY, onUpdateMultipleSystemPeriodicity),
    takeLatest(constants.DELETE_DATA_FROM_USER_SYSTEM, onDeleteDataFromUser),
    takeLatest(constants.CREATE_GROUP, onCreateGroup),
    takeLatest(constants.UPDATE_GROUP, onUpdateGroup),
    takeLatest(constants.DELETE_GROUP, onDeleteGroup),
    takeLatest(constants.DELETE_GROUP_SYSTEM, onDeleteSystemFromGroup),
    takeLatest(constants.GET_SCREENS, onGetScreens),
    takeLatest(constants.CREATE_SCREEN, onCreateScreen),
    takeLatest(constants.UPDATE_SCREEN, onUpdateScreen),
    takeLatest(constants.DELETE_SCREEN, onDeleteScreen),
    takeLatest(constants.CREATE_ALARM, onCreateAlarm),
    takeLatest(constants.UPDATE_ALARM, onUpdateAlarm),
    takeLatest(constants.DELETE_ALARM, onDeleteAlarm),
    takeLatest(constants.GET_ALARMS, onGetAlarms),
    takeLatest(constants.CHANGE_ALARM_STATUS, onChangeAlarmStatus),
    takeLatest(constants.CREATE_INACTIVITY_ALARM, onCreateInactivityAlarm),
    takeLatest(constants.UPDATE_INACTIVITY_ALARM, onUpdateInactivityAlarm),
    takeLatest(constants.DELETE_INACTIVITY_ALARM, onDeleteInactivityAlarm),
    takeLatest(constants.GET_INACTIVITY_ALARMS, onGetInactivityAlarms),
    takeLatest(constants.CHANGE_INACTIVITY_ALARM_STATUS, onChangeInactivityAlarmStatus)
  ])
}
