import DownloadSettings from './DownloadSettings'
import DownloadMultipleSettings from './DownloadMultipleSettings'
import DownloadStatistics from './DownloadStatistics'

import AddFullScreenView from './AddFullScreenView'
import EditFullScreenView from './EditFullScreenView'
import FullScreenSystem from './FullScreenSystem'
import UploadFileModal from './UploadFile'

import AddSystemModal from './AddSystemModal'
import EditSystemModal from './EditSystemModal'

import EditSystemLights from './EditSystemLights'
import EditSystemPeriodicity from './EditSystemPeriodicity'
import EditSystemCalibrationCo2 from './EditSystemCalibrationCo2'

import AddParameterAlertModal from './AddParameterAlertModal'
import AddInactivityAlertModal from './AddInactivityAlertModal'
import EditParameterAlertModal from './EditParameterAlertModal'
import EditInactivityAlertModal from './EditInactivityAlertModal'

import NewGroupModal from './NewGroup'
import EditGroupModal from './EditGroup'

import TypeInfoModal from './TypeInfoModal'
import SupportModal from './SupportModal'
import WhatIsNewModal from './WhatIsNewModal'
import AddReportModal from './AddReportModal'
import ConfirmationModal from './ConfirmationModal'
import ConfirmTransfer from './ConfirmTransfer'
import DownloadWellDataReport from './DownloadWellDataReport'
import DownloadWellStaticData from './DownloadWellStaticData'
import TransferSystem from './TransferSystem'
import DeleteDeviceUser from './DeleteDeviceUserModal'
import AddCollaborativeModal from './AddCollaborativeModal'
import ShowMessage from './ShowMessage'
export default {
  FullScreenSystem,
  ConfirmationModal,
  DownloadSettings,
  DownloadMultipleSettings,
  DownloadStatistics,
  AddSystemModal,
  EditSystemModal,
  EditSystemLights,
  EditSystemPeriodicity,
  AddParameterAlertModal,
  AddInactivityAlertModal,
  EditParameterAlertModal,
  EditInactivityAlertModal,
  AddFullScreenView,
  EditFullScreenView,
  NewGroupModal,
  EditGroupModal,
  UploadFileModal,
  TypeInfoModal,
  SupportModal,
  WhatIsNewModal,
  AddReportModal,
  DownloadWellDataReport,
  DownloadWellStaticData,
  TransferSystem,
  DeleteDeviceUser,
  ConfirmTransfer,
  AddCollaborativeModal,
  EditSystemCalibrationCo2,
  ShowMessage
}
